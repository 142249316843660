import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

export const ChartPie = ({
  ArrayLabels,
  Etiqueta,
  ArrayData,
  ArrayBackground,
  ArrayBorder,
  Titulo,
}) => {
  // Preparamos los datos para la gráfica
  const chartData = {
    labels: ArrayLabels,
    datasets: [
      {
        label: Etiqueta,
        data: ArrayData,
        backgroundColor: ArrayBackground,
        borderColor: ArrayBorder,
        borderWidth: 1,
      },
    ],
  };

  // Configuramos las opciones de la gráfica
  const chartOptions = {
    maintainAspectRatio: false,
    title: {
      display: true,
      text: Titulo,
    },
  };

  return (
    <Pie style={{ height: "16rem" }} data={chartData} options={chartOptions} />
  );
};
