import { BtnAtras } from "../navegacion/btn_atras";
import axios from "axios";
import { useState, useMemo, useEffect, useRef } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Tooltip } from "antd";
import withReactContent from "sweetalert2-react-content";
import {
  traerDatos,
  HeadersAuth,
  renewSession,
  CheckExpiracion,
} from "../funciones_utiles";
import {
  validarTelefono,
  validarTextoNumerico,
  validarSelector,
} from "../validaciones";
import { useTranslation } from "react-i18next";
const MySwal = withReactContent(Swal);

const URI_PAISES =
  process.env.REACT_APP_DOMINIO + process.env.REACT_APP_PUERTO + "/paises";
const URI_PROVINCIAS =
  process.env.REACT_APP_DOMINIO + process.env.REACT_APP_PUERTO + "/provincias";
const URI_RUBROS =
  process.env.REACT_APP_DOMINIO + process.env.REACT_APP_PUERTO + "/rubros";

const URI_GUARDAR_EMPRESA =
  process.env.REACT_APP_DOMINIO +
  process.env.REACT_APP_PUERTO +
  "/nueva_empresa";

export const CompNuevaEmpresa = ({ rolUser, existeEmpresa }) => {
  const [t,i18n] = useTranslation("global");
  //Variables Para las condiciones
  const currentLanguage = i18n.language;
  //Codigo del formulario
  const [opcionesPaises, setOpcionesPaises] = useState([]);
  const [opcionesProvincias, setOpcionesProvincias] = useState([]);
  const [opcionesRubros, setOpcionesRubros] = useState([]);

  // const [logo, setLogo] = useState(null);
  const [previewLogo, setPreviewLogo] = useState("./img/empresa_nuevo.png");
  const [error, setError] = useState("");

  const inputFileRef = useRef(null);

  const navigate = useNavigate();

  const [tempDatosEmpresa, setTempDatosEmpresa] = useState({
    razon_social: "",
    pais: "",
    nombre: "",
    provincia_estado: "",
    ciudad: "",
    domicilio: "",
    rubro: "",
    telefono: "",
    logo: "",
  });

  var [tooltipVisible, setTooltipVisible] = useState({
    razon_social: false,
    pais: false,
    nombre: false,
    provincia_estado: false,
    ciudad: false,
    domicilio: false,
    rubro: false,
    telefono: false,
  });

  const [errores, setErrores] = useState({
    razon_social: "",
    pais: "",
    nombre: "",
    provincia_estado: "",
    ciudad: "",
    domicilio: "",
    rubro: "",
    telefono: "",
  });

  const campos = [
    { name: "razon_social", validator: validarTextoNumerico },
    { name: "nombre", validator: validarTextoNumerico },
    { name: "ciudad", validator: validarTextoNumerico },
    { name: "domicilio", validator: validarTextoNumerico },
    { name: "telefono", validator: validarTelefono },
    { name: "provincia_estado", validator: validarSelector },
    { name: "rubro", validator: validarSelector },
    { name: "pais", validator: validarSelector },
  ];

  useMemo(() => {
    (async () => {
      setOpcionesPaises(await traerDatos(URI_PAISES));
      setOpcionesRubros(await traerDatos(URI_RUBROS));
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setOpcionesProvincias(
        await traerDatos(URI_PROVINCIAS + "?id=" + tempDatosEmpresa.pais)
      );
    })();
  }, [tempDatosEmpresa.pais]);

  const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
  const maxFileSize = 20 * 1024 * 1024; // 20 MB

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    // setLogo(file);

    if (!file.type || !allowedTypes.includes(file.type)) {
      MySwal.fire({
        text:  t("mensajes.mensaje50"),
        imageUrl: "img/lechuza-triste.png",
        imageHeight: 55,
        confirmButtonText: "Ok",
      });
      inputFileRef.current.value = "";
      setError(true);
      return;
    }

    if (file.size > maxFileSize) {
      MySwal.fire({
        text:  t("mensajes.mensaje51"),
        imageUrl: "img/icono-exitoso.png",
        imageHeight: 65,
        confirmButtonText: "Ok",
      });
      inputFileRef.current.value = "";
      setError(true);
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setTempDatosEmpresa({
        ...tempDatosEmpresa,
        logo: reader.result,
      });
      setPreviewLogo(reader.result);
      setError(false);
    };
  };

  const handleChange = async (e) => {
    const { id, value } = e.target;

    if (id === "pais") {
      setTempDatosEmpresa({
        ...tempDatosEmpresa,
        provincia_estado: "",
        [id]: value,
      });
    } else {
      setTempDatosEmpresa({
        ...tempDatosEmpresa,
        [id]: value,
      });
    }
    let erroresTemp = { ...errores };
    const validarCampo = async (campo, valor) => {
      const resultadoValidacion = await campo(valor,t);
      return {
        mensaje: resultadoValidacion.mensaje,
        valido: resultadoValidacion.valido,
      };
    };

    const campoActual = campos.find((campo) => campo.name === id);

    if (campoActual) {
      const mira = await validarCampo(campoActual.validator, value);
      erroresTemp[id] = mira.mensaje;
      setTooltipVisible((prevState) => ({
        ...prevState,
        [id]: !mira.valido,
      }));
      setTimeout(() => {
        setTooltipVisible((prevState) => ({
          ...prevState,
          [id]: false,
        }));
      }, 3000);
    }

    setErrores(erroresTemp);
  };

  const guardar = async (e) => {
    e.preventDefault();
    console.log("hello");
    let hayErrores = false;
    const erroresTemp = {};
    for (const campo of campos) {
      const valorCampo = String(tempDatosEmpresa[campo.name]);
      const resultadoValidacion = await campo.validator(valorCampo,t);

      if (resultadoValidacion.valido === false) {
        hayErrores = true;
        erroresTemp[campo.name] = resultadoValidacion.mensaje;
        setTooltipVisible((prevState) => ({
          ...prevState,
          [campo.name]: !resultadoValidacion.valido,
        }));
        setTimeout(() => {
          setTooltipVisible((prevState) => ({
            ...prevState,
            [campo.name]: false,
          }));
        }, 3000);
      }
    }

    if (error || hayErrores) {
      setErrores(erroresTemp);
      return;
    } else {
      const registro = await axios.post(
        URI_GUARDAR_EMPRESA,
        tempDatosEmpresa,
        HeadersAuth
      );
      if (registro.data.errors) {
        const errores = registro.data.errors;
        const msgError = errores.map((error) => {
          return "<pre>" + (currentLanguage === "es" ? error.msg.ES :  error.msg.EN) + "</pre>";
        });
        MySwal.fire({
          html: `${msgError}`,
          imageUrl: "img/borrar.png",
          imageHeight: 65,
          confirmButtonText: "Ok",
        });
      } else {
        if (registro.data.code === 409) {
          CheckExpiracion();
        } else if (registro.data.code === 401) {
          MySwal.fire({
            text:  t("mensajes.mensaje42"),
            imageUrl: "img/lechuza-triste.png",
            imageHeight: 55,
            confirmButtonText: "Ok",
          });
        } else if (registro.data.code === 200) {
          await renewSession();
          navigate("/empresa");
          MySwal.fire({
            text:  t("mensajes.mensaje7"),
            imageUrl: "img/guardado-exitoso.svg",
            imageHeight: 65,
            confirmButtonText: "Ok",
          });
        }
      }
    }
  };
  return (
    <>
      {existeEmpresa && rolUser === 2 ? (
        <Navigate to="/empresa" replace />
      ) : (
        <form onSubmit={guardar} className="form-inline">
          <div className=" row g-3 d-flex justify-content-center ms-4 me-4 ">
            <div className=" col-10 col-md-5">
              <label className="select_lista" htmlFor="razon_social">
              {t("empresa.datosEmpresa.razonSocial")}
              </label>
              <Tooltip
                title={
                  !!errores.razon_social && <span>{errores.razon_social}</span>
                }
                open={tooltipVisible.razon_social}
                color="#1CA6AF"
              >
                <input
                  className="form-control"
                  type="text"
                  placeholder= {t("registro.nombre")}
                  id="razon_social"
                  value={tempDatosEmpresa.razon_social || ""}
                  onChange={handleChange}
                />
              </Tooltip>

              <label className="select_lista">{t("empresa.datosEmpresa.rubros")}</label>
              <Tooltip
                title={!!errores.rubro && <span>{errores.rubro}</span>}
                open={tooltipVisible.rubro}
                color="#1CA6AF"
              >
                <select
                  className="form-select"
                  id="rubro"
                  onChange={handleChange}
                  value={tempDatosEmpresa.rubro || ""}
                >
                  <option value="">{t("empresa.datosSede.elejirOpcion")}</option>
                  {opcionesRubros.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.rubro}
                    </option>
                  ))}
                </select>
              </Tooltip>
            </div>
            <div className=" col-10 col-md-5">
              <div className="col-empresa">
                <img className="imagen-perfil " src={previewLogo} alt="" />
                <input
                  className="form-control-guardar-empresa"
                  type="file"
                  onChange={handleLogoChange}
                  accept=".jpg,.jpeg,.png,.gif"
                />
              </div>
            </div>
          </div>
          <div className=" row g-3 d-flex justify-content-center ms-4 me-4 ">
            <h1 className="titulos" style={{ marginTop: "2em" }}>
            {t("empresa.sucursalPrincipal")}
            </h1>
          </div>
          <div className=" row g-3 d-flex justify-content-center ms-4 me-4 ">
            <div className=" col-10 col-md-5">
              <label className="select_lista" htmlFor="razon_social">
              {t("registro.nombre")}
              </label>
              <Tooltip
                title={!!errores.nombre && <span>{errores.nombre}</span>}
                open={tooltipVisible.nombre}
                color="#1CA6AF"
              >
                <input
                  className="form-control"
                  type="text"
                  placeholder={t("registro.nombre")}
                  id="nombre"
                  value={tempDatosEmpresa.nombre || ""}
                  onChange={handleChange}
                />
              </Tooltip>
            </div>
            <div className=" col-10 col-md-5">
              <label className="select_lista">{t("empresa.datosSede.pais")}</label>
              <Tooltip
                title={!!errores.pais && <span>{errores.pais}</span>}
                open={tooltipVisible.pais}
                color="#1CA6AF"
              >
                <select
                  className="form-select"
                  id="pais"
                  onChange={handleChange}
                  value={tempDatosEmpresa.pais || ""}
                >
                  <option value="">{t("empresa.datosSede.elejirOpcion")}</option>
                  {opcionesPaises.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.paisnombre}
                    </option>
                  ))}
                </select>
              </Tooltip>
            </div>

            <div className="col-10 col-md-5">
              <label className="select_lista">{t("empresa.datosSede.provincia")}</label>
              <Tooltip
                title={
                  !!errores.provincia_estado && (
                    <span>{errores.provincia_estado}</span>
                  )
                }
                open={tooltipVisible.provincia_estado}
                color="#1CA6AF"
              >
                <select
                  className="form-select"
                  id="provincia_estado"
                  onChange={handleChange}
                  value={tempDatosEmpresa.provincia_estado || ""}
                >
                  <option value="">{t("empresa.datosSede.elejirOpcion")}</option>
                  {opcionesProvincias.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.estadonombre}
                    </option>
                  ))}
                </select>
              </Tooltip>
            </div>

            <div className=" col-10 col-md-5">
              <label className="select_lista">{t("empresa.datosSede.ciudad")}</label>
              <Tooltip
                title={!!errores.ciudad && <span>{errores.ciudad}</span>}
                open={tooltipVisible.ciudad}
                color="#1CA6AF"
              >
                <input
                  className="form-control"
                  type="text"
                  placeholder={t("empresa.datosSede.ciudad")}
                  id="ciudad"
                  value={tempDatosEmpresa.ciudad || ""}
                  onChange={handleChange}
                />
              </Tooltip>
            </div>

            <div className="col-10 col-md-5">
              <label className="select_lista">{t("empresa.datosSede.domicilio")}</label>
              <Tooltip
                title={!!errores.domicilio && <span>{errores.domicilio}</span>}
                open={tooltipVisible.domicilio}
                color="#1CA6AF"
              >
                <input
                  className="form-control"
                  type="text"
                  placeholder={t("empresa.datosSede.domicilio")}
                  id="domicilio"
                  value={tempDatosEmpresa.domicilio || ""}
                  onChange={handleChange}
                />
              </Tooltip>
            </div>

            <div className="col-10 col-md-5">
              <label className="select_lista">{t("empresa.datosSede.telefono")}</label>
              <Tooltip
                title={!!errores.telefono && <span>{errores.telefono}</span>}
                open={tooltipVisible.telefono}
                color="#1CA6AF"
              >
                <input
                  className="form-control"
                  type="number"
                  placeholder={t("tooltip.telefono")}
                  id="telefono"
                  value={tempDatosEmpresa.telefono || ""}
                  onChange={handleChange}
                />
              </Tooltip>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <button
              className="boton-agregar-empresa justify-content-end"
              style={{ marginTop: "1rem" }}
            >
              {t("botones.guardarEmpresa")}
            </button>
          </div>
        </form>
      )}
    </>
  );
};
