import { BtnAtrasCalculadoras } from "../navegacion/btn_atras_calculadoras";
import {
  PlusOutlined,
  RightOutlined,
  LeftOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { TablaCompleta } from "../tablas/tabla_simple/tabla_simple";
import { ChartEmisionesMulti } from "../charts/ChartEmisionesMulti";
import { InputPrecio } from "../componentes/inputComponente";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import moment from "moment";
import withReactContent from "sweetalert2-react-content";
import { FaQuestionCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import {
  traerDatos,
  HeadersAuth,
  CheckExpiracion,
  traerDatosObjeto,
  fNum,
  cambioValor,
  sacaComas,
} from "../funciones_utiles";
import {
  validarNumericoDecimal,
  validarFechaPeriodo,
  validarSelector,
} from "../validaciones";
import PresetResultado from "./presetResultado";
import { BtnPrincipal } from "../navegacion/BtnPrincipal";
import { Tooltip, Tour } from "antd";

const MySwal = withReactContent(Swal);

//DESCARGAR MOMENT JS
export const CompCalculadoraGases = ({
  sesion,
  calculo,
  type,
  type_tabla,
  titulo,
}) => {
  const [t, i18n] = useTranslation("global");
  const currentLanguage = i18n.language;
  const URI_COMBUSTIBLES =
    process.env.REACT_APP_DOMINIO + process.env.REACT_APP_PUERTO + "/gases";
  // console.log(empresasUsuario)
  // console.log(empresasUsuario.some(checkEmpresa))

  const refGas = useRef(null);
  const refCantidad = useRef(null);
  const refMes = useRef(null);
  const refBtnGuardar = useRef(null);
  const refTabla = useRef(null);

  const [abrirAyuda, setAbrirAyuda] = useState(false);

  const propsNext = {
    children: (
      <RightOutlined style={{ color: "white", marginBottom: "20px" }} />
    ),
    style: {
      height: 31,
    },
  };
  const propsFin = {
    children: (
      <CloseOutlined style={{ color: "white", marginBottom: "20px" }} />
    ),
    style: {
      height: 31,
    },
  };
  const propsPrev = {
    children: <LeftOutlined style={{ color: "white", marginBottom: "20px" }} />,
    style: {
      height: 31,
    },
  };
  const pasos = [
    {
      title: t("calculadoras.pasos.titulo3"),
      description: t("calculadoras.pasos.subtitulo3"),
      target: () => refMes.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    },
    {
      title: t("calculadoras.pasos.titulo27"),
      description: t("calculadoras.pasos.subtitulo27"),
      target: () => refGas.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    },
    {
      title: t("calculadoras.pasos.titulo2"),
      description: t("calculadoras.pasos.subtitulo28"),
      target: () => refCantidad.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    },

    {
      title: t("calculadoras.pasos.titulo4"),
      description: t("calculadoras.pasos.subtitulo4"),
      target: () => refBtnGuardar.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    },
    {
      title: t("calculadoras.pasos.titulo6"),
      description: t("calculadoras.pasos.subtitulo6"),
      target: () => refTabla.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsFin,
    },
  ];

  // const anioActual = new Date().getFullYear();
  // const [yearRange, setYearRange] = useState(anioActual);
  const [sufijo, setSufijo] = useState("S/N");

  const [datosChart, setDatosChart] = useState([]);
  const [datosTable, setDatosTable] = useState([]);

  const [opcionesCombustibles, setOpcionesCombustibles] = useState([]);

  const [idUnico, setIdUnico] = useState("");
  const [tempDatosCalculo, setTempDatosCalculo] = useState({});
  const [idEmpresa, setIdEmpresa] = useState(sesion.empresaActiva);
  const [idSede, setIdSede] = useState(sesion.sedeActiva);
  const [fuenteEmision, setFuenteEmision] = useState("");

  useEffect(() => {
    setIdEmpresa(sesion.empresaActiva);
    setIdSede(sesion.sedeActiva);
    if (idUnico) {
      (async () => {
        const URI_CONSUMOS_COMBUSTION_UNICOS =
          process.env.REACT_APP_DOMINIO +
          process.env.REACT_APP_PUERTO +
          "/datos_consumo_unico?type=" +
          type +
          "&unique_id=" +
          idUnico +
          "&ids=" +
          idSede +
          "&id=" +
          idEmpresa;
        const datosConsumo = await traerDatosObjeto(
          URI_CONSUMOS_COMBUSTION_UNICOS
        );
        // Aplicar formato a los Kwhs consumidos
        datosConsumo.gas_quantity = cambioValor(
          parseFloat(datosConsumo.gas_quantity).toFixed(2)
        );

        setTempDatosCalculo({
          gas: datosConsumo.gas,
          cantidad_gas: datosConsumo.gas_quantity,
          mes_anio: datosConsumo.month_year,
        });
      })();
    } else {
      setTempDatosCalculo({
        gas: "",
        cantidad_gas: "",
        mes_anio: "",
      });
    }
  }, [idUnico, idSede, idEmpresa, sesion.empresaActiva, sesion.sedeActiva]);

  const [show, setShow] = useState("");
  const [fetchDatos, setFetchDatos] = useState([]);

  var [tooltipVisible, setTooltipVisible] = useState({
    gas: false,
    cantidad_gas: false,
    mes_anio: false,
  });
  const [errores, setErrores] = useState({
    gas: "",
    cantidad_gas: "",
    mes_anio: "",
  });
  const campos = [
    { name: "mes_anio", validator: validarFechaPeriodo },
    { name: "gas", validator: validarSelector },
    { name: "cantidad_gas", validator: validarNumericoDecimal },
  ];
  useEffect(() => {
    const actualizarChart = async () => {
      setDatosTable(fetchDatos);
    };
    actualizarChart();
  }, [fetchDatos, show]);

  useEffect(() => {
    (async () => {
      setOpcionesCombustibles(await traerDatos(URI_COMBUSTIBLES));
      setFetchDatos(await traerDatos(URI_CONSUMOS_ENERGIA));
    })();
  }, [show, URI_COMBUSTIBLES]);
  let URI_NUEVO_CALCULO;
  const URI_CONSUMOS_ENERGIA =
    process.env.REACT_APP_DOMINIO +
    process.env.REACT_APP_PUERTO +
    "/datos_consumo?type=" +
    type_tabla +
    "&id=" +
    idEmpresa +
    "&ids=" +
    idSede;
  const elScroll = { x: 0 };
  const Columns = [
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}>{t("tablas.periodo")}</span>
        </div>
      ),
      key: "fecha",
      align: "center",
      sorter: (a, b) => a.fecha.localeCompare(b.fecha),
      render: (a) => (
        <span style={{ fontSize: "0.9em" }}>
          {moment(`${a.fecha}`, "YYYY-MM").format("MM/YYYY")}
        </span>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center", fontSize: "0.8em" }}>
          <span style={{ fontSize: "0.8em" }}>{t("tablas.cantidad")}</span>
        </div>
      ),
      align: "right",
      sorter: (a, b) => a.gas_consumido - b.gas_consumido,
      render: (a) => (
        <span style={{ fontSize: "0.9em" }}>
          {fNum(a.gas_consumido)} {"Kg "}
        </span>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center", fontSize: "0.8em" }}>
          <span style={{ fontSize: "0.8em" }}>Gas</span>
        </div>
      ),
      align: "left",
      sorter: (a, b) => -a.gas.localeCompare(b.gas),
      render: (a) => (
        <span style={{ fontSize: "0.8em" }}>
          {currentLanguage === "es" ? a.gas : a.gas_en}
        </span>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center", fontSize: "0.8em" }}>
          <span
            style={{ fontSize: "0.8em" }}
            dangerouslySetInnerHTML={{ __html: t("tablas.toneladasPorUnidad") }}
          />
        </div>
      ),
      key: "kgcodose_emitido",
      align: "right",
      sorter: (a, b) => a.kgcodose_emitido - b.kgcodose_emitido,
      render: (a) => fNum(a.kgcodose_emitido),
    },

    {
      title: () => (
        <div style={{ textAlign: "center", fontSize: "0.8em" }}>
          <span>+ Info</span>
        </div>
      ),
      key: "id",
      align: "center",
      render: (dato) => (
        <div className="row">
          <div className="col align-items-center">
            {dato.compensado === 1 && (
              <>
                <button
                  className="boton-vehiculos-nuevo justify-content-center"
                  onClick={(e) => borrar(e, dato.id)}
                >
                  <DeleteOutlined />
                </button>

                <button
                  className="boton-vehiculos-nuevo justify-content-center"
                  onClick={(e) => traerCalculo(e, dato.id)}
                >
                  <EditOutlined />
                </button>
              </>
            )}
            {dato.compensado === 2 && (
              <span>
                <b> {t("compensacion.estados.pendiente")}</b>
              </span>
            )}
            {dato.compensado === 3 && (
              <span>
                <b>{t("compensacion.estados.compensado")}</b>
              </span>
            )}
          </div>
        </div>
      ),
    },
  ];
  // Asegúrate de definir esta función según tus necesidades específicas
  const handleChangeModal = (e, index) => {
    const { value } = e.target;
    // Realiza las operaciones necesarias con los cambios en el select del modal
    console.log(`Select en la fila ${index + 1} cambió a ${value}`);
  };

  const handleQuestionIconClick = () => {
    if (!tempDatosCalculo.gas) {
      // Mostrar Sweet Alert si no se seleccionó una opción
      Swal.fire({
        imageUrl: "img/lechuza-triste.png",
        imageHeight: 65,
        title: "Error",
        text: t("mensajes.mensaje29"),
      });
    } else {
      // Abrir el modal solo si se seleccionó una opción
      Swal.fire({
        imageUrl: "img/imagen1.png",
        imageHeight: 65,
        text: "ADEME/Bilan Carbone",
      });
    }
  };

  const handleChange = async (e) => {
    const { id, value } = e.target;
    // console.log("ES : " + value);

    setTempDatosCalculo({
      ...tempDatosCalculo,
      [id]: value,
    });

    let erroresTemp = { ...errores };
    const validarCampo = async (campo, valor) => {
      const resultadoValidacion = await campo(valor, t);
      return {
        mensaje: resultadoValidacion.mensaje,
        valido: resultadoValidacion.valido,
      };
    };
    const campoActual = campos.find((campo) => campo.name === id);

    if (campoActual) {
      const mira = await validarCampo(campoActual.validator, value);
      erroresTemp[id] = mira.mensaje;
      setTooltipVisible((prevState) => ({
        ...prevState,
        [id]: !mira.valido,
      }));
      setTimeout(() => {
        setTooltipVisible((prevState) => ({
          ...prevState,
          [id]: false,
        }));
      }, 3000);
    }

    setErrores(erroresTemp);
  };

  const guardar = async (e) => {
    e.preventDefault();
    let hayErrores = false;
    const erroresTemp = {};
    for (const campo of campos) {
      const valorCampo = String(tempDatosCalculo[campo.name]);
      const resultadoValidacion = await campo.validator(valorCampo, t);

      if (resultadoValidacion.valido === false) {
        hayErrores = true;
        erroresTemp[campo.name] = resultadoValidacion.mensaje;
        setTooltipVisible((prevState) => ({
          ...prevState,
          [campo.name]: !resultadoValidacion.valido,
        }));
        setTimeout(() => {
          setTooltipVisible((prevState) => ({
            ...prevState,
            [campo.name]: false,
          }));
        }, 3000);
      }
    }
    if (hayErrores) {
      setErrores(erroresTemp);
      return;
    } else {
      tempDatosCalculo.cantidad_gas = await sacaComas(
        tempDatosCalculo.cantidad_gas
      );

      if (idUnico) {
        URI_NUEVO_CALCULO =
          process.env.REACT_APP_DOMINIO +
          process.env.REACT_APP_PUERTO +
          "/calculo_gases?id=" +
          idEmpresa +
          "&ids=" +
          idSede +
          "&unique_id=" +
          idUnico +
          "&calculo=" +
          calculo;
      } else {
        URI_NUEVO_CALCULO =
          process.env.REACT_APP_DOMINIO +
          process.env.REACT_APP_PUERTO +
          "/calculo_gases?id=" +
          idEmpresa +
          "&ids=" +
          idSede +
          "&unique_id=none&calculo=" +
          calculo;
      }

      const registro = await axios.post(
        URI_NUEVO_CALCULO,
        {
          gas: tempDatosCalculo.gas,
          gas_quantity: tempDatosCalculo.cantidad_gas,
          month_year: tempDatosCalculo.mes_anio,
        },
        HeadersAuth
      );

      if (registro.data.errors) {
        const errores = registro.data.errors;
        // console.log(errores)
        const msgError = errores.map((error) => {
          return "<pre>" + (currentLanguage === "es"
            ? error.msg.ES
            : error.msg.EN) + "</pre>";
        });
        MySwal.fire({
          html: `${msgError}`,
          imageUrl: "img/lechuza_triste.png",
          imageHeight: 65,
          confirmButtonText: "Ok",
        });
      } else {
        if (registro.data.code === 409) {
          CheckExpiracion();
        } else {
          if (registro.data.code === 200) {
            MySwal.fire({
              html: (
                <PresetResultado resultado={fNum(registro.data.result)} t={t} />
              ),
              imageUrl: "img/mensajes/resultado.svg",
              imageHeight: 65,
              confirmButtonText: "Ok",
            });
            setTempDatosCalculo({
              gas: "",
              cantidad_gas: "",
              mes_anio: "",
            });
            if (show === "") {
              setShow("guardado");
            } else {
              setShow("");
            }
            setIdUnico("");
          }
        }
      }
    }
  };
  const traerCalculo = async (e, value) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    setIdUnico(value);
  };
  const borrar = async (e, value) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    //const { value } = e.target;
    // console.log(value)
    const URI_BORRAR_CONSUMO =
      process.env.REACT_APP_DOMINIO +
      process.env.REACT_APP_PUERTO +
      "/borrar_consumo?type=" +
      type +
      "&id=" +
      idEmpresa +
      "&ids=" +
      idSede +
      "&unique_id=" +
      value;
    const borrar = await axios.delete(URI_BORRAR_CONSUMO, HeadersAuth);
    if (borrar.data.code === 401 || borrar.data.code === 402) {
      MySwal.fire({
        text: t("mensajes.mensaje12"),
        imageUrl: "img/lechuza-triste.png",
        imageHeight: 55,
        confirmButtonText: "Ok",
      });
    } else {
      if (borrar.data.code === 409) {
        CheckExpiracion();
      } else {
        if (borrar.data.code === 200) {
          MySwal.fire({
            text: t("mensajes.mensaje10"),
            imageUrl: "img/borrar.png",
            imageHeight: 50,
            confirmButtonText: "Ok",
          });
          if (show === "") {
            setShow("borrado");
          } else {
            setShow("");
          }
          setIdUnico("");
        }
      }
    }
  };

  return (
    <>
      <br></br>
      <div className="perfil row g-3 justify-content-center ms-4 me-4">
        <div className="row row-form px-2">
          <div className="col-12 col-md-4">
            <div className="contenedor-form-gases">
              <div className="contenedor-titulo w-100">
                <h2 className="titulo-calculadoras">
                  {titulo}

                  <Tooltip title={t("tooltip.ayuda")} placement="top">
                    <FaQuestionCircle
                      style={{
                        marginLeft: "5px",
                        cursor: "pointer",
                        color: "red",
                      }}
                      onClick={() => setAbrirAyuda(true)}
                    />
                  </Tooltip>
                  {idUnico ? (
                  <>
                    <Tooltip title={t("tooltip.nuevoCalculo")}>
                      <PlusOutlined
                        className="btnnuevocalculo p-1 mb-1"
                        onClick={(e) => setIdUnico("")}
                      />
                    </Tooltip>{" "}
                  </>
                ) : (
                  <></>
                )}
                </h2>
              </div>
              <form className="form-elec" onSubmit={guardar}>
               

                <div className="row pb-2 mb-3 justify-content-center custom-select-row">
                  <div
                    ref={refMes}
                    className="col-md-6 col-12 d-flex flex-column align-items-center"
                  >
                    <div className="d-flex align-items-center w-100 justify-content-start">
                      <label
                        className="select_lista text-left"
                        style={{ flex: "1 0 auto" }}
                      >
                        {t("tablas.mes")}
                      </label>{" "}
                    </div>
                    <Tooltip
                      title={
                        !!errores.mes_anio && <span>{errores.mes_anio}</span>
                      }
                      open={tooltipVisible.mes_anio}
                      color="#1CA6AF"
                    >
                      <div className="w-100">
                        <input
                          className="form-control w-100"
                          type="month"
                          min="2010-01"
                          max={`${new Date().getFullYear()}-${(
                            "0" +
                            (new Date().getMonth() + 1)
                          ).slice(-2)}`}
                          id="mes_anio"
                          value={tempDatosCalculo.mes_anio || ""}
                          onChange={handleChange}
                        />
                      </div>
                    </Tooltip>
                  </div>
                  <div
                    ref={refGas}
                    className="col-md-6 col-12 d-flex flex-column align-items-center"
                  >
                    <div className="d-flex align-items-center w-100 justify-content-start">
                      <label className="select_lista  text-left">
                        Gas
                        <Tooltip
                          title={t("tooltip.fuenteEmision")}
                          placement="top"
                        >
                          <FaQuestionCircle
                            style={{
                              marginLeft: "5px",
                              cursor: "pointer",
                              color: "#1ca5af",
                            }}
                            onClick={() =>
                              handleQuestionIconClick(fuenteEmision)
                            }
                          />
                        </Tooltip>
                      </label>{" "}
                    </div>
                    <Tooltip
                      title={!!errores.gas && <span>{errores.gas}</span>}
                      open={tooltipVisible.gas}
                      color="#1CA6AF"
                    >
                      <select
                        className="form-select form-select-sm w-100"
                        id="gas"
                        onChange={handleChange}
                        value={tempDatosCalculo.gas || ""}
                      >
                        <option key="" value="">
                          {t("empresa.datosSede.elejirOpcion")}
                        </option>
                        {opcionesCombustibles.map((option) => (
                          <option key={option.id} value={option.id}>
                            {currentLanguage === "es"
                              ? option.gas
                              : option.gas_en}
                          </option>
                        ))}
                      </select>
                    </Tooltip>
                  </div>

                  <div
                    ref={refCantidad}
                    className="col-md-6 col-12 d-flex flex-column align-items-center"
                  >
                    <div className="d-flex align-items-center w-100 justify-content-start">
                      <label
                        className="select_lista text-left"
                        style={{ flex: "1 0 auto" }}
                      >
                        {t("tablas.cantidad")}
                      </label>{" "}
                    </div>
                    <Tooltip
                      title={
                        !!errores.cantidad_gas && (
                          <span>{errores.cantidad_gas}</span>
                        )
                      }
                      open={tooltipVisible.cantidad_gas}
                      color="#1CA6AF"
                    >
                      <div className="w-100">
                        <InputPrecio
                          className="form-control"
                          idCampo={"cantidad_gas"}
                          elValue={tempDatosCalculo.cantidad_gas || ""}
                          elPlaceholder={"Gas Consumido"}
                          elSufijo={"Kg"}
                          onChange={handleChange}
                        />
                      </div>
                    </Tooltip>
                  </div>

                  <div
                    ref={refBtnGuardar}
                    className="col-md-6 col-12 d-flex flex-column align-items-center gap-1"
                  >
                     <div className="d-flex align-items-center w-100 justify-content-end mt-4">
                    <BtnPrincipal name={t("botones.calcular")} />
                  </div></div>
                </div>
              </form>
            </div>
          </div>

          <div className="col-12 col-md-8">
            <div className="contenedor-tabla-gases">
              <h2 className="texto-explicacion"> {t("tablas.info5")}</h2>
              <div ref={refTabla} className="col-12 col-md-12 ">
                <TablaCompleta
                  fetchDatos={datosTable}
                  Columns={Columns}
                  elScroll={elScroll}
                  sizeVariant="small" 
                />
              </div>
            </div>{" "}
          </div>
        </div>{" "}
      </div>
      <Tour
        open={abrirAyuda}
        onClose={() => setAbrirAyuda(false)}
        steps={pasos}
      />
    </>
  );
};
