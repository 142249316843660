import { Link } from "react-router-dom";
import { CompConfigAsesor } from "../gestion_usuarios/componentes_empresa/configuracionAsesor";
import { CompConfigEmpresa } from "../gestion_usuarios/componentes_empresa/configuracionEmpresa";
import { CardHome } from "../navegacion/card_home";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
export const ElHome = ({ sesion }) => {
  const [t] = useTranslation("global");
  return (
    <>
     {/* {parseInt(sesion.qtEmpresas) > 0
        ? (parseInt(sesion.rol) === 2 || parseInt(sesion.rol) === 4) ? (
            <CompConfigEmpresa sesion={sesion} />
          ) : (parseInt(sesion.rol) === 3 || parseInt(sesion.rol) === 5) ? (
            <CompConfigAsesor sesion={sesion} />
          ) : (
            ""
          )
        : ""}*/}

      {sesion.rol !== 0 ? (
        <>
          <div className="contendor-card-home row g-3 d-flex justify-content-center ms-4 me-3">
            <Row justify="space-around">
              {sesion.rol === 3 && sesion.qtEmpresas > 0 && (
                <Col span={200}>
                  <Link to={"empresa"}>
                    <CardHome
                       nombre={t("tooltip.empresa")}
                      nombreImagen={"ilustraciones/empresa.svg"}
                    />
                  </Link>
                </Col>
              )}

              {sesion.duenio && sesion.rol === 2 && sesion.qtEmpresas > 0 &&(
                <Col span={200}>
                  <Link to={"empresa/" + sesion.empresaActiva}>
                    <CardHome
                      nombre={t("tooltip.empresa")}
                      nombreImagen={"ilustraciones/empresa.svg"}
                    />
                  </Link>
                </Col>
              )}

              <Col span={200}>
                <Link to={"calculadoras"}>
                  <CardHome
                    nombre={t("tooltip.calculadora")}
                    nombreImagen={"ilustraciones/calculadora.svg"}
                  />
                </Link>
              </Col>

              <Col span={200}>
                <Link to={"reportes"}>
                  <CardHome
                     nombre={t("tooltip.reporte")}
                    nombreImagen={"ilustraciones/reporte.svg"}
                  />
                </Link>
              </Col>

              {sesion.rol === 2 && (
                <Col span={200}>
                  <Link to={"medidasMitigadoras"}>
                    <CardHome
                       nombre={t("tooltip.medidasMitigadoras")}
                      nombreImagen={"ilustraciones/compensar.svg"}
                    />
                  </Link>
                </Col>
              )}

              {sesion.rol === 1 && (
                <Col span={200}>
                  <Link to={"medidasMitigadorasPersonal"}>
                    <CardHome
                      nombre={t("tooltip.medidasMitigadoras")}
                      nombreImagen={"ilustraciones/compensar.svg"}
                    />
                  </Link>
                </Col>
              )}
            </Row>
          </div>
        </>
      ) : (
        <>{t("mensajes.mensaje63")}</>
      )}
    </>
  );
};
