import React from 'react';
import ReactDOM from 'react-dom/client';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';
import es_ES from 'antd/es/locale/es_ES';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import global_es from "./translations/es/global.json";
import global_en from "./translations/en/global.json";

const preferredLanguage = localStorage.getItem('preferredLanguage') || 'es';

i18next.init({
    interpolation: { escapeValue: false},
    lng: preferredLanguage,
    resources: {
        es: {
            global: global_es,
        },
        en: {
            global: global_en,
        },
    },
})
// import 'bootstrap/dist/css/bootstrap.min.css'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <I18nextProvider i18n={i18next}>
        <ConfigProvider locale={es_ES}>
            <App />
        </ConfigProvider>
    </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
