import { Button, Tabs, Col, Row, Modal, Select, Input } from "antd";
import { SearchOutlined,PlusOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { traerDatos } from "../funciones_utiles";
import { CardNueva } from "../navegacion/card";
import { CalculadorasModalCard } from "./miniCardCalculadora/cardDescCalculadora";
import { useTranslation } from "react-i18next";

const { Option } = Select;

export const CompPanelCalculadoras = ({ sesion }) => {
  const URI_CALCULADORAS = `${process.env.REACT_APP_DOMINIO}${process.env.REACT_APP_PUERTO}/calculadoras_empresa?id=${sesion.empresaActiva}`;
  const [t, i18n] = useTranslation("global");
  const currentLanguage = i18n.language;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [switcheado, setSwitcheado] = useState("");
  const [alcanceFiltro, setAlcanceFiltro] = useState("");
  const [nombreFiltro, setNombreFiltro] = useState("");
  const [listaCalculadora, setListaCalculadora] = useState([]);

  useEffect(() => {
    (async () => {
      setListaCalculadora(await traerDatos(URI_CALCULADORAS));
    })();
  }, [isModalOpen, switcheado, URI_CALCULADORAS]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleAlcanceChange = (value) => {
    setAlcanceFiltro(value);
  };

  const handleNombreChange = (e) => {
    setNombreFiltro(e.target.value);
  };

  const filteredCalculadoras = listaCalculadora
    .filter((option) => option.id_calculadora !== null)
    .filter((option) =>
      alcanceFiltro === "" ? true : option.alcance === alcanceFiltro
    )
    .filter((option) =>
      nombreFiltro === ""
        ? true
        : option.nombre.toLowerCase().includes(nombreFiltro.toLowerCase())
    )
    .sort((a, b) => {
      const alcanceOrder = { alcance_1: 1, alcance_2: 2, alcance_3: 3, "": 0 };
      return alcanceOrder[a.alcance] - alcanceOrder[b.alcance];
    });

  const alcancesDisponibles = {
    alcance_1:
      listaCalculadora.filter(
        (option) => option.alcance === "alcance_1" && option.id_empresa !== null
      ).length > 0,
    alcance_2:
      listaCalculadora.filter(
        (option) => option.alcance === "alcance_2" && option.id_empresa !== null
      ).length > 0,
    alcance_3:
      listaCalculadora.filter(
        (option) => option.alcance === "alcance_3" && option.id_empresa !== null
      ).length > 0,
  };

  const itemsTabs = [
    {
      key: "1",
      label: t("panelCalculadoras.alcanceUno"),
      children: (
        <CalculadorasModalCard
          listaCalculadora={listaCalculadora}
          titulo={t("panelCalculadoras.calculadorasAlcanceUno")}
          alcance={"alcance_1"}
          rolUsuario={sesion.rol}
          empresaActiva={sesion.empresaActiva}
          switcheado={switcheado}
          setSwitcheado={setSwitcheado}
        />
      ),
    },
    {
      key: "2",
      label: t("panelCalculadoras.alcanceDos"),
      children: (
        <CalculadorasModalCard
          listaCalculadora={listaCalculadora}
          titulo={t("panelCalculadoras.calculadorasAlcanceDos")}
          alcance={"alcance_2"}
          rolUsuario={sesion.rol}
          empresaActiva={sesion.empresaActiva}
          switcheado={switcheado}
          setSwitcheado={setSwitcheado}
        />
      ),
    },
    {
      key: "3",
      label: t("panelCalculadoras.alcanceTres"),
      children: (
        <CalculadorasModalCard
          listaCalculadora={listaCalculadora}
          titulo={t("panelCalculadoras.calculadorasAlcanceTres")}
          alcance={"alcance_3"}
          rolUsuario={sesion.rol}
          empresaActiva={sesion.empresaActiva}
          switcheado={switcheado}
          setSwitcheado={setSwitcheado}
        />
      ),
    },
  ].filter(Boolean);

  return (
    <>
 <div className="perfil row mx-3">
  <div className="col col-sedes text-start input-select-alcances">
    <label className="select_lista " style={{ display: "block", fontSize: "0.8em",textAlign:"left" }}>
    {t("panelCalculadoras.elegirAlcance")}
    </label>
    <Select
      defaultValue={""}
      placeholder="Seleccionar alcance"
      onChange={handleAlcanceChange}
      style={{ width: 200}}
    >
      <Option value="">{t("panelCalculadoras.todos")}</Option>
      {alcancesDisponibles.alcance_1 && (
        <Option value="alcance_1">{t("panelCalculadoras.alcanceUno")}</Option>
      )}
      {alcancesDisponibles.alcance_2 && (
        <Option value="alcance_2">{t("panelCalculadoras.alcanceDos")}</Option>
      )}
      {alcancesDisponibles.alcance_3 && (
        <Option value="alcance_3">{t("panelCalculadoras.alcanceTres")}</Option>
      )}
    </Select>
  </div>

  <div className="col col-sedes mt-4" style={{textAlign:"end"}}>
    <Input
      addonAfter={<SearchOutlined />}
      className=""
      placeholder= {t("panelCalculadoras.buscarPorNombre")}
      onChange={handleNombreChange}
      style={{ width: 200 }}
    />
  </div>
</div>


      <div className="cont-boton-select-calc">
        <Modal
          title={t("panelCalculadoras.seleccionarCalculadoras")}
          open={isModalOpen}
          width={900}
          onOk={handleOk}
          onCancel={() => setIsModalOpen(false)}
          footer={[
            <Button className="btn-modal" key="ok" onClick={handleOk}>
              Ok
            </Button>,
          ]}
        >
          <Tabs defaultActiveKey="1" items={itemsTabs} />
        </Modal>
      </div>
      <Row justify="space-around">
        {filteredCalculadoras.map((option) =>
          option.id_empresa !== null ? (
            <Col key={option.id} span={200}>
              <CardNueva
                title={currentLanguage === "es" ? option.nombre : option.nombre_en}
                imageSrc={option.url_imagen}
                mensaje={currentLanguage === "es" ? option.mensaje : option.mensaje_en}
                link={"/calculadoras/" + option.codigo}
                mensaje_particular={currentLanguage === "es" ? option.mensaje_particular : option.mensaje_particular_en}
                rol={sesion.rol}
                color={
                  option.alcance === "alcance_1"
                    ? "#c2e4cb"
                    : option.alcance === "alcance_2"
                    ? "#36cecc"
                    : "#27b1bf"
                }
              />
            </Col>
          ) : (
            ""
          )
        )}
      </Row>
      <div className="contenedor-btn-modal d-grid gap-2 col-12 md-auto mb-5">
        <button
          onClick={showModal}
          className="boton-modal justify-content-center align-items-center"
        >
          <span></span>
          <PlusOutlined className="button-icon"/>
        
          <span className="Button-label">{t("panelCalculadoras.seleccionarCalculadoras")}</span>
        </button>
      </div>
    </>
  );
};
