import React from "react";
import { Collapse } from "antd";
import { BtnAtras } from "../navegacion/btn_atras";
import { useTranslation } from "react-i18next";

const { Panel } = Collapse;

export const CompFaq = () => {
	const [t] = useTranslation("global");

	const onChange = (key) => {
		console.log(key);
	};

	return (
		<>
			<BtnAtras titulo={t("preguntasFrecuentes.titulo")} />

			<div className="contenedor-faq" style={{marginBottom:"1em"}}>
				<Collapse defaultActiveKey={["1"]} onChange={onChange}>
					<Panel
						className="pregunta"
						header={t("preguntasFrecuentes.pregunta1")} 
						key="1">
						<p className="respuesta">
						{t("preguntasFrecuentes.respuesta1")} 
						</p>
					</Panel>
					<Panel
						className="pregunta"
						header={t("preguntasFrecuentes.pregunta2")} 
						key="2">
						<p className="respuesta">
						{t("preguntasFrecuentes.respuesta2")} 
						</p>
					</Panel>
					<Panel
						className="pregunta"
						header={t("preguntasFrecuentes.pregunta3")} 
						key="3">
						<p className="respuesta">
							{t("preguntasFrecuentes.respuesta3")} 
						</p>
					</Panel>
					<Panel
						className="pregunta"
						header={t("preguntasFrecuentes.pregunta4")} 
						key="4">
						<p className="respuesta">
						{t("preguntasFrecuentes.respuesta4")} {" "}
						</p>
					</Panel>
					<Panel
						className="pregunta"
						header={t("preguntasFrecuentes.pregunta5")} 
						key="5">
						 <p className="respuesta" dangerouslySetInnerHTML={{ __html: t("preguntasFrecuentes.respuesta5") }} />
					</Panel>
					<Panel
						className="pregunta"
						header={<div dangerouslySetInnerHTML={{ __html: t("preguntasFrecuentes.pregunta6") }} />}
						key="6">
						 <p className="respuesta" dangerouslySetInnerHTML={{ __html: t("preguntasFrecuentes.respuesta6") }} />
					</Panel>
					<Panel
						className="pregunta"
						header={t("preguntasFrecuentes.pregunta7")} 
						key="7">
						<p className="respuesta">
						{t("preguntasFrecuentes.respuesta7")} 
						</p>
					</Panel>
				</Collapse>
			</div>
		</>
	);
};
