import { BtnAtrasCalculadoras } from "../navegacion/btn_atras_calculadoras";
import { Tooltip, Tour } from "antd";
import { TablaCompleta } from "../tablas/tabla_simple/tabla_simple";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import moment from "moment";
import withReactContent from "sweetalert2-react-content";
import {
  PlusOutlined,
  RightOutlined,
  LeftOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { InputPrecio } from "../componentes/inputComponente";
import { FaQuestionCircle } from "react-icons/fa";
import {
  traerDatos,
  HeadersAuth,
  CheckExpiracion,
  traerDatosObjeto,
  fNum,
  sacaComas,
  cambioValor,
} from "../funciones_utiles";
import {
  validarNumericoDecimal,
  validarFecha,
  validarSelector,
} from "../validaciones";
import PresetResultado from "./presetResultado";
import { BtnPrincipal } from "../navegacion/BtnPrincipal";
import { useTranslation } from "react-i18next";
const MySwal = withReactContent(Swal);

const URI_TIPO_COMBUSTIBLES =
  process.env.REACT_APP_DOMINIO +
  process.env.REACT_APP_PUERTO +
  "/combustible_logistica";
const URI_CARGA_VIAJES =
  process.env.REACT_APP_DOMINIO +
  process.env.REACT_APP_PUERTO +
  "/carga_logistica";
const URI_CALCULO_VIAJES =
  process.env.REACT_APP_DOMINIO +
  process.env.REACT_APP_PUERTO +
  "/calculo_logistica";
const URI_VEHICULOS =
  process.env.REACT_APP_DOMINIO +
  process.env.REACT_APP_PUERTO +
  "/vehiculos_logistica";
const URI_TIPO_VEHICULOS =
  process.env.REACT_APP_DOMINIO +
  process.env.REACT_APP_PUERTO +
  "/tipo_vehiculo_logistica";
const URI_AGUAS_LOGISTICA =
  process.env.REACT_APP_DOMINIO +
  process.env.REACT_APP_PUERTO +
  "/aguas_logistica";

export const CompCalculadoraLogisticaPorKm = ({
  sesion,
  type,
  type_tabla,
  alcance,
  titulo,
}) => {
  const [t, i18n] = useTranslation("global");
  const currentLanguage = i18n.language;
  const [opcionesCombustibles, setOpcionesCombustibles] = useState([]);
  const [opcionesCarga, setOpcionesCarga] = useState([]);
  const [opcionesVehiculos, setOpcionesVehiculos] = useState([]);
  const [opcionesTipoVehiculos, setOpcionesTipoVehiculos] = useState([]);
  const [opcionesTipoAguas, setOpcionesTipoAguas] = useState([]);
  const [opcionesCalculo, setOpcionesCalculo] = useState([]);

  const [idUnico, setIdUnico] = useState("");
  const [tempDatosCalculoDos, setTempDatosCalculoDos] = useState({});
  const [idEmpresa, setIdEmpresa] = useState(sesion.empresaActiva);
  const [idSede, setIdSede] = useState(sesion.sedeActiva);
  const [pasos, setPasos] = useState([]);

  const refAguas = useRef(null);
  const refCalculo = useRef(null);
  const refVehiculo = useRef(null);
  const refTipoVehiculo = useRef(null);
  const refCarga = useRef(null);
  const refCombustible = useRef(null);
  const refKm = useRef(null);
  const refTonelada = useRef(null);
  const refCantidadUnidades = useRef(null);
  const refFecha = useRef(null);
  const refBtnGuardar = useRef(null);
  const refTabla = useRef(null);

  const [abrirAyuda, setAbrirAyuda] = useState(false);

  // const pasos = [

  useEffect(() => {
    setIdEmpresa(sesion.empresaActiva);
    setIdSede(sesion.sedeActiva);
    if (idUnico) {
      (async () => {
        const URI_CONSUMOS_VIAJES_UNICOS =
          process.env.REACT_APP_DOMINIO +
          process.env.REACT_APP_PUERTO +
          "/datos_consumo_unico?type=" +
          type +
          "&unique_id=" +
          idUnico +
          "&ids=" +
          idSede +
          "&id=" +
          idEmpresa;
        const datosConsumo = await traerDatosObjeto(URI_CONSUMOS_VIAJES_UNICOS);
        // Aplicar formato a los Kwhs consumidos
        datosConsumo.kilometers_traveled = cambioValor(
          parseFloat(datosConsumo.kilometers_traveled).toFixed(2)
        );

        datosConsumo.quantity_of_units = cambioValor(
          parseFloat(datosConsumo.quantity_of_units).toFixed(2)
        );
        if (parseInt(datosConsumo.calculation) === 2) {
          datosConsumo.tons_transported = cambioValor(
            parseFloat(datosConsumo.tons_transported).toFixed(2)
          );
        }

        setTempDatosCalculoDos({
          fecha: datosConsumo.date,
          tipo_vehiculo: datosConsumo.vehicle_type,
          vehiculo: datosConsumo.vehicle,
          aguas: datosConsumo.waters,
          carga: datosConsumo.load,
          combustible: datosConsumo.fuel,
          km_recorridos: datosConsumo.kilometers_traveled,
          toneladas_transportadas: datosConsumo.tons_transported,
          cantidad_unidades: datosConsumo.quantity_of_units,
          calculo: datosConsumo.calculation,
        });
      })();
    } else {
      setTempDatosCalculoDos({
        fecha: "",
        tipo_vehiculo: "",
        vehiculo: "",
        aguas: "",
        carga: "",
        combustible: "",
        km_recorridos: "",
        toneladas_transportadas: "",
        cantidad_unidades: "",
        calculo: "",
      });
    }
  }, [
    idUnico,
    idEmpresa,
    idSede,
    sesion.empresaActiva,
    sesion.rol,
    sesion.sedeActiva,
  ]);

  useEffect(() => {
    const propsNext = {
      children: (
        <RightOutlined style={{ color: "white", marginBottom: "20px" }} />
      ),
      style: {
        height: 31,
      },
    };
    const propsFin = {
      children: (
        <CloseOutlined style={{ color: "white", marginBottom: "20px" }} />
      ),
      style: {
        height: 31,
      },
    };
    const propsPrev = {
      children: (
        <LeftOutlined style={{ color: "white", marginBottom: "20px" }} />
      ),
      style: {
        height: 31,
      },
    };
    const objetoTourFecha = {
      title: t("calculadoras.pasos.titulo11"),
      description: t("calculadoras.pasos.subtitulo11"),
      target: () => refFecha.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    };
    const objetoTourAguas = {
      title: t("calculadoras.pasos.titulo8"),
      description: t("calculadoras.pasos.subtitulo8"),
      target: () => refAguas.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    };
    const objetoTourCalculo = {
      title: t("calculadoras.pasos.titulo12"),
      description: t("calculadoras.pasos.subtitulo12"),
      target: () => refCalculo.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    };
    const objetoTourCombustible = {
      title: t("calculadoras.pasos.titulo1"),
      description: t("calculadoras.pasos.subtitulo1"),
      target: () => refCombustible.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    };
    const objetoTourTipoVehiculo = {
      title: t("calculadoras.pasos.titulo9"),
      description: t("calculadoras.pasos.subtitulo9"),
      target: () => refTipoVehiculo.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    };
    const objetoTourVehiculo = {
      title: t("calculadoras.pasos.titulo9"),
      description: t("calculadoras.pasos.subtitulo9"),
      target: () => refVehiculo.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    };
    const objetoTourCarga = {
      title: t("calculadoras.pasos.titulo13"),
      description: t("calculadoras.pasos.subtitulo13"),
      target: () => refCarga.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    };
    const objetoTourKm = {
      title: t("calculadoras.pasos.titulo14"),
      description: t("calculadoras.pasos.subtitulo14"),
      target: () => refKm.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    };
    const objetoTourTonelada = {
      title: t("calculadoras.pasos.titulo15"),
      description: t("calculadoras.pasos.subtitulo15"),
      target: () => refTonelada.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    };
    const objetoTourUnidades = {
      title: t("tablas.cantidadUnidades"),
      description: t("calculadoras.pasos.subtitulo10"),
      target: () => refCantidadUnidades.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    };
   
    const objetoTourBtnGuardar = {
      title: t("calculadoras.pasos.titulo4"),
      description: t("calculadoras.pasos.subtitulo4"),
      target: () => refBtnGuardar.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    };
    const objetoTourTabla = {
      title: t("calculadoras.pasos.titulo6"),
      description: t("calculadoras.pasos.subtitulo6"),
      target: () => refTabla.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsFin,
    };
    let pasosPorClaseVehiculo;
    if (tempDatosCalculoDos.calculo === 1) {
      pasosPorClaseVehiculo = {
        1: [
          objetoTourFecha,
          objetoTourAguas,
          objetoTourCalculo,
          objetoTourTipoVehiculo,
          objetoTourVehiculo,
          objetoTourCarga,
          objetoTourKm,
          objetoTourUnidades,    
          objetoTourBtnGuardar,
          objetoTourTabla,
        ],
        2: [
          objetoTourFecha,
          objetoTourAguas,
          objetoTourCalculo,
          objetoTourTipoVehiculo,
          objetoTourVehiculo,
          objetoTourCombustible,
          objetoTourKm,
          objetoTourUnidades,      
          objetoTourBtnGuardar,
          objetoTourTabla,
        ],
        default: [
          objetoTourFecha,
          objetoTourAguas,
          objetoTourCalculo,
          objetoTourTipoVehiculo,
          objetoTourVehiculo,
          objetoTourCarga,
          objetoTourKm,
          objetoTourUnidades,
          objetoTourBtnGuardar,
          objetoTourTabla,
        ],
      };
    } else {
      pasosPorClaseVehiculo = {
        1: [
          objetoTourFecha,
          objetoTourAguas,
          objetoTourCalculo,
          objetoTourTipoVehiculo,
          objetoTourVehiculo,
          objetoTourCarga,
          objetoTourKm,
          objetoTourTonelada,
          objetoTourUnidades,
          objetoTourBtnGuardar,
          objetoTourTabla,
        ],
        2: [
          objetoTourFecha,
          objetoTourAguas,
          objetoTourCalculo,
          objetoTourTipoVehiculo,
          objetoTourVehiculo,
          objetoTourCombustible,
          objetoTourKm,
          objetoTourTonelada,
          objetoTourUnidades,
          objetoTourBtnGuardar,
          objetoTourTabla,
        ],
        default: [
          objetoTourFecha,
          objetoTourAguas,
          objetoTourCalculo,
          objetoTourTipoVehiculo,
          objetoTourVehiculo,
          objetoTourCarga,
          objetoTourKm,
          objetoTourTonelada,
          objetoTourUnidades,
          objetoTourBtnGuardar,
          objetoTourTabla,
        ],
      };
    }

    const pasosAAplicar =
      pasosPorClaseVehiculo[tempDatosCalculoDos.tipo_vehiculo] ||
      pasosPorClaseVehiculo.default;
    setPasos(pasosAAplicar);

    let camposPorClaseVehiculo;

    if (tempDatosCalculoDos.calculo === 2) {
      camposPorClaseVehiculo = {
        1: [
          { name: "aguas", validator: validarSelector },
          { name: "calculo", validator: validarSelector },
          { name: "tipo_vehiculo", validator: validarSelector },
          { name: "vehiculo", validator: validarSelector },
          { name: "carga", validator: validarSelector },
          { name: "km_recorridos", validator: validarNumericoDecimal },
          {
            name: "toneladas_transportadas",
            validator: validarNumericoDecimal,
          },
          { name: "cantidad_unidades", validator: validarNumericoDecimal },
          { name: "fecha", validator: validarFecha },
        ],
        2: [
          { name: "aguas", validator: validarSelector },
          { name: "calculo", validator: validarSelector },
          { name: "tipo_vehiculo", validator: validarSelector },
          { name: "vehiculo", validator: validarSelector },
          { name: "combustible", validator: validarSelector },
          { name: "km_recorridos", validator: validarNumericoDecimal },
          {
            name: "toneladas_transportadas",
            validator: validarNumericoDecimal,
          },
          { name: "cantidad_unidades", validator: validarNumericoDecimal },
          { name: "fecha", validator: validarFecha },
        ],
        default: [
          { name: "aguas", validator: validarSelector },
          { name: "calculo", validator: validarSelector },
          { name: "tipo_vehiculo", validator: validarSelector },
          { name: "vehiculo", validator: validarSelector },
          { name: "carga", validator: validarSelector },
          { name: "km_recorridos", validator: validarNumericoDecimal },
          {
            name: "toneladas_transportadas",
            validator: validarNumericoDecimal,
          },
          { name: "cantidad_unidades", validator: validarNumericoDecimal },
          { name: "fecha", validator: validarFecha },
        ],
      };
    } else {
      camposPorClaseVehiculo = {
        1: [
          { name: "aguas", validator: validarSelector },
          { name: "calculo", validator: validarSelector },
          { name: "tipo_vehiculo", validator: validarSelector },
          { name: "vehiculo", validator: validarSelector },
          { name: "carga", validator: validarSelector },
          { name: "km_recorridos", validator: validarNumericoDecimal },
          { name: "cantidad_unidades", validator: validarNumericoDecimal },
          { name: "fecha", validator: validarFecha },
        ],
        2: [
          { name: "aguas", validator: validarSelector },
          { name: "calculo", validator: validarSelector },
          { name: "tipo_vehiculo", validator: validarSelector },
          { name: "vehiculo", validator: validarSelector },
          { name: "combustible", validator: validarSelector },
          { name: "km_recorridos", validator: validarNumericoDecimal },
          { name: "cantidad_unidades", validator: validarNumericoDecimal },
          { name: "fecha", validator: validarFecha },
        ],
        default: [
          { name: "aguas", validator: validarSelector },
          { name: "calculo", validator: validarSelector },
          { name: "tipo_vehiculo", validator: validarSelector },
          { name: "vehiculo", validator: validarSelector },
          { name: "carga", validator: validarSelector },
          { name: "km_recorridos", validator: validarNumericoDecimal },
          { name: "cantidad_unidades", validator: validarNumericoDecimal },
          { name: "fecha", validator: validarFecha },
        ],
      };
    }

    const camposAAplicar =
      camposPorClaseVehiculo[tempDatosCalculoDos.tipo_vehiculo] ||
      camposPorClaseVehiculo.default;

    setTooltipVisible({
      fecha: false,
      tipo_vehiculo: false,
      vehiculo: false,
      aguas: false,
      carga: false,
      combustible: false,
      km_recorridos: false,
      toneladas_transportadas: false,
      cantidad_unidades: false,
      calculo: false,
    });
    setErrores(
      camposAAplicar.reduce((obj, campo) => ({ ...obj, [campo.name]: "" }), {})
    );
    setCampos(camposAAplicar);
  }, [tempDatosCalculoDos.tipo_vehiculo, tempDatosCalculoDos.calculo]);

  var [tooltipVisible, setTooltipVisible] = useState({});
  const [errores, setErrores] = useState({});
  const [campos, setCampos] = useState([]);
  // const [fuenteEmision, setFuenteEmision] = useState("");
  const [show, setShow] = useState("");
  const [fetchDatosDos, setFetchDatosDos] = useState([]);

  let URI_NUEVO_CALCULO_KILOMETROS;
  const URI_CONSUMOS_VIAJES_KILOMETROS =
    process.env.REACT_APP_DOMINIO +
    process.env.REACT_APP_PUERTO +
    "/datos_consumo?type=" +
    type_tabla +
    "&id=" +
    idEmpresa +
    "&ids=" +
    idSede;

  useEffect(() => {
    (async () => {
      setOpcionesVehiculos(await traerDatos(URI_VEHICULOS));
      setOpcionesCalculo(await traerDatos(URI_CALCULO_VIAJES));
      setOpcionesTipoVehiculos(await traerDatos(URI_TIPO_VEHICULOS));
      setOpcionesCarga(await traerDatos(URI_CARGA_VIAJES));
      setOpcionesCombustibles(await traerDatos(URI_TIPO_COMBUSTIBLES));
      setOpcionesTipoAguas(await traerDatos(URI_AGUAS_LOGISTICA));
      // console.log(await traerDatos(URI_CONSUMOS_VIAJES_KILOMETROS))
      setFetchDatosDos(await traerDatos(URI_CONSUMOS_VIAJES_KILOMETROS));
    })();
  }, [show, URI_CONSUMOS_VIAJES_KILOMETROS]);
  const elScroll = { x: 0 };

  const ColumnsDos = [
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}> {t("tablas.fecha")}</span>
        </div>
      ),
      key: "fecha",
      align: "center",
      sorter: (a, b) => a.fecha.localeCompare(b.fecha),
      render: (a) => (
        <span style={{ fontSize: "0.9em" }}>
          {moment(`${a.fecha}`, "YYYY-MM").format("MM/YYYY")}
        </span>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}> {t("tablas.vehiculo")}</span>
        </div>
      ),
      align: "left",
      sorter: (a, b) => a.nombre_vehiculo.localeCompare(b.nombre_vehiculo),
      render: (a) => (
        <span style={{ fontSize: "0.8em" }}>
          {currentLanguage === "es" ? a.vehiculo : a.vehiculo_en}
        </span>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}> {t("tablas.tipoViaje")}</span>
        </div>
      ),
      align: "left",
      sorter: (a, b) => a.tipo_aguas.localeCompare(b.tipo_aguas),
      render: (a) => (
        <span style={{ fontSize: "0.8em" }}>
          {currentLanguage === "es" ? a.aguas : a.aguas_en}
        </span>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}>
            {t("tablas.kilometrosRecorridos")}
          </span>
        </div>
      ),
      key: "km_recorridos",
      align: "right",
      sorter: (a, b) => a.km_recorridos - b.km_recorridos,
      render: (a) => (
        <span style={{ fontSize: "0.8em" }}>{fNum(a.km_recorridos)}</span>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}>
            {" "}
            {t("tablas.toneladasTransportadas")}
          </span>
        </div>
      ),
      key: "toneladas_transportadas",
      align: "right",
      sorter: (a, b) => a.toneladas_transportadas - b.toneladas_transportadas,
      render: (a) =>
        parseFloat(a.toneladas_transportadas) !== 0
          ? fNum(a.toneladas_transportadas)
          : "-",
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}>
            {" "}
            {t("tablas.cantidadUnidades")}
          </span>
        </div>
      ),
      key: "cantidad_unidad",
      align: "right",
      sorter: (a, b) => a.cantidad_unidad - b.cantidad_unidad,
      render: (a) => (
        <span style={{ fontSize: "0.8em" }}>{fNum(a.cantidad_unidad)}</span>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span
            style={{ fontSize: "0.8em" }}
            dangerouslySetInnerHTML={{ __html: t("tablas.toneladasPorUnidad") }}
          />
        </div>
      ),
      key: "kgcodose_emitido_unidad",
      align: "right",
      sorter: (a, b) => a.kgcodose_emitido_unidad - b.kgcodose_emitido_unidad,
      render: (a) => (
        <span style={{ fontSize: "0.8em" }}>
          {fNum(a.kgcodose_emitido_unidad)}
        </span>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span
            style={{ fontSize: "0.8em" }}
            dangerouslySetInnerHTML={{ __html: t("tablas.toneladas") }}
          />
        </div>
      ),
      key: "kgcodose_emitido",
      align: "right",
      sorter: (a, b) => a.kgcodose_emitido - b.kgcodose_emitido,
      render: (a) => (
        <span style={{ fontSize: "0.8em" }}>{fNum(a.kgcodose_emitido)}</span>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}>+ Info</span>
        </div>
      ),
      key: "id",
      align: "center",
      render: (dato) => (
        <div className="row">
          <div className="col align-items-center">
            {dato.compensado === 1 && (
              <>
                <button
                  className="boton-vehiculos-nuevo justify-content-center"
                  onClick={(e) => borrarDos(e, dato.id)}
                >
                  <DeleteOutlined />
                </button>

                <button
                  className="boton-vehiculos-nuevo justify-content-center"
                  onClick={(e) => traerCalculo(e, dato.id)}
                  style={{fontSize:"0.8em", padding:"0", margin:"0"}}
                >
                  <EditOutlined />
                </button>
              </>
            )}
            {dato.compensado === 2 && (
              <span>
                <b> {t("compensacion.estados.pendiente")}</b>
              </span>
            )}
            {dato.compensado === 3 && (
              <span>
                <b>{t("compensacion.estados.compensado")}</b>
              </span>
            )}
          </div>
        </div>
      ),
    },
  ];

  const handleChangeDos = async (e) => {
    const { id, value } = e.target;
    setTempDatosCalculoDos({
      ...tempDatosCalculoDos,
      [id]: value,
    });

    let erroresTemp = { ...errores };
    const validarCampo = async (campo, valor) => {
      const resultadoValidacion = await campo(valor, t);
      return {
        mensaje: resultadoValidacion.mensaje,
        valido: resultadoValidacion.valido,
      };
    };
    const campoActual = campos.find((campo) => campo.name === id);

    if (campoActual) {
      const mira = await validarCampo(campoActual.validator, value);
      erroresTemp[id] = mira.mensaje;
      setTooltipVisible((prevState) => ({
        ...prevState,
        [id]: !mira.valido,
      }));
      setTimeout(() => {
        setTooltipVisible((prevState) => ({
          ...prevState,
          [id]: false,
        }));
      }, 3000);
    }

    setErrores(erroresTemp);
  };

  const guardarDos = async (e) => {
    e.preventDefault();

    let hayErrores = false;
    const erroresTemp = {};
    for (const campo of campos) {
      const valorCampo = String(tempDatosCalculoDos[campo.name]);
      const resultadoValidacion = await campo.validator(valorCampo, t);

      if (resultadoValidacion.valido === false) {
        hayErrores = true;
        erroresTemp[campo.name] = resultadoValidacion.mensaje;
        setTooltipVisible((prevState) => ({
          ...prevState,
          [campo.name]: !resultadoValidacion.valido,
        }));
        setTimeout(() => {
          setTooltipVisible((prevState) => ({
            ...prevState,
            [campo.name]: false,
          }));
        }, 3000);
      }
    }

    if (hayErrores) {
      setErrores(erroresTemp);
      return;
    } else {
      tempDatosCalculoDos.km_recorridos = await sacaComas(
        tempDatosCalculoDos.km_recorridos
      );
      if (parseInt(tempDatosCalculoDos.calculo) === 2) {
        tempDatosCalculoDos.toneladas_transportadas = await sacaComas(
          tempDatosCalculoDos.toneladas_transportadas
        );
      } else {
        tempDatosCalculoDos.toneladas_transportadas = "0";
      }
      tempDatosCalculoDos.cantidad_unidades = await sacaComas(
        tempDatosCalculoDos.cantidad_unidades
      );
      if (idUnico) {
        URI_NUEVO_CALCULO_KILOMETROS =
          process.env.REACT_APP_DOMINIO +
          process.env.REACT_APP_PUERTO +
          `/calculo_logistica_kilometros_tonelada?id=` +
          idEmpresa +
          "&unique_id=" +
          idUnico +
          "&scope=" +
          alcance +
          "&ids=" +
          idSede;
      } else {
        URI_NUEVO_CALCULO_KILOMETROS =
          process.env.REACT_APP_DOMINIO +
          process.env.REACT_APP_PUERTO +
          "/calculo_logistica_kilometros_tonelada?id=" +
          idEmpresa +
          "&scope=" +
          alcance +
          "&unique_id=none" +
          "&ids=" +
          idSede;
      }
      const registro = await axios.post(
        URI_NUEVO_CALCULO_KILOMETROS,
        {
          date: tempDatosCalculoDos.fecha,
          vehicle_type: tempDatosCalculoDos.tipo_vehiculo,
          vehicle: tempDatosCalculoDos.vehiculo,
          waters: tempDatosCalculoDos.aguas,
          load: tempDatosCalculoDos.carga,
          fuel: tempDatosCalculoDos.combustible,
          kilometers_traveled: tempDatosCalculoDos.km_recorridos,
          tons_transported: tempDatosCalculoDos.toneladas_transportadas,
          quantity_of_units: tempDatosCalculoDos.cantidad_unidades,
          calculation: tempDatosCalculoDos.calculo,
        },
        HeadersAuth
      );
      if (registro.data.errors) {
        const errores = registro.data.errors;
        // console.log(errores)
        const msgError = errores.map((error) => {
          return "<pre>" + (currentLanguage === "es"
            ? error.msg.ES
            : error.msg.EN) + "</pre>";
        });
        MySwal.fire({
          html: `${msgError}`,
          imageUrl: "img/lechuza_triste.png",
          imageHeight: 65,
          confirmButtonText: "Ok",
        });
      } else {
        if (registro.data.code === 409) {
          CheckExpiracion();
        } else {
          if (registro.data.code === 200) {
            MySwal.fire({
              html: (
                <PresetResultado resultado={fNum(registro.data.result)} t={t} />
              ),
              imageUrl: "img/mensajes/resultado.svg",
              imageHeight: 65,
              confirmButtonText: "Ok",
            });

            setTempDatosCalculoDos({
              fecha: "",
              tipo_vehiculo: "",
              vehiculo: "",
              aguas: "",
              carga: "",
              combustible: "",
              km_recorridos: "",
              toneladas_transportadas: "",
              cantidad_unidades: "",
              calculo: "",
            });
            if (show === "") {
              setShow("guardado");
            } else {
              setShow("");
            }
            if (idUnico) {
              setIdUnico("");
            }
          } else if (registro.data.code === 408) {
            MySwal.fire({
              html: t("mensajes.mensaje13"),
              imageUrl: "img/lechuza_triste.png",
              imageHeight: 65,
              confirmButtonText: "Ok",
            });
            setTempDatosCalculoDos({
              fecha: "",
              tipo_vehiculo: "",
              vehiculo: "",
              aguas: "",
              carga: "",
              combustible: "",
              km_recorridos: "",
              toneladas_transportadas: "",
              cantidad_unidades: "",
              calculo: "",
            });
          }
        }
      }
    }
  };
  const traerCalculo = async (e, value) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    setIdUnico(value);
  };
  const borrarDos = async (e, value) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    //const { value } = e.target;
    // console.log(value)
    const URI_BORRAR_CONSUMO =
      process.env.REACT_APP_DOMINIO +
      process.env.REACT_APP_PUERTO +
      "/borrar_consumo?type=" +
      type +
      "&id=" +
      idEmpresa +
      "&unique_id=" +
      value +
      "&ids=" +
      idSede;
    const borrar = await axios.delete(URI_BORRAR_CONSUMO, HeadersAuth);
    if (borrar.data.code === 401 || borrar.data.code === 402) {
      MySwal.fire({
        text: t("mensajes.mensaje12"),
        imageUrl: "img/lechuza-triste.png",
        imageHeight: 55,
        confirmButtonText: "Ok",
      });
    } else {
      if (borrar.data.code === 409) {
        CheckExpiracion();
      } else {
        if (borrar.data.code === 200) {
          MySwal.fire({
            text: t("mensajes.mensaje10"),
            imageUrl: "img/borrar.png",
            imageHeight: 50,
            confirmButtonText: "Ok",
          });
          if (show === "") {
            setShow("borrado");
          } else {
            setShow("");
          }
        }
      }
    }
  };
  const handleQuestionIconClick = () => {
    if (!tempDatosCalculoDos.tipo_combustible) {
      // Mostrar Sweet Alert si no se seleccionó una opción
      Swal.fire({
        imageUrl: "img/lechuza-triste.png",
        imageHeight: 65,
        title: "Error",
        text: t("mensajes.mensaje11"),
      });
    } else {
      // Abrir el modal solo si se ha seleccionado una opción
      Swal.fire({
        imageUrl: "img/imagen1.png",
        imageHeight: 65,
        text: "https://www.gov.uk/government/publications/greenhouse-gas-reporting-conversion-factors-2021",
      });
      // setModalVisible(true);
    }
  };

  return (
    <>
      <br></br>
      <div className="perfil row g-3 justify-content-center ms-2 me-2">
        <div className="row row-form px-1">
          <div className="col-12 col-md-4">
            <div className="contenedor-form-km-tn2">
              <div className="contenedor-titulo w-100">
                <h2 className="titulo-calculadoras">
                  {titulo}
                  <Tooltip title={t("tooltip.ayuda")} placement="top">
                    <FaQuestionCircle
                      style={{
                        marginLeft: "5px",
                        cursor: "pointer",
                        color: "red",
                      }}
                      onClick={() => setAbrirAyuda(true)}
                    />
                  </Tooltip>
                  {idUnico ? (
                    <>
                      <Tooltip title={t("tooltip.nuevoCalculo")}>
                        <PlusOutlined
                          className="btnnuevocalculo p-1 mb-1"
                          onClick={(e) => setIdUnico("")}
                        />
                      </Tooltip>{" "}
                      <br></br>
                    </>
                  ) : (
                    <></>
                  )}
                </h2>

                <form  onSubmit={guardarDos}>
                

                  <div className="row pb-2 mb-3 justify-content-center custom-select-row">
                    <div ref={refFecha} className="col-md-6 col-12  align-items-center">
                      <div className="d-flex align-items-center w-100 justify-content-start">
                        <label
                          className="select_lista text-left"
                          style={{ flex: "1 0 auto" }}
                        >
                          {t("tablas.fecha")}
                        </label>
                      </div>
                      <Tooltip
                        title={!!errores.fecha && <span>{errores.fecha}</span>}
                        open={tooltipVisible.fecha}
                        color="#1CA6AF"
                      >       
                        <div className="w-100">
                          <input
                            className="form-control w-100"
                            type="date"
                            min="2010-01-01"
                            max={`${new Date().getFullYear()}-${(
                              "0" +
                              (new Date().getMonth() + 1)
                            ).slice(-2)}-${("0" + new Date().getDate()).slice(
                              -2
                            )}`}
                            id="fecha"
                            value={tempDatosCalculoDos.fecha || ""}
                            onChange={handleChangeDos}
                          />{" "}
                        </div>
                      </Tooltip>
                    </div>
                    <div ref={refAguas} className="col-md-6 col-12  align-items-center">
                      <div className="d-flex align-items-center w-100 justify-content-start">
                        <label
                          className="select_lista text-left"
                          style={{ flex: "1 0 auto" }}
                        >
                          {t("tablas.tipoViaje")}
                        </label>
                      </div>
                      <Tooltip
                        title={!!errores.aguas && <span>{errores.aguas}</span>}
                        open={tooltipVisible.aguas}
                        color="#1CA6AF"
                      >
                        <select
                          className="form-select form-select-sm w-100"
                          id="aguas"
                          onChange={handleChangeDos}
                          value={tempDatosCalculoDos.aguas || ""}
                        >
                          <option value="">
                            {" "}
                            {t("empresa.datosSede.elejirOpcion")}
                          </option>
                          {opcionesTipoAguas.map((option) => (
                            <option key={option.id} value={option.id}>
                              {currentLanguage === "es"
                                ? option.aguas
                                : option.aguas_en}
                            </option>
                          ))}
                        </select>
                      </Tooltip>
                    </div>
                    <div ref={refCalculo} className="col-md-6 col-12  align-items-center" >
                      <div className="d-flex align-items-center w-100 justify-content-start">
                        <label
                          className="select_lista text-left"
                          style={{ flex: "1 0 auto" }}
                        >
                          {t("tablas.calculo")}
                        </label>
                      </div>
                      <Tooltip
                        title={
                          !!errores.calculo && <span>{errores.calculo}</span>
                        }
                        open={tooltipVisible.calculo}
                        color="#1CA6AF"
                      >
                        <select
                          className="form-select form-select-sm w-100"
                          id="calculo"
                          onChange={handleChangeDos}
                          value={tempDatosCalculoDos.calculo || ""}
                        >
                          <option value="">
                            {t("empresa.datosSede.elejirOpcion")}
                          </option>
                          {opcionesCalculo.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.calculo}
                            </option>
                          ))}
                        </select>
                      </Tooltip>
                    </div>
                    <div ref={refTipoVehiculo} className="col-md-6 col-12 align-items-center">
                      <div className="d-flex align-items-center w-100 justify-content-start">
                        <label
                          className="select_lista text-left"
                          style={{ flex: "1 0 auto" }}
                        >
                          {t("tablas.tipoVehiculo")}
                        </label> 
						</div>
                        <Tooltip
                          title={
                            !!errores.tipo_vehiculo && (
                              <span>{errores.tipo_vehiculo}</span>
                            )
                          }
                          open={tooltipVisible.tipo_vehiculo}
                          color="#1CA6AF"
                        >
                          <select
                            className="form-select form-select-sm w-100"
                            id="tipo_vehiculo"
                            onChange={handleChangeDos}
                            value={tempDatosCalculoDos.tipo_vehiculo || ""}
                          >
                            <option value="">
                              {t("empresa.datosSede.elejirOpcion")}
                            </option>
                            {opcionesTipoVehiculos.map((option) => (
                              <option key={option.id} value={option.id}>
                                {currentLanguage === "es"
                                  ? option.tipo_vehiculo
                                  : option.tipo_vehiculo_en}
                              </option>
                            ))}
                          </select>
                        </Tooltip>
                      </div>
                      <div ref={refVehiculo} className="col-md-6 col-12  align-items-center" >
                        <div className="d-flex align-items-center w-100 justify-content-start">
                          <label className="select_lista text-left"   style={{ flex: "1 0 auto" }}>
                            {t("tablas.vehiculo")}
                          </label>{" "}
                        </div>
                        <Tooltip
                          title={
                            !!errores.vehiculo && (
                              <span>{errores.vehiculo}</span>
                            )
                          }
                          open={tooltipVisible.vehiculo}
                          color="#1CA6AF"
                        >
                          <select
                            className="form-select form-select-sm w-100"
                            id="vehiculo"
                            onChange={handleChangeDos}
                            value={tempDatosCalculoDos.vehiculo || ""}
                          >
                            <option value="">
                              {t("empresa.datosSede.elejirOpcion")}
                            </option>
                            {opcionesVehiculos
                              .filter(
                                (option) =>
                                  parseInt(option.tipo) ==
                                  parseInt(tempDatosCalculoDos.tipo_vehiculo)
                              )
                              .map((option) => (
                                <option key={option.id} value={option.id}>
                                  {currentLanguage === "es"
                                    ? option.vehiculo
                                    : option.vehiculo_en}
                                </option>
                              ))}
                          </select>
                        </Tooltip>
                      </div>
                      {parseInt(tempDatosCalculoDos.tipo_vehiculo) === 2 ? (
                        <div ref={refCombustible} className="col-md-6 col-12 align-items-center">
                          <div className="d-flex align-items-center w-100 justify-content-start">
                            <label
                              className="select_lista text-left"
                              style={{ flex: "1 0 auto" }}
                            >
                              {t("tablas.combustible")}
                            </label>{" "}
                          </div>
                          <Tooltip
                            title={
                              !!errores.combustible && (
                                <span>{errores.combustible}</span>
                              )
                            }
                            open={tooltipVisible.combustible}
                            color="#1CA6AF"
                          >
                            <select
                              className="form-select form-select-sm w-100"
                              id="combustible"
                              onChange={handleChangeDos}
                              value={tempDatosCalculoDos.combustible || ""}
                            >
                              <option value="">
                                {t("empresa.datosSede.elejirOpcion")}
                              </option>
                              {opcionesCombustibles.map((option) => (
                                <option key={option.id} value={option.id}>
                                  {currentLanguage === "es"
                                    ? option.combustible
                                    : option.combustible_en}
                                </option>
                              ))}
                            </select>
                          </Tooltip>
                        </div>
                      ) : (
                        <div ref={refCarga} className="col-md-6 col-12  align-items-center" >
                          <div className="d-flex align-items-center w-100 justify-content-start">
                            <label
                              className="select_lista text-left"
                              style={{ flex: "1 0 auto" }}
                            >
                              {t("tablas.porcentajeCarga")}
                            </label>{" "}
                          </div>
                          <Tooltip
                            title={
                              !!errores.carga && <span>{errores.carga}</span>
                            }
                            open={tooltipVisible.carga}
                            color="#1CA6AF"
                          >
                            <select
                              className="form-select form-select-sm  w-100"
                              id="carga"
                              onChange={handleChangeDos}
                              value={tempDatosCalculoDos.carga || ""}
                            >
                              <option value="">
                                {t("empresa.datosSede.elejirOpcion")}
                              </option>
                              {opcionesCarga.map((option) => (
                                <option key={option.id} value={option.id}>
                                  {currentLanguage === "es"
                                    ? option.carga
                                    : option.carga_en}
                                </option>
                              ))}
                            </select>
                          </Tooltip>
                        </div>
                      )}
                      <div ref={refKm}  className="col-md-6 col-12 align-items-center">          
                        <div className="d-flex align-items-center w-100 justify-content-start">
                          <label
                            className="select_lista text-left"
                            style={{ flex: "1 0 auto" }}
                          >
                            {t("tablas.kilometrosRecorridos")}
                          </label>{" "}
                        </div>
                        <Tooltip
                          title={
                            !!errores.km_recorridos && (
                              <span>{errores.km_recorridos}</span>
                            )
                          }
                          open={tooltipVisible.km_recorridos}
                          color="#1CA6AF"
                        >
                          <div className="w-100">
                            <InputPrecio
                              className="form-control"
                              idCampo={"km_recorridos"}
                              elValue={tempDatosCalculoDos.km_recorridos}
                              elPlaceholder={"Kilometros recorridos"}
                              elSufijo={"Km"}
                              onChange={handleChangeDos}
                            />
                          </div>
                        </Tooltip>
					</div>
                      {parseInt(tempDatosCalculoDos.calculo) === 1 ? (
                        <></>
                      ) : (
                        <div ref={refTonelada} className="col-md-6 col-12 align-items-center">
                          <div className="d-flex align-items-center w-100 justify-content-start">
                            <label className="select_lista text-left"  style={{ flex: "1 0 auto" }}>
                              {t("tablas.toneladasTransportadas")}
                            </label>{" "}
                          </div>
                          <Tooltip
                            title={
                              !!errores.toneladas_transportadas && (
                                <span>{errores.toneladas_transportadas}</span>
                              )
                            }
                            open={tooltipVisible.toneladas_transportadas}
                            color="#1CA6AF"
                          >
                            <div className="w-100">
                              <InputPrecio
                                className="form-control"
                                idCampo={"toneladas_transportadas"}
                                elValue={
                                  tempDatosCalculoDos.toneladas_transportadas
                                }
                                elPlaceholder={"Toneladas Transportadas"}
                                elSufijo={"Tn"}
                                onChange={handleChangeDos}
                              />
                            </div>
                          </Tooltip>
                        </div>
                      )}
                   
                    <div ref={refCantidadUnidades} className="col-md-6 col-12  align-items-center ">
                      <div className="align-items-center w-100 justify-content-start">
                        <label className="select_lista  text-left"   >
                          {t("tablas.cantidadUnidades")}
                        </label>{" "}
                      </div>
                      <Tooltip
                        title={
                          !!errores.cantidad_unidades && (
                            <span>{errores.cantidad_unidades}</span>
                          )
                        }
                        open={tooltipVisible.cantidad_unidades}
                        color="#1CA6AF"
                      >
                        <div className="w-100">
                          <InputPrecio
                            className="form-control"
                            idCampo={"cantidad_unidades"}
                            elValue={tempDatosCalculoDos.cantidad_unidades}
                            elPlaceholder={"Cantidad de unidades"}
                            elSufijo={"U"}
                            onChange={handleChangeDos}
                          />
                        </div>
                      </Tooltip>
                    </div>
                    <div ref={refBtnGuardar} className="col-md-6 col-12 d-flex  align-items-center ">
                      <div className="d-flex align-items-center w-100 justify-content-end mt-3" style={{position:"relati"}}>
                        <BtnPrincipal name={t("botones.calcular")} />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-8">
		  <div className="contenedor-tabla-km-tn2">
              <div ref={refTabla} >
                <TablaCompleta
                  fetchDatos={fetchDatosDos}
                  Columns={ColumnsDos}
                  elScroll={elScroll}

                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Tour
        open={abrirAyuda}
        onClose={() => setAbrirAyuda(false)}
        steps={pasos}
      />
    </>
  );
};
