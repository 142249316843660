import React from "react";
import { Line } from "react-chartjs-2";
import randomColor from "randomcolor";
import moment from "moment";
import { Chart, registerables } from "chart.js";
import { useTranslation } from "react-i18next";

Chart.register(...registerables);

export const ChartEmisionesMultiMeses = ({ data }) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  // Mapeo de nombres de meses en diferentes idiomas
  const monthNames = {
    es: [
      `Ene`, `Feb`, `Mar`, `Abr`, `May`, `Jun`, `Jul`, `Ago`, `Sep`, `Oct`, `Nov`, `Dic`
    ],
    en: [
      `Jan`, `Feb`, `Mar`, `Apr`, `May`, `Jun`, `Jul`, `Aug`, `Sep`, `Oct`, `Nov`, `Dec`
    ]
  };

  // Usa el idioma actual para seleccionar los nombres de los meses
  const monthLabels = monthNames[currentLanguage];

  // Genera los nombres de los meses para el año en cuestión
  const yearMonths = monthLabels.map((month, index) => ({
    label: `${month}`,
    month: (index + 1).toString().padStart(2, '0')
  }));

  // Agrupa los datos por mes y año con nombres de meses traducidos
  const groupedData = data.reduce((acc, item) => {
    const { anio, mes_anio, total_codose } = item;
    const month = moment.utc(mes_anio).format("MM"); 
    const monthLabel = monthLabels[parseInt(month, 10) - 1]; 
    const yearMonthKey = `${month}-${anio}`;

    if (!acc[yearMonthKey]) {
      acc[yearMonthKey] = {
        label: monthLabel,
        fecha: moment(mes_anio, "YYYY-MM-DD").toDate(),
        values: {
          [anio]: total_codose,
        },
      };
    } else {
      acc[yearMonthKey].values[anio] = total_codose;
    }
    return acc;
  }, {});


  yearMonths.forEach(({ month, label }) => {
    const key = `${month}-${new Date().getFullYear()}`; 
    if (!groupedData[key]) {
      groupedData[key] = {
        label,
        fecha: new Date(`${new Date().getFullYear()}-${month}-01T03:00:00.000Z`),
        values: {},
      };
    }
  });

  // Prepara los datasets
  const datasets = {};
  Object.keys(groupedData).forEach((key) => {
    const item = groupedData[key];
    Object.keys(item.values).forEach((anio) => {
      const total_codose = item.values[anio];
      const datasetKey = `dataset_${anio}`;
      if (!datasets[datasetKey]) {
        const colorRamdomPunto = randomColor({
          hue: "red",
          format: "hex",
        });
        const colorRamdomFondo = randomColor({
          hue: "#1CA6AF",
          format: "hex",
        });
        const colorRamdomBorde = randomColor({
          hue: "random",
          luminosity: "dark",
          format: "hex",
        });
        datasets[datasetKey] = {
          label: anio.toString(),
          data: yearMonths.map(() => 0),
          fill: true,
          backgroundColor: colorRamdomFondo,
          pointBackgroundColor: colorRamdomPunto,
          borderColor: colorRamdomBorde,
          tension: 0.2,
        };
      }
      const monthIndex = yearMonths.findIndex(({ month: m }) => m === item.fecha.toISOString().slice(5, 7));
      datasets[datasetKey].data[monthIndex] += total_codose;
    });
  });

  const chartData = {
    labels: yearMonths.map((item) => item.label),
    datasets: Object.values(datasets),
  };

  const chartOptions = {
    maintainAspectRatio: false,
    title: {
      display: true,
      text: "Gráfico de emisiones de CO2",
    },
    scales: {
      x: {
        ticks: {
          autoSkip: false,
          maxTicksLimit: 12,
          suggestedMin: 0,
          suggestedMax: 1000,
        },
      },
      y: {
        min: 0,
        ticks: {
          stepSize: 50,
        },
        title: {
          display: true,
          text: "tCO2eq",
          font: {
            size: 10,
            weight: 'bold'
          },
        },
      },
    },
    height: 500,
  };

  return (
    <Line style={{ height: "20rem" }} data={chartData} options={chartOptions} />
  );
};
