import React from "react";
import { Card, Row, Col } from "antd";
import { fNum } from "../funciones_utiles";
import { useTranslation } from "react-i18next";

export const DashboardCardMain = ({
  datoResultado,
  titulo,
  indirectas,
  directas
}) => {
	const [t] = useTranslation("global");

  return (
    <Card
      bordered={true}
      hoverable={true}
      style={{
        height: "96%",
      }}
    >
      <Row>
        <h2
          style={{
            fontSize: "1rem",
            fontWeight: "1000",
            color: "#1CA6AF",
          }}
        >
          {titulo}
        </h2>
      </Row>
      <Row>
        <Col type="flex" align="end" style={{ width: "100%" }}>
          <h4
            style={{
              fontSize: "2.8rem",
              fontWeight: "900",
            }}
          >
            {fNum(parseFloat(datoResultado))}
          </h4>
          <h2
            style={{
              fontSize: "1.4rem",
              fontWeight: "900",
            }}
          >
            					<span dangerouslySetInnerHTML={{ __html: t('tablas.toneladas') }} />

          </h2>
        </Col>
      </Row>
      <br></br> <br></br>
      <Row>
        <Col
          type="flex"
          align="start"
          style={{
            width: "100%",
            color: "#1CA6AF",
          }}
        >
          <h2
            style={{
              fontSize: "1.23rem",
              fontWeight: "900",
            }}
          >
            {t("reportes.seccionEstadisticas.emisionesDirectas")}: {fNum(directas)} %
          </h2>

          <h2
            style={{
              fontSize: "1.23rem",
              fontWeight: "900",
            }}
          >
             {t("reportes.seccionEstadisticas.emisionesIndirectas")}: {fNum(indirectas)} %
          </h2>

          
        </Col>
      </Row>
    </Card>
  );
};
