import { Avatar, List, Input, Tooltip, Button } from "antd";
import axios from "axios";
import moment from "moment";
import { TablaCompleta } from "../../tablas/tabla_simple/tabla_simple";
import { useState, useEffect, useRef, useMemo } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { IoIosAlert } from "react-icons/io";
import { CardPlanesInternos } from "../../navegacion/CardsPlanesInterno";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  traerDatos,
  HeadersAuth,
  traerDatosObjeto,
  CheckExpiracion,
} from "../../funciones_utiles";
import { useTranslation } from "react-i18next";
const MySwal = withReactContent(Swal);

const URI_PLANES =
  process.env.REACT_APP_DOMINIO + process.env.REACT_APP_PUERTO + "/planes";
const URI_PAGO =
  process.env.REACT_APP_DOMINIO +
  process.env.REACT_APP_PUERTO +
  "/generar_pago_interno";
export const CompDatosSubscripciones = ({ sesion, id_empresa }) => {
  const [t, i18n] = useTranslation("global");
  const currentLanguage = i18n.language;
  const recaptchaRef = useRef(null);

  const [cargaScreen, setCargaScreen] = useState(false);

  const plan = sesion.datosPlanes.find(
    (item) => parseInt(item.id_empresa) === parseInt(id_empresa)
  );
  const [opcionesEmpresas, setOpcionesEmpresas] = useState([]);
  const [opcionesAsesor, setOpcionesAsesor] = useState([]);
  const [tipoSubscripcion, setTipoSubscripcion] = useState("");
  const [idSubscripcion, setIdSubscripcion] = useState("");
  const [precioDolar, setPrecioDolar] = useState("");
  const [nombrePlan, setNombrePlan] = useState("");
  const [URLpago, setURLpago] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [id_empresa]);

  useMemo(() => {
    (async () => {
      setOpcionesEmpresas(await traerDatos(URI_PLANES + "/2"));
      setOpcionesAsesor(await traerDatos(URI_PLANES + "/3"));
    })();
  }, []);
  const cleanVars = async () => {
    setIdSubscripcion("");
    setTipoSubscripcion("");
    setPrecioDolar("");
    setNombrePlan("");
  };

  const elejir = async (valor, id, precio, nombre, e) => {
    e.preventDefault();
    console.log(valor);
    console.log(id);
    console.log(precio);
    console.log(nombre);
    setTipoSubscripcion(valor);
    setIdSubscripcion(id);
    setPrecioDolar(precio);
    setNombrePlan(nombre);
    const generarPago = await axios.post(URI_PAGO + "?id=" + id_empresa, {
      idSub: id,
    });
    //navigate('/')
    if (generarPago.data.errors) {
      const errores = generarPago.data.errors;
      
      const msgError = errores.map((error) => {
        return "<pre>" + (currentLanguage === "es" ? error.msg.ES :  error.msg.EN) + "</pre>";
      });
      MySwal.fire({
        html: `${msgError}`,
        imageUrl: "img/lechuza-triste.png",
        imageHeight: 55,
        confirmButtonText: "Ok",
      });
    } else {
      if (generarPago.data.code === 200) {
        setURLpago(generarPago.data.url);
      } else if (generarPago.data.code === 406) {
        cleanVars();
        MySwal.fire({
          html: t("mensajes.mensaje58"),
          imageUrl: "img/lechuza-triste.png",
          imageHeight: 55,
          confirmButtonText: "Ok",
        });
      }
    }
  };

  const irApago = async (url, e) => {
    e.preventDefault();
    setCargaScreen(true);
    cleanVars();
    window.location.href = url;
  };

  return (
    <>
      <div className="row  container-fluid justify-content-center align-items-center "  style={{ marginBottom: "2rem" }}>
        <div className="col d-flex ">
          <div className="card-empresa p-3" style={{ height: "12em", width: "62em",maxWidth: "100%"}}>
            <div className="row  no-gutters">
              <div className="col">
                <h5 className="texto-suscripcion">{t("empresa.suscripcion1.suscripcionActual")}</h5>{" "}
               
                <h1 className="texto-tipo-empresa">
                  {currentLanguage === "es" ?  sesion.datosPlanes[0].tipo : sesion.datosPlanes[0].tipo_en}
                </h1>
               
                <span className="span-suscripcion">
                {t("empresa.suscripcion1.diasRestantes")}{" "}
                  {parseInt(sesion.datosPlanes[0].premium) === 1
                    ? sesion.infoSubscripcion.dias_restantes +t("empresa.suscripcion1.dias" )
                    : "Ilimitado"}
                </span>{" "}
                <br></br>
               
                <span className="span-suscripcion">
                {t("empresa.suscripcion1.fechaInicio")}{" "}
                  {moment(
                    `${sesion.infoSubscripcion.fecha_inicio}`,
                    "YYYY-MM-DD"
                  ).format("DD/MM/YYYY")}
                </span>{" "}
                <br></br>
                
                {parseInt(sesion.datosPlanes[0].premium) === 1 ? (
                  
                  <span className="span-suscripcion">
                     {t("empresa.suscripcion1.fechaFin")}{" "}{" "}
                    {moment(
                      `${sesion.infoSubscripcion.fecha_fin}`,
                      "YYYY-MM-DD"
                    ).format("DD/MM/YYYY")}
                  </span>
                ) : (
                  <></>
                )}{" "}
                <br></br>
             
              </div>
            </div>
            
          </div>
        </div>
        <div className="container-fluid text-center justify-content-center align-items-center">

        {sesion.infoSubscripcion.renovacion_activa ? (
          <h2
            style={{
              fontSize: "1rem",
              fontWeight: "1000",
              color: "#1CA6AF",
            }}
          >
           <IoIosAlert  className="icono-card"
            /> {t("empresa.suscripcion1.planSolicitado")}{" "}
            {sesion.infoSubscripcion.renovacion_nombre}
          </h2>
        ) : // <>YA TENES UNA Subscripcion ACTIVA</>
        URLpago === "" ? (
          parseInt(sesion.rol) === 2 ? (
            
              <CardPlanesInternos
              style={{maxWidth:"100%"}}
                nombreImagen={"usuario_empresa.png"}
                arrPlanes={opcionesEmpresas}
                funcion={elejir}
                planActual={plan.orden_numerico}
                diasFaltantes={sesion.infoSubscripcion.dias_restantes}
              />
           
          ) : parseInt(sesion.rol) === 3 ? (
           
              <CardPlanesInternos
                nombreImagen={"usuario_asesor.png"}
                arrPlanes={opcionesAsesor}
                funcion={elejir}
                planActual={plan.orden_numerico}
                diasFaltantes={sesion.infoSubscripcion.dias_restantes}
              />
        
          ) : (
            <></>
          )
        ) : (
          <>
           
              <div className="form-btn-login ">
                {plan.id === idSubscripcion ? (
                  <h1 className="titulo-checkout">{t("empresa.suscripcion1.renovarSuscripcion")}</h1>
                ) : (
                  <h1 className="titulo-checkout-suscripcion text-left">{t("empresa.suscripcion1.cambiarPlan")}</h1>
                )}
              </div>
              <hr className="linea-separadora" /> <br></br>
              <div className="text-left align-items-left">
                <span className="span-checkout-dato">{nombrePlan}</span>
                <br></br>
                <span className="span-checkout-dato">
                  USD {precioDolar} {t("planes.año")}
                </span>
                <br></br>
              </div>
              {/* RUBRO RUBRO RUBRO RUBRO RUBRO RUBRO */}
              <Button
                className="btn-checkout"
                onClick={(e) => irApago(URLpago, e)}
              >
                {" "}
                {t("empresa.suscripcion1.pagar")}{" "}
              </Button>
         
          </>
        )}</div>
      </div>
    </>
  );
};
