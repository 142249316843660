import React from "react";
import { Card, Typography, Button } from "antd";
import { FaCheckCircle } from "react-icons/fa";
import { Tabs } from "antd";
import { useTranslation } from "react-i18next";

const { Text } = Typography;
const { TabPane } = Tabs;

export const CardPlanes = ({ funcion, nombreImagen, arrPlanes }) => {
  const [t, i18n] = useTranslation("global");
  const currentLanguage = i18n.language;

  return (
    <>
      {arrPlanes.map((plan) => {
        const tipoSubscripcion =
          currentLanguage === "es"
            ? plan.tipo_subscripcion
            : plan.tipo_subscripcion_en;

        return (
          <Card
            key={plan.id}
            title={
              currentLanguage === "es"
                ? plan.tipo_subscripcion
                : plan.tipo_subscripcion_en
            }
            hoverable
            style={{
              width: "20em",
              height: "28em",
              border: "1px solid #89BEC2",
              margin: "1rem",
              backgroundColor: plan.id == 10 ? "#f7f3cf" : "#FFFFFF",
              textAlign: "center",
              fontWeight: "400",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                style={{ height: "4em", width: "4em", marginTop: "0.5em" }}
                alt=""
                src={"./img/" + nombreImagen}
              />
            </div>

            <hr style={{ borderTop: "2px solid #757575" }} />

            <div
              key={1}
              style={{
                display: "flex",
                alignItems: "left",
                justifyContent: "left",
                marginBottom: "0.5em",
              }}
            >
              <FaCheckCircle
                className="icono-card"
                style={{ marginRight: "0.5em" }}
              />
              <Text>
                {t("planes.cantidadEmpresas")} {plan.max_empresas}
              </Text>
            </div>
            <div
              key={2}
              style={{
                display: "flex",
                alignItems: "left",
                justifyContent: "left",
                marginBottom: "0.5em",
              }}
            >
              <FaCheckCircle
                className="icono-card"
                style={{ marginRight: "0.5em" }}
              />
              <Text>
                {t("planes.cantidadSedes")} {plan.max_sedes}
              </Text>
            </div>
            <div
              key={3}
              style={{
                display: "flex",
                alignItems: "left",
                justifyContent: "left",
                marginBottom: "0.5em",
              }}
            >
              <FaCheckCircle
                className="icono-card"
                style={{ marginRight: "0.5em" }}
              />
              <Text>
                {t("planes.cantidadUsuarios")}
                {plan.max_usuarios}
              </Text>
            </div>
            <div
              key={4}
              style={{
                display: "flex",
                alignItems: "left",
                justifyContent: "left",
                marginBottom: "0.5em",
              }}
            >
              <FaCheckCircle
                className="icono-card"
                style={{ marginRight: "0.5em" }}
              />
              <Text>
                {t("planes.apiIntegradora")}{" "}
                {currentLanguage === "es"
                  ? parseInt(plan.habilita_api) === 1
                    ? " Si"
                    : " No"
                  : parseInt(plan.habilita_api) === 1
                  ? " Yes"
                  : " No"}{" "}
              </Text>
            </div>
			<div
  key={5}
  style={{
    display: "flex",
    alignItems: "left",
    justifyContent: "center",
    marginBottom: "0.5em",
  }}
>
  <h2
    className="valor-cards"
    style={{
      marginBottom: isNaN(parseFloat(plan.precio_dolar)) ||
        parseFloat(plan.precio_dolar) === 0
        ? "1em" // Agrega espacio extra si es "Free!"
        : "0",
    }}
  >
    {isNaN(parseFloat(plan.precio_dolar)) ||
    parseFloat(plan.precio_dolar) === 0 ? (
      "Free!"
    ) : (
      <>
        USD {parseFloat(plan.precio_dolar).toFixed(2)}
        <span style={{ display: "block", fontSize: "0.8em" }}>
          {t("planes.año")}
        </span>
      </>
    )}
  </h2>
</div>

            <Button
              onClick={(e) =>
                funcion(
                  plan.rol_general,
                  plan.id,
                  plan.precio_dolar,
                  tipoSubscripcion,
                  e
                )
              }
              type="primary"
              className="boton-card px-3"
              style={{ display: "block", margin: "0 auto" }}
            >
              {t("botones.seleccionar")}
            </Button>
          </Card>
        );
      })}
    </>
  );
};
