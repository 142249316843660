import React, { useState } from "react";
import { Table, Input } from "antd";
import { useTableSearch } from "./useTableSearch";
import { useTranslation } from "react-i18next";

const { Search } = Input;

export const TablaCompleta = ({ fetchDatos, Columns, elScroll, expandableData, sizeVariant }) => {
  const [t] = useTranslation("global");
  const [searchVal, setSearchVal] = useState(null);

  const { filteredData, loading } = useTableSearch({
    searchVal,
    retrieve: fetchDatos,
  });

  if (!expandableData) {
    expandableData = "Not Expandable";
  }

  // Estilos condicionales
  const containerStyle = {
    marginTop: "1em",
    height: sizeVariant === 'large' ? '17em' : sizeVariant === 'small' ? '20em' : 'auto',
    overflow: 'auto',
  };

  // Estilo para hacer los renglones más chicos
  const rowStyle = {
    height: '1em', // Ajusta esta altura según sea necesario
  };

  return (
    <>
      <div className="search-wrapper">
        <Search
          onChange={(e) => setSearchVal(e.target.value)}
          placeholder=""
          enterButton
          style={{ position: "sticky", top: "0", left: "0", height:"0.8em" }}
        />
      </div>

      <div style={containerStyle}>
        <Table
          rowKey="id"
          dataSource={[...filteredData]}
          columns={Columns}
          loading={loading}
          scroll={elScroll}
          expandable={expandableData}
          pagination={{ position: ["bottomCenter"], pageSize: 4 }}
          size="small"
          rowClassName={() => 'custom-row'}
        />
      </div>
    </>
  );
};

// CSS para la clase de los renglones
const styles = {
  '.custom-row': {
    height: '30px',
  },
};

export default TablaCompleta;
