import { BtnAtras } from "../navegacion/btn_atras";
import { BottomTop } from "../navegacion/btn_flotante_top";
import { Card, List, Table, Checkbox, Input, Avatar, Tour } from "antd";
import axios from "axios";
import moment from "moment";
import "moment/locale/es";
import { Link } from "react-router-dom";
import { DashboardCard } from "../navegacion/DashboardCard";
import { DashboardCardMain } from "../navegacion/DashboardCardMain";
import { ChartEmisiones } from "../charts/ChartEmisiones";
import { ChartEmisionesMultiMeses } from "../charts/ChartEmisionesMultiMeses";
import { ChartPie } from "../charts/ChartPieDefault";
import { ChartBarras } from "../charts/ChartBarrasVertical";
import { NotFound404 } from "../navegacion/404_not_found";
import { HeadersAuth } from "../funciones_utiles";
import { ModalProyecto } from "./modalProyecto";
import { fNum, traerDatos, CheckExpiracion } from "../funciones_utiles";
import { Tooltip, Modal } from "antd";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import React, { useState, useEffect, useRef } from "react";
import { MdOutlineChangeCircle } from "react-icons/md";
import { useSesion } from "../navegacion/sesionContexto";
import {
  DeleteOutlined,
  FilePdfOutlined,
  HourglassOutlined,
  CheckOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { FaQuestionCircle, FaEye, FaLeaf } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const MySwal = withReactContent(Swal);

const MemoizedChartAnios = React.memo(ChartEmisionesMultiMeses);

export const CompPanelReportes = () => {
  const [t, i18n] = useTranslation("global");
  const currentLanguage = i18n.language;
  const { sesion } = useSesion();
  const [idEmpresa, setIdEmpresa] = useState(sesion.empresaActiva);
  const [idSede, setIdSede] = useState(sesion.sedeActiva);
  const plan = sesion.datosPlanes.find(
    (item) => parseInt(item.id_empresa) === parseInt(sesion.empresaActiva)
  );

  const URI_DATOS_REPORTE =
    process.env.REACT_APP_DOMINIO +
    process.env.REACT_APP_PUERTO +
    "/datos_reporte";
  const URI_DATOS_REPORTE_MACRO =
    process.env.REACT_APP_DOMINIO +
    process.env.REACT_APP_PUERTO +
    "/datos_reporte_macro";
  const URI_PROYECTOS =
    process.env.REACT_APP_DOMINIO + process.env.REACT_APP_PUERTO + "/proyectos";
  var [loaderBtn, setLoaderBtn] = useState(false);
  var [year, setYear] = useState("");
  const [dataReportes, setDataReportes] = useState({});
  const [dataCompensaciones, setDataCompensaciones] = useState([]);
  // const [dataPdfs, setDataPdfs] = useState([]);
  const [dataChart, setDataChart] = useState([]);
  const [elejirProyecto, setElejirProyecto] = useState();
  const [trigger, setTrigger] = useState(false);
  const [losProyectos, setLosProyectos] = useState();
  const [hayDatos, setHayDatos] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [filteredList, setFilteredList] = useState();
  const [optionsYears, setOptionYears] = useState([]);
  const [modalId, setModalId] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const showModal2 = () => {
    setIsModalOpen2(true);
  };

  const handleOk2 = () => {
    setIsModalOpen2(false);
  };

  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };
  const [isModalOpen3, setIsModalOpen3] = useState(false);

  const showModal3 = () => {
    setIsModalOpen3(true);
  };

  const handleOk3 = () => {
    setIsModalOpen3(false);
  };

  const handleCancel3 = () => {
    setIsModalOpen3(false);
  };
  // const [seccion, setSeccion] = useState("");
  const handleChangeAnio = (anio) => {
    // const { value } = e.currentTarget.target;
    // console.log(value);
    setYear(anio);
  };
  useEffect(() => {
    (async () => {
      const datosMacro = await axios.post(
        URI_DATOS_REPORTE_MACRO,
        {
          id: idEmpresa,
          id_sede: idSede,
        },
        HeadersAuth
      );
      // console.log("HAY: "+ datosMacro.data.mes_anio.length)
      if (datosMacro.data.emisiones_anio.length > 0) {
        setHayDatos(true);
      } else {
        setHayDatos(false);
      }
      setOptionYears(datosMacro.data.emisiones_anio);
      setDataChart(datosMacro.data.mes_anio);
    })();
  }, [idEmpresa, URI_DATOS_REPORTE_MACRO, idSede]);
  useEffect(() => {
    (async () => {
      const reportes = await axios.post(
        URI_DATOS_REPORTE,
        {
          anio: year,
          id: idEmpresa,
          id_sede: idSede,
        },
        HeadersAuth
      );
      const URI_COMPENSADAS =
        process.env.REACT_APP_DOMINIO +
        process.env.REACT_APP_PUERTO +
        "/datos_compensaciones_realizadas?id=" +
        idEmpresa +
        "&ids=" +
        idSede +
        "&anio=" +
        year;
      // const URI_PDFS =
      // 	process.env.REACT_APP_DOMINIO +
      // 	process.env.REACT_APP_PUERTO +
      // 	"/datos_pdfs?id=" +
      // 	idEmpresa +
      // 	"&ids=" +
      // 	idSede +
      // 	"&anio=" +
      // 	year;

      // console.log("Compensadas:"+ await traerDatos(URI_COMPENSADAS))

      setDataCompensaciones(await traerDatos(URI_COMPENSADAS));
      // setDataPdfs(await traerDatos(URI_PDFS));
      // console.log(reportes.data)
      setDataReportes(reportes.data);
      // console.log(await traerDatos(URI_PROYECTOS));
      setLosProyectos(await traerDatos(URI_PROYECTOS));
    })();
  }, [
    year,
    idEmpresa,
    elejirProyecto,
    trigger,
    URI_DATOS_REPORTE,
    URI_PROYECTOS,
    idSede,
  ]);

  useEffect(() => {
    setFilteredList((prevFilteredList) => {
      if (inputValue) {
        return prevFilteredList.filter(
          (item) =>
            item.nombre
              .toString()
              .toLowerCase()
              .indexOf(inputValue.toString().toLowerCase()) > -1
        );
      } else {
        return losProyectos;
      }
    });
  }, [inputValue, losProyectos]);
  function onSearch(e) {
    setInputValue(e.target.value);
  }
  const [activeTabKey2, setActiveTabKey2] = useState();
  const [listaTab, setListaTab] = useState([]);
  const [contenidoTab, setContenidoTab] = useState({});
  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const elScroll = { x: 0 };
  const Columns = [
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span>{t("compensacion.tabla.mes")}</span>
        </div>
      ),
      key: "mes",
      align: "center",
      sorter: (a, b) => a.mes - b.mes,
      render: (a) => {
        if (currentLanguage === "es") {
          moment.locale("es"); // Configurar idioma español
        } else {
          moment.locale("en"); // Configurar idioma inglés
        }
        return moment(`${a.mes}`, "MM").format("MMM");
      },
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span>{t("compensacion.tabla.sinCompensar")}</span>
        </div>
      ),
      key: "sin_compensar",
      align: "right",
      sorter: (a, b) => a.tcodose_no_compensadas - b.tcodose_no_compensadas,
      render: (a) => (
        <>
          {fNum(a.tcodose_no_compensadas)} tCO<sub>2eq</sub>
        </>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span>{t("compensacion.estados.pendiente")}</span>
        </div>
      ),
      key: "pendiente",
      align: "right",
      sorter: (a, b) => a.tcodose_pendiente - b.tcodose_pendiente,
      render: (a) => (
        <>
          {fNum(a.tcodose_pendiente)} tCO<sub>2eq</sub>
        </>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span>{t("compensacion.estados.compensado")} </span>
        </div>
      ),
      key: "compensado",
      align: "right",
      sorter: (a, b) => a.tcodose_compensadas - b.tcodose_compensadas,
      render: (a) => (
        <>
          {fNum(a.tcodose_compensadas)} tCO<sub>2eq</sub>
        </>
      ),
    },
  ];
  // const ColumnsPdfs = [
  // 	{
  // 		title: () => (
  // 			<div style={{ textAlign: "center" }}>
  // 				<span>PDF's</span>
  // 			</div>
  // 		),
  // 		key: "fecha",
  // 		align: "left",
  // 		sorter: (a, b) => a.fecha - b.fecha,
  // 		render: (a) =>
  // 			t("reportes.seccionReportes.tablaReporte" ) +
  // 			a.anio +    " " +
  // 			t("reportes.seccionReportes.fechaReporte")  +
  // 			moment(`${a.fecha}`, "YYY-MM-DD").format("DD/MM//YY"),
  // 	},
  // 	{
  // 		title: "",
  // 		key: "buttons",
  // 		align: "center",
  // 		render: (dato) => (
  // 			<div className="row">
  // 				<div className="col align-items-center">
  // 					<button
  // 						className="boton-vehiculos-nuevo justify-content-center"
  // 						onClick={(e) => borrar(e,dato.id)}>

  // 						<DeleteOutlined />
  // 					</button>
  // 					{loaderBtn ? (
  // 						<button
  // 							className="boton-vehiculos-nuevo justify-content-center"
  // 							value={dato.id}
  // 							disabled>
  // 							<FilePdfOutlined />
  // 						</button>
  // 					) : (
  // 						<button
  // 							className="boton-vehiculos-nuevo justify-content-center"
  // 							value={dato.id}
  // 							onClick={REgenerarPDF}>
  // 							<FilePdfOutlined />
  // 						</button>
  // 					)}
  // 				</div>
  // 			</div>
  // 		),
  // 	},
  // ];
  const ColumnsCompensaciones = [
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span>{t("compensacion.tabla.mes")}</span>
        </div>
      ),
      key: "mes",
      align: "center",
      sorter: (a, b) => a.mes - b.mes,
      render: (a) => {
        if (currentLanguage === "es") {
          moment.locale("es"); // Configurar idioma español
        } else {
          moment.locale("en"); // Configurar idioma inglés
        }
        return moment(`${a.mes}`, "MM").format("MMM");
      },
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span>{t("compensacion.tabla.proyecto")}</span>
        </div>
      ),
      key: "proyecto",
      align: "left",
      sorter: (a, b) => a.nombre_proyecto.localeCompare(b.nombre_proyecto),
      render: (a) => a.nombre_proyecto,
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span>{t("compensacion.tabla.proveedor")}</span>
        </div>
      ),
      key: "proveedor",
      align: "left",
      sorter: (a, b) => a.compensadora.localeCompare(b.compensadora),
      render: (a) => a.compensadora,
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span>{t("tablas.cantidad")}</span>
        </div>
      ),
      key: "cantidad",
      align: "right",
      sorter: (a, b) => a.cantidad - b.cantidad,
      render: (a) => (
        <span
          dangerouslySetInnerHTML={{
            __html: fNum(a.cantidad) + " tCO<sub>2eq</sub>",
          }}
        />
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span>{t("compensacion.tabla.estado")}</span>
        </div>
      ),
      key: "estado",
      align: "left",
      sorter: (a, b) => a.estado.localeCompare(b.estado),
      render: (a) =>
        parseInt(a.estado_num) !== 3
          ? `${currentLanguage === "es" ? a.estado : a.estado_en}, ${moment(
              a.fecha_pedido,
              "YYYY-MM-DD"
            ).format("DD/MM/YYYY")}`
          : `${currentLanguage === "es" ? a.estado : a.estado_en}, ${moment(
              a.fecha_compensacion,
              "YYYY-MM-DD"
            ).format("DD/MM/YYYY")}`,
    },
    {
      title: "",
      key: "botones",
      align: "center",
      render: (dato) => (
        <div className="row">
          <div className="col align-items-center">
            {parseInt(dato.estado_num) === 2 ? (
              <button
                className="boton-vehiculos-nuevo justify-content-center"
                value={dato.id}
                onClick={cancelarCompensacion}
              >
                <HourglassOutlined />
              </button>
            ) : (
              <CheckOutlined />
            )}
          </div>
        </div>
      ),
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filasSeleccionadas, setFilasSeleccionadas] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [sumaTcodoseNoCompensadas, setSumaTcodoseNoCompensadas] = useState();

  const toggleSelectAll = () => {
    const nuevasFilasSeleccionadas =
      selectedRowKeys.length === filteredData.length
        ? []
        : filteredData.map((r) => r.mes);
    setSelectedRowKeys(nuevasFilasSeleccionadas);
    const objetosFilasSeleccionadas = filteredData.filter((r) =>
      nuevasFilasSeleccionadas.includes(r.mes)
    );
    setFilasSeleccionadas(objetosFilasSeleccionadas);
  };
  const headerCheckbox = (
    <>
      <Checkbox
        id="switchAll"
        size="small"
        checked={selectedRowKeys.length}
        indeterminate={
          selectedRowKeys.length > 0 &&
          selectedRowKeys.length < filteredData.length
        }
        onChange={toggleSelectAll}
      ></Checkbox>
    </>
  );
  const rowSelection = {
    columnTitle: headerCheckbox,
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setFilasSeleccionadas(selectedRows);
    },
  };
  useEffect(() => {
    if (dataReportes.datos_reportes_mes) {
      // console.log(dataReportes.datos_reportes_mes)
      setFilteredData(
        dataReportes.datos_reportes_mes.length > 0
          ? dataReportes.datos_reportes_mes.filter(
              (record) => parseFloat(record.tcodose_no_compensadas) !== 0
            )
          : []
      );
    }
  }, [dataReportes.datos_reportes_mes]);
  const cancelarCompensacion = async (e) => {
    e.preventDefault();
    const { value } = e.target;
    const objetoCancelar = {
      id_compensacion: value,
    };
    let validarCompensar = MySwal.fire({
      title: t("mensajes.mensaje17"),
      text: ``,
      imageUrl: "img/borrar.png",
      imageHeight: 65,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("botones.aceptar"),
      cancelButtonText: t("botones.cancelar"),
    });
    if ((await validarCompensar).isConfirmed) {
      const URI_CANCELAR_COMPENSAR =
        process.env.REACT_APP_DOMINIO +
        process.env.REACT_APP_PUERTO +
        "/cancelar_compensacion_mensual?id=" +
        idEmpresa +
        "&ids=" +
        idSede;
      const compensar = await axios.post(
        URI_CANCELAR_COMPENSAR,
        objetoCancelar,
        HeadersAuth
      );
      if (compensar.data.code === 402) {
        MySwal.fire({
          text: t("mensajes.mensaje12"),
          imageUrl: "img/lechuza-triste.png",
          imageHeight: 55,
          confirmButtonText: "Ok",
        });
      } else if (compensar.data.code === 401) {
        MySwal.fire({
          text: t("mensajes.mensaje12"),
          imageUrl: "img/lechuza-triste.png",
          imageHeight: 55,
          confirmButtonText: "Ok",
        });
      } else {
        if (compensar.data.code === 409) {
          CheckExpiracion();
        } else {
          if (compensar.data.code === 200) {
            MySwal.fire({
              text: t("mensajes.mensaje18"),
              imageUrl: "img/borrar.png",
              imageHeight: 50,
              confirmButtonText: "Ok",
            });
          }
          trigger ? setTrigger(false) : setTrigger(true);
        }
      }
    }
  };
  useEffect(() => {
    setSumaTcodoseNoCompensadas(
      filasSeleccionadas.reduce((acumulador, filasSeleccionadas) => {
        return acumulador + filasSeleccionadas.tcodose_no_compensadas;
      }, 0)
    );
  }, [filasSeleccionadas]);

  const onTab2Change = (key) => {
    setActiveTabKey2(key);
  };
  useEffect(() => {
    setIdEmpresa(sesion.empresaActiva);
    setIdSede(sesion.sedeActiva);
    // console.log("los reportes: " + JSON.stringify(dataReportes));

    const listaTab = [];
    const contenidoTab = {};

    if (dataReportes.datos_reportes) {
      dataReportes.datos_reportes.forEach((reporte) => {
        const nombre = reporte.nombre;
        const nombre_en = reporte.nombre_en;
        const datos = reporte.datos;

        if (datos && datos.length > 0) {
          const key = `grafico_${nombre.toLowerCase().replace(/\s/g, "_")}`;

          listaTab.push({
            key: key,
            tab: (
              <b className="subtitulo-grafico">
                {currentLanguage === "es" ? nombre : nombre_en}{" "}
                <img
                  src="./img/img_activada.png"
                  alt=""
                  className="img-fluid img-tabla"
                />
              </b>
            ),
            disabled: false,
          });

          contenidoTab[key] = <ChartEmisiones data={datos} year={year} />;
        }
      });
    }
    setActiveTabKey2(listaTab.length > 0 ? listaTab[0].key : "");
    // console.log(listaTab.length > 0 ? listaTab[0].key : "NO EXISTO")
    setListaTab(listaTab);
    setContenidoTab(contenidoTab);
  }, [dataReportes, year, sesion.empresaActiva, sesion.sedeActiva]);
  const listaTabFiltrada = listaTab.filter((tab) => !tab.disabled);
  const alcancesArrayLabels = [
    t("panelCalculadoras.alcanceUno"),
    t("panelCalculadoras.alcanceDos"),
    t("panelCalculadoras.alcanceDos"),
  ];
  const alcancesEtiqueta = t("reportes.seccionEstadisticas.alcances");
  const alcancesArrayData = [
    dataReportes.total_alcance_uno,
    dataReportes.total_alcance_dos,
    dataReportes.total_alcance_tres,
  ];
  const alcancesArrayBackground = ["#c2e4cb", "#36cecc", "#27b1bf"];
  const alcancesArrayBorder = ["#cef0ee", "#9de3e0"];
  const alcancesTitulo = t("reportes.seccionEstadisticas.alcances");
  const emisionesArrayData = [
    dataReportes.total_alcance_uno,
    dataReportes.total_alcance_dos + dataReportes.total_alcance_tres,
  ];
  const emisionesArrayLabels = [
    t("reportes.seccionEstadisticas.emisionesDirectas"),
    t("reportes.seccionEstadisticas.emisionesIndirectas"),
  ];
  const emisionesArrayBackground = ["#36cecc", "#27b1bf"];
  const emisionesArrayBorder = ["#cef0ee", "#9de3e0"];

  const compensarUno = (e) => {
    e.preventDefault();
    if (!sesion.checkPerfil || !sesion.checkSede) {
      let mensajePerfil;
      let mensajeSede;

      if (sesion.checkPerfil) {
        mensajePerfil = "";
      } else {
        mensajePerfil = t("mensajes.mensaje74");
      }
      if (sesion.checkSede) {
        mensajeSede = "";
      } else {
        if (sesion.duenio) {
          mensajeSede = t("mensajes.mensaje75");
        } else {
          mensajeSede = t("mensajes.mensaje76");
        }
      }

      MySwal.fire({
        html: `
                ${mensajePerfil}</br>${mensajeSede}
                
                `,
        icon: "warning",
        confirmButtonText: "Ok",
      });
    } else {
      setElejirProyecto(true);
    }
  };
  const atrasCompensar = (e) => {
    e.preventDefault();
    setElejirProyecto();
  };
  const verProyecto = (e, id) => {
    e.preventDefault();
    // console.log("EL ID", id);
    setModalId(id);
    setIsModalVisible(true);
  };
  const handleModalClose = () => {
    setIsModalVisible(false);
  };
  const compensarFinal = async (e, id_proyecto) => {
    e.preventDefault();
    if (!sesion.checkPerfil || !sesion.checkSede) {
      let mensajePerfil;
      let mensajeSede;

      if (sesion.checkPerfil) {
        mensajePerfil = "";
      } else {
        mensajePerfil = t("mensajes.mensaje74");
      }
      if (sesion.checkSede) {
        mensajeSede = "";
      } else {
        if (sesion.duenio) {
          mensajeSede = t("mensajes.mensaje75");
        } else {
          mensajeSede = t("mensajes.mensaje76");
        }
      }

      MySwal.fire({
        html: `
                ${mensajePerfil}</br>${mensajeSede}
                
                `,
        icon: "warning",
        confirmButtonText: "Ok",
      });
    } else {
      let objetoCompensar = {
        listaMeses: filasSeleccionadas,
        sumaTotalToneladas: sumaTcodoseNoCompensadas,
        id_proyecto: id_proyecto,
        anio: year,
      };
      let validarCompensar = MySwal.fire({
        title: t("mensajes.mensaje32", {
          cantidad: fNum(sumaTcodoseNoCompensadas),
          unidad: t("tablas.toneladas2"),
        }),
        text: ``,
        imageUrl: "img/mensajes/resultado.svg",
        imageHeight: 65,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("botones.aceptar"),
        cancelButtonText: t("botones.cancelar"),
      });

      if ((await validarCompensar).isConfirmed) {
        const URI_COMPENSAR =
          process.env.REACT_APP_DOMINIO +
          process.env.REACT_APP_PUERTO +
          "/nueva_compensacion?id=" +
          idEmpresa +
          "&ids=" +
          idSede;
        const compensar = await axios.post(
          URI_COMPENSAR,
          objetoCompensar,
          HeadersAuth
        );
        if (compensar.data.code === 402) {
          MySwal.fire({
            text: t("mensajes.mensaje12"),
            imageUrl: "img/lechuza-triste.png",
            imageHeight: 55,
            confirmButtonText: "Ok",
          });
        } else if (compensar.data.code === 401) {
          MySwal.fire({
            text: t("mensajes.mensaje12"),
            imageUrl: "img/lechuza-triste.png",
            imageHeight: 55,
            confirmButtonText: "Ok",
          });
        } else {
          if (compensar.data.code === 409) {
            CheckExpiracion();
          } else {
            if (compensar.data.code === 200) {
              MySwal.fire({
                text: t("mensajes.mensaje34"),
                imageUrl: "img/mensajes/resultado.svg",
                imageHeight: 50,
                confirmButtonText: "Ok",
              });
            }
            setElejirProyecto();
            setSumaTcodoseNoCompensadas();
            setFilasSeleccionadas([]);
            trigger ? setTrigger(false) : setTrigger(true);
          }
        }
      }
    }
  };
  const generarPDF = (event, id, anio, id_sede) => {
    event.preventDefault();
    if (!sesion.checkPerfil || !sesion.checkSede) {
      let mensajePerfil;
      let mensajeSede;
      if (sesion.checkPerfil) {
        mensajePerfil = "";
      } else {
        mensajePerfil = t("mensajes.mensaje74");
      }
      if (sesion.checkSede) {
        mensajeSede = "";
      } else {
        if (sesion.duenio) {
          mensajeSede = t("mensajes.mensaje75");
        } else {
          mensajeSede = t("mensajes.mensaje76");
        }
      }

      MySwal.fire({
        html: `
                ${mensajePerfil}</br>${mensajeSede}
                
                `,
        icon: "warning",
        confirmButtonText: "Ok",
      });
    } else {
      const URI_PDF_RESULTADO =
        process.env.REACT_APP_DOMINIO +
        process.env.REACT_APP_PUERTO +
        plan.reporte_premium;
      // var loading = false
      setLoaderBtn(true);
      axios
        .post(
          URI_PDF_RESULTADO,
          { id: id, id_sede: id_sede, anio: anio, lang: currentLanguage },
          {
            withCredentials: true,
            credentials: "include",
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
              Accept: "application/pdf",
            },
          }
        )
        .then((response) => {
          if (parseInt(response.data.code) === 200) {
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, "0");
            const day = String(currentDate.getDate()).padStart(2, "0");
            const hours = String(currentDate.getHours()).padStart(2, "0");
            const minutes = String(currentDate.getMinutes()).padStart(2, "0");
            const seconds = String(currentDate.getSeconds()).padStart(2, "0");
            const milliseconds = String(currentDate.getMilliseconds()).padStart(
              3,
              "0"
            );

            const uniqueHash = `${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}`;
            const nombreHash = Math.imul(31, id);
            // console.log(url)
            setLoaderBtn(false);

            // const url = window.URL.createObjectURL(new Blob([new Uint8Array(response.data).buffer]));
            const link = document.createElement("a");
            link.href =
              "data:application/octet-stream;base64," + response.data.pdf;
            link.setAttribute(
              "download",
              "reporte_owltrack_" + uniqueHash + nombreHash + ".pdf"
            );
            document.body.appendChild(link);
            link.click();
            trigger ? setTrigger(false) : setTrigger(true);
          } else if (
            parseInt(response.data.code) === 401 &&
            parseInt(response.data.code) === 409 &&
            parseInt(response.data.code) === 601
          ) {
            MySwal.fire({
              text: t("mensajes.mensaje12"),
              imageUrl: "img/lechuza-triste.png",
              imageHeight: 55,
              confirmButtonText: "Ok",
            });
            setLoaderBtn(false);
          } else if (parseInt(response.data.code) === 400) {
            MySwal.fire({
              text: t("mensajes.mensaje77"),
              imageUrl: "img/lechuza-triste.png",
              imageHeight: 55,
              confirmButtonText: "Ok",
            });
            setLoaderBtn(false);
          }
        })
        .catch((error) => console.log(error));
    }
  };

  const REgenerarPDF = (e) => {
    e.preventDefault();
    if (!sesion.checkPerfil || !sesion.checkSede) {
      let mensajePerfil;
      let mensajeSede;
      if (sesion.checkPerfil) {
        mensajePerfil = "";
      } else {
        mensajePerfil = t("mensajes.mensaje74");
      }
      if (sesion.checkSede) {
        mensajeSede = "";
      } else {
        if (sesion.duenio) {
          mensajeSede = t("mensajes.mensaje75");
        } else {
          mensajeSede = t("mensajes.mensaje76");
        }
      }

      MySwal.fire({
        html: `
                ${mensajePerfil}</br>${mensajeSede}
                
                `,
        icon: "warning",
        confirmButtonText: "Ok",
      });
    } else {
      const { value } = e.target;
      const URI_REGENERAR_PDF =
        process.env.REACT_APP_DOMINIO +
        process.env.REACT_APP_PUERTO +
        "/regenerar_pdf?id=" +
        idEmpresa +
        "&ids=" +
        idSede +
        "&idp=" +
        value;
      // var loading = false
      setLoaderBtn(true);
      axios
        .get(URI_REGENERAR_PDF, {
          withCredentials: true,
          credentials: "include",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/pdf",
          },
        })
        .then((response) => {
          // console.log(response.data.pdf)
          if (parseInt(response.data.code) === 200) {
            const nombreHash = Math.imul(31, value);
            // console.log(url)
            setLoaderBtn(false);

            // const url = window.URL.createObjectURL(new Blob([new Uint8Array(response.data).buffer]));
            const link = document.createElement("a");
            link.href =
              "data:application/octet-stream;base64," + response.data.pdf;
            link.setAttribute(
              "download",
              "reporte_" + year + "_" + nombreHash + ".pdf"
            );
            document.body.appendChild(link);
            link.click();
            trigger ? setTrigger(false) : setTrigger(true);
          } else if (
            parseInt(response.data.code) === 401 &&
            parseInt(response.data.code) === 409 &&
            parseInt(response.data.code) === 601
          ) {
            MySwal.fire({
              text: t("mensajes.mensaje12"),
              imageUrl: "img/lechuza-triste.png",
              imageHeight: 55,
              confirmButtonText: "Ok",
            });
            setLoaderBtn(false);
          }
        })
        .catch((error) => console.log(error));
    }
  };

  const borrar = async (e, value) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    //const { value } = e.target;
    // console.log(e.target);
    const URI_BORRAR_PDFS =
      process.env.REACT_APP_DOMINIO +
      process.env.REACT_APP_PUERTO +
      "/borrar_pdf?id=" +
      idEmpresa +
      "&ids=" +
      idSede +
      "&idp=" +
      value;
    const borrar = await axios.delete(URI_BORRAR_PDFS, HeadersAuth);
    if (borrar.data.code === 401 || borrar.data.code === 402) {
      MySwal.fire({
        text: t("mensajes.mensaje12"),
        imageUrl: "img/lechuza-triste.png",
        imageHeight: 55,
        confirmButtonText: "Ok",
      });
    } else {
      if (borrar.data.code === 409) {
        CheckExpiracion();
      } else {
        if (borrar.data.code === 200) {
          MySwal.fire({
            text: t("mensajes.mensaje10"),
            imageUrl: "img/borrar.png",
            imageHeight: 50,
            confirmButtonText: "Ok",
          });
          trigger ? setTrigger(false) : setTrigger(true);
        }
      }
    }
  };

  // Funciones para manejar el clic en los botones
  const handleEstadisticasClick = () => {
    // setSeccion("estadisticas");
    scrollToSection("estadisticas");
  };

  const handleNuevoReporteClick = () => {
    // setSeccion("nuevo-reporte");
    scrollToSection("nuevo-reporte");
  };

  const handleCompensarClick = () => {
    // setSeccion("compensar");
    scrollToSection("compensar");
  };
  // Función para hacer scroll hacia la sección correspondiente
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      {year === "" ? (
        !hayDatos ? (
          <BtnAtras titulo={"Upss!"} />
        ) : (
          <BtnAtras titulo={t("reportes.seccionReportes.reportes")} />
        )
      ) : (
        <BtnAtras titulo={`${t("reportes.general.titulo")} ${year}`} />
      )}
      {plan ? (
        hayDatos ? (
          // optionsYears.length > 0 ?
          <div className="perfil row g-3 px-3 d-flex justify-content-center align-items-center  mt-2 panel-reporte mb-5">
            <div className="row justify-content-center">
              {year === "" ? (
                <>
                  <div className="col-md-4 col-input-calculadoras">
                    <Card
                      bordered={true}
                      hoverable={true}
                      style={{ height: "24em", marginBottom: "1em" }}
                    >
                      <h2
                        style={{
                          fontSize: "1rem",
                          fontWeight: "1000",
                          color: "#1CA6AF",
                        }}
                      >
                        {t("reportes.general.elejirAño")}
                      </h2>
                      <List
                        pagination={{
                          size: "small",
                          pageSize: 3,
                          position: "top",
                          align: "end",
                        }}
                        itemLayout="horizontal"
                        dataSource={optionsYears}
                        renderItem={(item) => (
                          <List.Item
                            actions={[
                              <Tooltip
                                placement="left"
                                title={t("reportes.general.verReporte")}
                                className="tooltip-head"
                              >
                                <button
                                  style={{ border: "0", background: "none" }}
                                  onClick={() => handleChangeAnio(item.anio)}
                                >
                                  <EyeOutlined />
                                </button>
                              </Tooltip>,
                            ]}
                          >
                            <List.Item.Meta
                              title={
                                <b style={{ fontSize: "1.2rem" }}>
                                  {item.anio}
                                </b>
                              }
                              description={
                                <b>
                                  {parseFloat(item.total_codose).toFixed(2) +
                                    " tCO"}
                                  <sub>2eq</sub> {t("reportes.general.emitido")}
                                </b>
                              }
                            />
                          </List.Item>
                        )}
                      />
                    </Card>
                  </div>
                  <div className="col-md-8 col-input-calculadoras">
                    <Card bordered={true} hoverable={true}>
                      <div>
                        <MemoizedChartAnios data={dataChart} />
                      </div>
                    </Card>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-md-12 ">
                    <div className="row justify-content-start pb-3">
                      <div className="col-md-12 col-ms-12 d-flex flex-row justify-content-between align-items-center">
                        <button
                          className="btnprincipal p-1"
                          onClick={() => setYear("")}
                          style={{
                            margin: "0",
                            width: "9em",
                            height: "3em",
                          }}
                        >
                          {t("reportes.general.cambiarAño")}
                        </button>

                        <div className="d-flex">
                          <button
                            className="btnprincipal d-flex p-1 mx-1"
                            onClick={handleEstadisticasClick}
                          >
                            {t("reportes.seccionEstadisticas.estadisticas")}
                          </button>
                          <button
                            className="btnprincipal d-flex p-1 mx-1"
                            onClick={handleNuevoReporteClick}
                          >
                            {t("reportes.seccionReportes.nuevoReporte")}
                          </button>
                          {sesion.duenio ? (
                            <button
                              className="btnprincipal d-flex p-1 mx-1"
                              onClick={handleCompensarClick}
                            >
                              {t("compensacion.general.compensar")}
                            </button>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>

                    <Modal
                      className="custom-modal"
                      title={t("reportes.seccionEstadisticas.estadisticas")}
                      open={isModalOpen}
                      onOk={handleOk}
                      onCancel={handleCancel}
                      okText={t("botones.aceptar")}
                      cancelText={t("botones.cancelar")}
                      cancelButtonProps={{ className: "custom-cancel-button" }}
                    >
                      <div className="row align-items-center">
                        <div className="col-md-6">
                          <img
                            src="../img/ayuda1.png"
                            className="img-ayuda"
                          ></img>
                        </div>
                        <div className="col-md-6 ">
                          <p>
                            {t("reportes.seccionEstadisticas.ayuda.ayuda1")}
                          </p>
                        </div>
                      </div>
                      <hr></hr>
                      <div className="row">
                        <div className="col-md-6">
                          <img
                            src="../img/ayuda2.png"
                            className="img-ayuda"
                          ></img>
                        </div>
                        <div className="col-md-6 ">
                          <p>
                            {t("reportes.seccionEstadisticas.ayuda.ayuda2")}
                          </p>
                        </div>
                      </div>
                      <hr></hr>
                      <div className="row">
                        <div className="col-md-6">
                          <img
                            src="../img/ayuda3.png"
                            className="img-ayuda"
                          ></img>
                        </div>
                        <div className="col-md-6 ">
                          <p>
                            {" "}
                            {t("reportes.seccionEstadisticas.ayuda.ayuda3")}
                          </p>
                        </div>
                      </div>
                    </Modal>
                    <section id="estadisticas" className="section">
                      <div className="row justify-content-between ">
                        <div className="col col-xl-4 col-md-12 ">
                          <DashboardCardMain
                            titulo={
                              <>
                                {t("reportes.seccionEstadisticas.totalEmitido")}
                                <Tooltip
                                  title={t("tooltip.ayuda")}
                                  placement="top"
                                >
                                  <FaQuestionCircle
                                    style={{
                                      marginLeft: "5px",
                                      cursor: "pointer",
                                      color: "red",
                                      fontSize: "1em",
                                      marginBottom: "5px",
                                    }}
                                    onClick={showModal}
                                  />
                                </Tooltip>
                              </>
                            }
                            datoResultado={dataReportes.total_general}
                            directas={dataReportes.total_porcentaje_directas}
                            indirectas={
                              dataReportes.total_porcentaje_indirectas
                            }
                            style={{ paddingBottom: "2em" }}
                          />
                        </div>
                        <div className="col col-xl-4 col-md-12 mb-2">
                          <Card bordered={true} hoverable={true}>
                            <h2
                              style={{
                                fontSize: "1rem",
                                fontWeight: "600",
                                color: "#1CA6AF",
                              }}
                            >
                              {t("reportes.seccionEstadisticas.alcances")}
                            </h2>
                            <span>
                              <div>
                                <ChartPie
                                  ArrayLabels={alcancesArrayLabels}
                                  Etiqueta={alcancesEtiqueta}
                                  ArrayData={alcancesArrayData}
                                  ArrayBackground={alcancesArrayBackground}
                                  ArrayBorder={alcancesArrayBorder}
                                  Titulo={alcancesTitulo}
                                />
                              </div>
                            </span>
                          </Card>
                        </div>
                        <div className="col col-xl-4 col-md-12  mb-2 ">
                          <Card bordered={true} hoverable={true}>
                            <h2
                              style={{
                                fontSize: "1rem",
                                fontWeight: "600",
                                color: "#1CA6AF",
                              }}
                            >
                              {t("reportes.seccionEstadisticas.tipoEmisiones")}
                            </h2>
                            <span>
                              <div>
                                <ChartBarras
                                  ArrayLabels={emisionesArrayLabels}
                                  ArrayData={emisionesArrayData}
                                  ArrayBackground={emisionesArrayBackground}
                                  ArrayBorder={emisionesArrayBorder}
                                />
                              </div>
                            </span>
                          </Card>
                        </div>

                        <div
                          className="row justify-content-center"
                          style={{ marginLeft: "0.1em" }}
                        >
                          {!dataReportes || !dataReportes.datos_calculadoras ? (
                            <div>
                              {t("reportes.seccionEstadisticas.cargandoDatos")}
                            </div>
                          ) : (
                            Object.values(dataReportes.datos_calculadoras).map(
                              (calculadora, index) =>
                                calculadora.total !== 0 ? (
                                  <div
                                    className="col-md-4 col-6"
                                    key={`${calculadora.codigo}-${index}`}
                                  >
                                    <DashboardCard
                                      titulo={`${
                                        currentLanguage === "es"
                                          ? calculadora.nombre
                                          : calculadora.nombre_en
                                      }`}
                                      datoResultado={parseFloat(
                                        calculadora.total
                                      ).toFixed(2)}
                                    />
                                  </div>
                                ) : null
                            )
                          )}
                        </div>
                      </div>

                      <div className="row justify-content-start pb-3">
                        <div className="col-xl-10">
                          <Card
                            bordered={true}
                            hoverable={true}
                            tabList={listaTabFiltrada}
                            activeTabKey={activeTabKey2}
                            tabBarExtraContent={
                              <Link to={"/calculadoras"}>
                                {t(
                                  "reportes.seccionEstadisticas.irCalculadora"
                                )}
                              </Link>
                            }
                            onTabChange={onTab2Change}
                            className="tab-reportes"
                          >
                            {contenidoTab[activeTabKey2]}
                          </Card>
                        </div>
                        <div className="col-xl-2 contenedor-reporte ">
                          <Card bordered={true} hoverable={true} style={{height:"100%"}}>
                            <div className="row row-usuarios">
                              <h2
                                style={{
                                  fontSize: "1rem",
                                  fontWeight: "600",
                                  color: "#1CA6AF",
                                }}
                              >
                                {t("reportes.seccionReportes.reportes")} {year}
                                <Tooltip
                                  title={t("tooltip.ayuda")}
                                  placement="top"
                                >
                                  <FaQuestionCircle
                                    style={{
                                      marginLeft: "5px",
                                      cursor: "pointer",
                                      color: "red",
                                      fontSize: "1em",
                                      marginBottom: "5px",
                                    }}
                                    onClick={showModal2}
                                  />
                                </Tooltip>
                              </h2>
                              {/* <div className="col-md-2"> */}

                              {/* <Table
																		rowKey="id"
																		dataSource={dataPdfs}
																		columns={ColumnsPdfs}
																		scroll={elScroll}
																		pagination={{
																			position: ["bottomRight"],
																			pageSize: 3,
																		}}
																		size="small"
																	/> */}
                              {/* </div> */}
                              <div
                                className="col-md-12 justify-content-center align-items-center text-center  d-flex"
                                id="nuevo-reporte"
                              >
                                {loaderBtn ? (
                                  <>
                                    <div className="justify-content-center align-items-center text-center ">
                                      <Card
                                        className="card-con-cursor text-center"
                                        style={{
                                       
                                          border:"none",
                                          fontSize: "1rem",
                                          fontWeight: "600",
                                          color: "#1CA6AF",
                                        }}
                                      >
                                        <h2
                                          style={{
                                            fontSize: "1rem",
                                            fontWeight: "600",
                                            color: "#1CA6AF",
                                          }}
                                        >
                                          {t(
                                            "reportes.seccionReportes.generandoReporte"
                                          )}
                                        </h2>

                                        <img
                                          src="img/lechuza-reporte.png"
                                          alt="Lechuza OWLTRACK Reporte"
                                          style={{ width: "5em" }}
                                          className="img-reportes"
                                        ></img>
                                      </Card>{" "}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="justify-content-center align-items-center text-center">
                                      <Card
                                        className="card-con-cursor text-center "
                                        onClick={(event) =>
                                          generarPDF(
                                            event,
                                            idEmpresa,
                                            year,
                                            idSede
                                          )
                                        }
                                        style={{
                                          fontSize: "1rem",
                                          fontWeight: "600",
                                          color: "#1CA6AF",
                                        
                                        }}
                                      >
                                        <h2
                                          style={{
                                            fontSize: "1rem",
                                            fontWeight: "600",
                                            color: "#1CA6AF",
                                            marginTop:"2em"
                                          }}
                                        >
                                          {t(
                                            "reportes.seccionReportes.nuevoReporte"
                                          )}
                                        </h2>

                                        <img
                                          src="img/lechuza-reporte.png"
                                          alt="Lechuza OWLTRACK reporte"
                                          style={{ width: "5em" }}
                                          className="img-reportes"
                                        ></img>
                                      </Card>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </Card>
                        </div>{" "}
                      </div>
                    </section>

                    <Modal
                      className="custom-modal"
                      title={t("reportes.seccionReportes.reportes")}
                      open={isModalOpen2}
                      onOk={handleOk2}
                      onCancel={handleCancel2}
                      okText={t("botones.aceptar")}
                      cancelText={t("botones.cancelar")}
                      cancelButtonProps={{ className: "custom-cancel-button" }}
                    >
                      <div className="row">
                        <div className="col-md-6">
                          <img
                            style={{ width: "50%" }}
                            src="../img/ayuda4.png"
                            className="img-ayuda"
                          ></img>
                        </div>
                        <div className="col-md-6">
                          <p style={{ marginLeft: "1em", marginTop: "3em" }}>
                            {t("reportes.seccionReportes.ayuda.ayuda1")}
                          </p>
                        </div>
                      </div>
                    </Modal>

                    <Modal
                      className="custom-modal"
                      title={t("compensacion.general.compensacion")}
                      open={isModalOpen3}
                      onOk={handleOk3}
                      onCancel={handleCancel3}
                      okText={t("botones.aceptar")}
                      cancelText={t("botones.cancelar")}
                      cancelButtonProps={{ className: "custom-cancel-button" }}
                    >
                      <div className="row">
                        <div className="col-md-6">
                          <p style={{ marginLeft: "1em" }}>
                            {" "}
                            1 - {t("compensacion.ayuda.ayuda1")}
                          </p>

                          <img
                            src="../img/ayuda5.png"
                            className="img-ayuda"
                          ></img>
                        </div>
                        <div className="col-md-6">
                          <p style={{ marginLeft: "1em" }}>
                            2 - {t("compensacion.ayuda.ayuda2parte1")}
                            <FaEye className="mx-2" />
                            {t("compensacion.ayuda.ayuda2parte2")}
                            <FaLeaf className="mx-2" />
                            {t("compensacion.ayuda.ayuda2parte3")}
                          </p>
                          <img
                            src="../img/ayuda6.png"
                            className="img-ayuda"
                          ></img>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <p style={{ marginLeft: "1em" }}>
                            {" "}
                            3 - {t("compensacion.ayuda.ayuda3")}
                          </p>

                          <img
                            src="../img/ayuda7.png"
                            className="img-ayuda"
                          ></img>
                        </div>
                        <div className="col-md-6">
                          <p style={{ marginLeft: "1em" }}>
                            {" "}
                            4 - {t("compensacion.ayuda.ayuda4")}
                          </p>

                          <img
                            src="../img/ayuda8.png"
                            className="img-ayuda"
                          ></img>
                        </div>
                      </div>
                    </Modal>
                    <section id="compensar" className="section ">
                      {sesion.duenio ? (
                        <>
                          <Card bordered={true} hoverable={true}>
                            <div className="row justify-content-center align-items-center">
                              <div className="col-md-4 text-center ">
                                <img
                                  src="img/lechuza-compensar.png"
                                  alt="Lechuza OWLTRACK compensar"
                                  style={{ width: "8em" }}
                                  className="img-reportes"
                                ></img>
                              </div>
                              <div className="col-md-8">
                                <h2
                                  style={{
                                    fontSize: "1rem",
                                    fontWeight: "600",
                                    color: "#1CA6AF",
                                  }}
                                >
                                  {t("compensacion.informacion.titulo")} {year}
                                  <Tooltip
                                    title={t("tooltip.ayuda")}
                                    placement="top"
                                  >
                                    <FaQuestionCircle
                                      style={{
                                        marginLeft: "5px",
                                        cursor: "pointer",
                                        color: "red",
                                        fontSize: "1em",
                                        marginBottom: "5px",
                                      }}
                                      onClick={showModal3}
                                    />
                                  </Tooltip>
                                </h2>

                                {filteredData.length > 0 ? (
                                  !elejirProyecto ? (
                                    <>
                                      {" "}
                                      <h2 className="texto-explicacion">
                                        {" "}
                                        {t(
                                          "compensacion.informacion.subtitulo"
                                        )}
                                      </h2>
                                      <Table
                                        rowKey="mes"
                                        rowSelection={{
                                          type: "checkbox",
                                          ...rowSelection,
                                        }}
                                        dataSource={filteredData}
                                        columns={Columns}
                                        scroll={elScroll}
                                        pagination={{
                                          position: ["bottomRight"],
                                          pageSize: 3,
                                        }}
                                        size="small"
                                      />
                                      <div className="justify-content-end text-end ml-auto">
                                        {filasSeleccionadas.length === 0 ? (
                                          <button
                                            className="boton-resultado  "
                                            type="button"
                                            disabled
                                          >
                                            {t(
                                              "compensacion.general.compensar"
                                            )}
                                          </button>
                                        ) : (
                                          <button
                                            className="boton-resultado "
                                            onClick={compensarUno}
                                            type="button"
                                          >
                                            {t("botones.compensar")}{" "}
                                            {fNum(sumaTcodoseNoCompensadas)} t
                                          </button>
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        className="d-flex justify-content-between"
                                        style={{ marginTop: "0.8em" }}
                                      >
                                        <h3 className="subtitulo-empresas">
                                          {t(
                                            "compensacion.informacion.titulo2"
                                          )}
                                        </h3>
                                        <Input
                                          className="dominio"
                                          placeholder={t("tooltip.nombre")}
                                          onChange={(e) => onSearch(e)}
                                          style={{
                                            width: 200,
                                          }}
                                        />
                                      </div>
                                      <List
                                        itemLayout="horizontal"
                                        dataSource={filteredList}
                                        className="lista-sedes"
                                        pagination={{
                                          size: "small",
                                          pageSize: 2,
                                        }}
                                        size="small"
                                        renderItem={(item) => (
                                          <List.Item
                                            actions={[
                                              <button
                                                className="boton-vehiculos"
                                                onClick={(e) =>
                                                  verProyecto(e, item.id)
                                                }
                                              >
                                                <FaEye
                                                  style={{ fontSize: "1.4em" }}
                                                />
                                              </button>,
                                              <button
                                                className="boton-vehiculos justify-content-end"
                                                onClick={(e) =>
                                                  compensarFinal(e, item.id)
                                                }
                                              >
                                                <FaLeaf />
                                              </button>,
                                            ]}
                                          >
                                            <List.Item.Meta
                                              avatar={
                                                <Avatar src=".././img/sedes.png" />
                                              }
                                              title={item.nombre}
                                              description={
                                                item.tipo +
                                                ", " +
                                                item.potencial +
                                                ", " +
                                                item.precio +
                                                "USD"
                                              }
                                            />
                                          </List.Item>
                                        )}
                                      />

                                      <button
                                        className="boton-resultado btn-atras-compensar"
                                        onClick={atrasCompensar}
                                        type="button"
                                      >
                                        {t("botones.atras")}
                                      </button>

                                      <ModalProyecto
                                        id={modalId}
                                        visible={isModalVisible}
                                        onClose={handleModalClose}
                                      />
                                    </>
                                  )
                                ) : (
                                  <div className="col-md-8 ">
                                    <h5>
                                      {t(
                                        "compensacion.informacion.emisionesSinCompensar"
                                      )}
                                    </h5>
                                  </div>
                                )}
                              </div>{" "}
                            </div>
                          </Card>

                          <div className="col-xl-12 pt-4">
                            <Card bordered={true} hoverable={true}>
                              <h2
                                style={{
                                  fontSize: "1rem",
                                  fontWeight: "600",
                                  color: "#1CA6AF",
                                }}
                              >
                                {t("compensacion.general.compensaciones")}{" "}
                                {year}
                              </h2>
                              {dataCompensaciones.length > 0 ? (
                                // <></>
                                <Table
                                  style={{ marginTop: "2em" }}
                                  rowKey="id"
                                  dataSource={dataCompensaciones}
                                  columns={ColumnsCompensaciones}
                                  scroll={elScroll}
                                  pagination={{
                                    position: ["bottomRight"],
                                    pageSize: 3,
                                  }}
                                  size="small"
                                />
                              ) : (
                                <h5>
                                  {t(
                                    "compensacion.informacion.emisionesSinCompensar"
                                  )}
                                </h5>
                              )}
                            </Card>
                            <BottomTop />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </section>
                  </div>
                </>
              )}
            </div>
          </div>
        ) : (
          <>
            <div className="perfil row pb-3 g-3 justify-content-center ms-4 me-4">
              <img
                alt=""
                className="img-lechuza-triste"
                src="./img/lechuza_triste.png"
              />
            </div>
            <div className="perfil row g-3 justify-content-center ms-4 me-4">
              <h2
                className="d-flex justify-content-center"
                style={{
                  fontSize: "2rem",
                  fontWeight: "1000",
                  color: "#1CA6AF",
                }}
              >
                Usted no ha realizado ninguna medición aún!
              </h2>
              <Link
                to={"/calculadoras"}
                className="d-flex justify-content-center"
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "1000",
                  color: "#1CA6AF",
                }}
              >
                Ir a calcular
              </Link>
            </div>
          </>
        )
      ) : (
        <NotFound404 />
      )}
    </>
  );
};
