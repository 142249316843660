import { useState, useEffect } from "react";
import { Modal, Image } from "antd";
import { traerDatos } from "../funciones_utiles";
import { useTranslation } from "react-i18next";

export const ModalProyecto = ({ id, visible, onClose }) => {
	const [t] = useTranslation("global");

  // console.log(id)
  // console.log(visible)
  // console.log(onClose)
  // const [ModalVisible, setModalVisible] = useState(visible);
  const [modalData, setModalData] = useState(null);
  const [fotosData, setFotosData] = useState(null);
  useEffect(() => {
    if(id)
    {
      (async () => {
        const URI_PROYECTO_UNICO =
          process.env.REACT_APP_DOMINIO +
          process.env.REACT_APP_PUERTO +
          "/datos_proyecto_vista?idp=" +
          id;
        const URI_FOTOS_PROYECTO_UNICO =
          process.env.REACT_APP_DOMINIO +
          process.env.REACT_APP_PUERTO +
          "/fotos_proyecto_vista?idp=" +
          id;

          // console.log(await traerDatos(URI_PROYECTO_UNICO))
    //       console.log(await traerDatos(URI_FOTOS_PROYECTO_UNICO))
    //     // Simulación de una solicitud de datos
        setModalData(await traerDatos(URI_PROYECTO_UNICO));
        setFotosData(await traerDatos(URI_FOTOS_PROYECTO_UNICO));
      })();
    
  }else{
    }
    // console.log(id)
  }, [id]); 



  return (
    <Modal
      title={modalData != null ? modalData[0].nombre : ""}
      open={visible}
      onOk={onClose}
      onCancel={onClose}
      footer={null}
    >
      {modalData ? (
        <div>
          
          <Image.PreviewGroup>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {fotosData ? (
                fotosData.map((option, index) => (
                  <div key={index} style={{ width: "8rem", margin: "10px" }}>
                    <Image
                      style={{ width: "100%", height: "auto" }}
                      src={option.imagen}
                    />
                  </div>
                ))
              ) : (
                <p>{t("compensacion.informacion.cargandoFotos")}</p>
              )}
            </div>
          </Image.PreviewGroup>
          <p>{modalData[0].descripcion} </p>
          <p>{t("compensacion.informacion.ubicacion")} {modalData[0].provincia_estado},<b>{modalData[0].pais}</b></p>
          <p>{t("compensacion.informacion.tipo")}  {modalData[0].tipo}</p>
          <p>{t("compensacion.informacion.potencial")}  {modalData[0].potencial} Tn.</p>
          <p>{t("compensacion.informacion.precio")} {modalData[0].precio} USD</p>
          {modalData[0].certificado == 1 ?
            <p>{t("compensacion.informacion.certificado")} {modalData[0].tipo_certificado}</p>
            :
            <></>
          }

        </div>
      ) : (
        <p>{t("reportes.seccionEstadisticas.cargandoDatos")}</p>
      )}
    </Modal>
  );
};
