import React from "react";
import { Card, Row } from "antd";
import { fNum } from "../funciones_utiles";
import { useTranslation } from "react-i18next";

export const DashboardCard = ({ datoResultado, titulo }) => {
  const [t] = useTranslation("global");

  return (
    <>
      <div className="row justify-content-center pb-3">
        <div className="col-12">
          <Card bordered={true} hoverable={true}>
            <Row>
              <h2
                className="titulos-card-calculadora"
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "1000",
                  color: "#1CA6AF",
              
                }}
              >
                {titulo}
              </h2>
            </Row>
            <Row>
              <Row type="flex" align="start" style={{ width: "100%" }}>
                <h4
                  style={{
                    fontSize: "1rem",
                    fontWeight: "900",
                  }}
                >
                  {fNum(datoResultado)} 
                </h4>
                <h2
                  style={{
                    fontSize: "0.8rem",
                    fontWeight: "900",
                    paddingLeft: "0.4rem"
                  }}
                >
                <span dangerouslySetInnerHTML={{ __html: t('tablas.toneladas') }} />

                </h2>
              </Row>
            </Row>
          </Card>
        </div>
      </div>
    </>
  );
};
