import React from "react";
import { Collapse } from "antd";
import { BtnAtras } from "../navegacion/btn_atras";
import { useTranslation } from "react-i18next";
import { Trans } from 'react-i18next';
const { Panel } = Collapse;

export const CompTermAndCondiciones = () => {
  const onChange = (key) => {
    console.log(key);
  };
  const [t, i18n] = useTranslation("global");
  const currentLanguage = i18n.language;
  return (
    <>
      <BtnAtras titulo={t("terminos.terminos")} />
      <div className="conteiner-fluid p-5">
        <p className="respuesta" style={{ textAlign: "justify" }}>
          <h1 className="subtitulo-empresas">{t("terminos.titulo1")}</h1>
          <span>
            {" "}
            {t("terminos.parrafo1")}
          </span>
          <hr></hr>
          <h1 className="subtitulo-empresas">{t("terminos.titulo2")}</h1>
          <span>
            {" "}
            {t("terminos.parrafo2")}
          </span>
          <hr></hr>
          <h1 className="subtitulo-empresas">{t("terminos.titulo3")}</h1>
          <h3 className="subtitulo-terminos">{t("terminos.subtitulo1")}</h3>
          <span>
          {t("terminos.parrafo3")}
          </span>
          <h3 className="subtitulo-terminos mt-2">
          {t("terminos.subtitulo2")}
          </h3>{" "}
          <span>
            {" "}
            {t("terminos.parrafo4")}
          </span>
          <hr></hr>
          <h1 className="subtitulo-empresas">
          {t("terminos.titulo4")}
          </h1>
          <h3 className="subtitulo-terminos">{t("terminos.subtitulo3")}</h3>{" "}
          <span>
          <span>
          <span dangerouslySetInnerHTML={{ __html: t('terminos.parrafo5') }} />
    </span>
          </span>
          <h3 className="subtitulo-terminos mt-2">{t("terminos.subtitulo4")}</h3>
          <span>
          {t("terminos.parrafo6")}
          </span>
          <h3 className="subtitulo-terminos mt-2">{t("terminos.subtitulo5")}</h3>
          <span>
          {t("terminos.parrafo7")}
          </span>
          <hr></hr>
          <h1 className="subtitulo-empresas"> {t("terminos.titulo5")}</h1>
          <h3 className="subtitulo-terminos">
          {t("terminos.subtitulo6")}
          </h3>{" "}
          <span>
            {" "}
            {t("terminos.parrafo8")}
          </span>
          <h3 className="subtitulo-terminos mt-2">
          {t("terminos.subtitulo7")}
          </h3>
          <span>
            {" "}
            {t("terminos.parrafo9")}
          </span>
          <h3 className="subtitulo-terminos mt-2">
          {t("terminos.subtitulo8")}
          </h3>{" "}
          <span>
          {t("terminos.parrafo10")}
          </span>
          <hr></hr>
          <h1 className="subtitulo-empresas">{t("terminos.titulo6")}</h1>
          <h3 className="subtitulo-terminos">
          {t("terminos.subtitulo9")}
          </h3>{" "}
          <span>
            {" "}
            {t("terminos.parrafo11")}
          </span>
          <h3 className="subtitulo-terminos mt-2">
          {t("terminos.subtitulo10")}
          </h3>
          <span>
            {" "}
            {t("terminos.parrafo12")}
          </span>
          <hr></hr>
          <h1 className="subtitulo-empresas">
          {t("terminos.titulo7")}
          </h1>
          <span>
          {t("terminos.parrafo13")}
          </span>
          <hr></hr>
          <h1 className="subtitulo-empresas">
          {t("terminos.titulo8")}
          </h1>
          <span>
            {" "}
            {t("terminos.parrafo14")}
          </span>
          <hr></hr>
          <h1 className="subtitulo-empresas">{t("terminos.titulo9")}</h1>
          <span>
            {" "}
            {t("terminos.parrafo15")}
          </span>
          <hr></hr>
          <h1 className="subtitulo-empresas">{t("terminos.titulo10")}</h1>
          <span>
            {" "}
            {t("terminos.parrafo16")}
          </span>
        </p>
      </div>
    </>
  );
};
