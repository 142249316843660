import React, { useState, useLayoutEffect, useRef } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

export const MapaVuelos = ({ aeropuertoOrigen, aeropuertoDestino }) => {
  const [origenCoords, setOrigenCoords] = useState(null);
  const [destinoCoords, setDestinoCoords] = useState(null);
  const mapRef = useRef(null);

  // console.log(aeropuertoDestino)
  // console.log(aeropuertoOrigen)

  useLayoutEffect(() => {
    const container = document.getElementById("map");

    const initializeMap = () => {
      if (container && !mapRef.current && !container.hasChildNodes()) {
        const map = L.map("map").setView([0, 0], 1);
        mapRef.current = map;

        const tileLayer = L.tileLayer(
          "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
          {
            attribution: "&copy; OpenStreetMap contributors",
          }
        );
        tileLayer.addTo(map);
        if (origenCoords && destinoCoords) {
          const latLngs = [origenCoords, destinoCoords];
          const polyline = L.polyline(latLngs, { color: "#1CA6AF" });
          polyline.addTo(map);
          map.fitBounds(polyline.getBounds());
        }
      }
    };

    if (container && !mapRef.current && !container.hasChildNodes()) {
      setTimeout(() => {
        initializeMap();
      }, 0);
    } else {
      initializeMap();
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [origenCoords, destinoCoords]);




  useLayoutEffect(() => {
    if (
      aeropuertoOrigen &&
      aeropuertoOrigen.latitud &&
      aeropuertoOrigen.longitud
    ) {
      setOrigenCoords([aeropuertoOrigen.latitud, aeropuertoOrigen.longitud]);
    } else {
      setOrigenCoords(null)
    }
  }, [aeropuertoOrigen]);

  useLayoutEffect(() => {
    if (
      aeropuertoDestino &&
      aeropuertoDestino.latitud &&
      aeropuertoDestino.longitud
    ) {
      setDestinoCoords([aeropuertoDestino.latitud, aeropuertoDestino.longitud]);
    } else {
      setDestinoCoords(null)
    }
  }, [aeropuertoDestino]);

  return <div id="map" style={{ height: "13rem" }}></div>;
};
