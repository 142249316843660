import React,{ createContext,useState, useEffect, useContext } from "react";
import { Routes, Route, useLocation} from "react-router-dom";
import jwt_decode from "jwt-decode";
import { renewSession } from "../funciones_utiles";


const SesionContext = createContext();

export const SesionProvider = ({ children }) => {
  //const [sesion, setSesion] = useState(null); // Estado inicial de la sesión
//   const [algo, setAlgo] = useState(null);
  const token = localStorage.getItem("valid_session");
  let decoded;
  if (token === "undefined" || token === null) {
    window.location.href = "/signin";
    localStorage.removeItem("valid_session");
  } else {
    decoded = jwt_decode(token);
  }
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  // console.log("Esto:",decoded.objetoSession.datosSede)

  const [sesion, setSesion] = useState({
    rol: decoded.objetoSession.rol,
    duenio: decoded.objetoSession.duenio,
    compensador: decoded.objetoSession.compensador,
    infoSubscripcion: decoded.objetoSession.infoSubscripcion,
    datosPlanes: decoded.objetoSession.datosPlanes,
    datosCalculadoras: decoded.objetoSession.datosCalculadoras,
    datosSede: decoded.objetoSession.datosSede,
    maxEmpresas: decoded.objetoSession.maxEmpresas,
    maxSedes: decoded.objetoSession.maxSedes,
    maxUsuarios: decoded.objetoSession.maxUsuarios,
    empresaPrincipal: decoded.objetoSession.ePrincipal,
    empresaActiva: decoded.objetoSession.eActiva,
    sedeActiva: decoded.objetoSession.sActiva,
    qtEmpresas: decoded.objetoSession.qtEmpresas,
    checkPerfil: decoded.objetoSession.check_perfil,
    checkSede: decoded.objetoSession.check_sede,
    checkDias: decoded.objetoSession.check_dias,
  });

  // console.log(decoded.objetoSession);
  //LA EMPRESA Y LA SEDE CON LA QUE ESTOY LABURANDO

  useEffect(() => {
    const loadData = async () => {
  // console.log("ME EJECUTO dos")
      
      await renewSession();
      const token = localStorage.getItem("valid_session");
      
      if (token === "undefined" || token === null) {
        window.location.href = "/signin";
        localStorage.removeItem("valid_session");
      } else {
        const decoded = jwt_decode(token);

        // console.log("Esto:",decoded.objetoSession.datosSede)
        setSesion({
          empresaActiva: decoded.objetoSession.eActiva,
          rol: decoded.objetoSession.rol,
          duenio: decoded.objetoSession.duenio,
          compensador: decoded.objetoSession.compensador,
          infoSubscripcion: decoded.objetoSession.infoSubscripcion,
          datosPlanes: decoded.objetoSession.datosPlanes,
          datosCalculadoras: decoded.objetoSession.datosCalculadoras,
          datosSede: decoded.objetoSession.datosSede,
          sedeActiva: decoded.objetoSession.sActiva,
          checkPerfil: decoded.objetoSession.check_perfil,
          qtEmpresas: decoded.objetoSession.qtEmpresas,
          maxEmpresas: decoded.objetoSession.maxEmpresas,
          maxSedes: decoded.objetoSession.maxSedes,
          maxUsuarios: decoded.objetoSession.maxUsuarios,
          empresaPrincipal: decoded.objetoSession.ePrincipal,
          checkSede: decoded.objetoSession.check_sede,
          checkDias: decoded.objetoSession.check_dias,
        });
      }

      setIsLoading(false);
    };
    loadData();
  }, [location.pathname]);
//   useEffect(() => {
//     setAlgo("Hay Caramba" + location.pathname)
//   }, [location.pathname]);
  return (
    <SesionContext.Provider value={{ sesion }}>
      {children}
    </SesionContext.Provider>
  );
};

export const useSesion = () => useContext(SesionContext);