import { BtnAtrasCalculadoras } from "../navegacion/btn_atras_calculadoras";
import { Tooltip, Tour } from "antd";
import { TablaCompleta } from "../tablas/tabla_simple/tabla_simple";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import moment from "moment";
import withReactContent from "sweetalert2-react-content";
import {
  PlusOutlined,
  RightOutlined,
  LeftOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { InputPrecio } from "../componentes/inputComponente";
import { FaQuestionCircle } from "react-icons/fa";
import {
  traerDatos,
  HeadersAuth,
  CheckExpiracion,
  traerDatosObjeto,
  fNum,
  sacaComas,
  cambioValor,
} from "../funciones_utiles";
import {
  validarNumericoDecimal,
  validarFecha,
  validarSelector,
} from "../validaciones";
import PresetResultado from "./presetResultado";
import { BtnPrincipal } from "../navegacion/BtnPrincipal";
import { useTranslation } from "react-i18next";

const MySwal = withReactContent(Swal);

const URI_TIPO_COMBUSTIBLES =
  process.env.REACT_APP_DOMINIO +
  process.env.REACT_APP_PUERTO +
  "/tipos_combustibles";
const URI_MOTIVOS_VIAJES =
  process.env.REACT_APP_DOMINIO +
  process.env.REACT_APP_PUERTO +
  "/motivos_viajes";
const URI_CLASE_VEHICULOS =
  process.env.REACT_APP_DOMINIO +
  process.env.REACT_APP_PUERTO +
  "/clases_vehiculos";
const URI_VEHICULOS =
  process.env.REACT_APP_DOMINIO + process.env.REACT_APP_PUERTO + "/vehiculos";

export const CompCalculadoraViajes = ({ sesion, titulo }) => {
  const [t, i18n] = useTranslation("global");
  const currentLanguage = i18n.language;
  const [opcionesCombustibles, setOpcionesCombustibles] = useState([]);
  const [opcionesMotivos, setOpcionesMotivos] = useState([]);
  const [opcionesVehiculos, setOpcionesVehiculos] = useState([]);
  const [opcionesClasesVehiculos, setOpcionesClasesVehiculos] = useState([]);
  const [idUnico, setIdUnico] = useState("");
  const [tempDatosCalculoDos, setTempDatosCalculoDos] = useState({});
  const [idEmpresa, setIdEmpresa] = useState(sesion.empresaActiva);
  const [idSede, setIdSede] = useState(sesion.sedeActiva);
  const [pasos, setPasos] = useState([]);

  const refMotivo = useRef(null);
  const refVehiculo = useRef(null);
  const refTamanio = useRef(null);
  const refPasajeros = useRef(null);
  const refCombustible = useRef(null);
  const refCantidad = useRef(null);
  const refMes = useRef(null);
  const refBtnGuardar = useRef(null);
  const refTabla = useRef(null);

  const [abrirAyuda, setAbrirAyuda] = useState(false);

  // const pasos = [

  useEffect(() => {
    setIdEmpresa(sesion.empresaActiva);
    setIdSede(sesion.sedeActiva);
    if (idUnico) {
      (async () => {
        const URI_CONSUMOS_VIAJES_UNICOS =
          process.env.REACT_APP_DOMINIO +
          process.env.REACT_APP_PUERTO +
          "/datos_consumo_unico?type=trip_report&unique_id=" +
          idUnico +
          "&ids=" +
          idSede +
          "&id=" +
          idEmpresa;
        const datosConsumo = await traerDatosObjeto(URI_CONSUMOS_VIAJES_UNICOS);
        // Aplicar formato a los Kwhs consumidos
        datosConsumo.quantity_kilometers = cambioValor(
          parseFloat(datosConsumo.quantity_kilometers).toFixed(2)
        );
        console.log(datosConsumo);
        setTempDatosCalculoDos({
          motivo: datosConsumo.reason,
          clase_vehiculo: datosConsumo.vehicle_class,
          vehiculo: datosConsumo.vehicle,
          tipo_combustible: datosConsumo.fuel_type,
          cantidad_pasajeros: datosConsumo.passengers_quantity,
          cantidad_kilometros: datosConsumo.quantity_kilometers,
          fecha: datosConsumo.date,
        });
      })();
    } else {
      setTempDatosCalculoDos({
        fecha: "",
        tipo_combustible: "0",
        motivo: sesion.rol === 1 ? "11" : "",
        cantidad_kilometros: "",
        cantidad_pasajeros: "0",
        vehiculo: "",
        clase_vehiculo: "",
      });
    }
  }, [
    idUnico,
    idEmpresa,
    idSede,
    sesion.empresaActiva,
    sesion.rol,
    sesion.sedeActiva,
  ]);

  useEffect(() => {
    const propsNext = {
      children: (
        <RightOutlined style={{ color: "white", marginBottom: "20px" }} />
      ),
      style: {
        height: 31,
      },
    };
    const propsFin = {
      children: (
        <CloseOutlined style={{ color: "white", marginBottom: "20px" }} />
      ),
      style: {
        height: 31,
      },
    };
    const propsPrev = {
      children: (
        <LeftOutlined style={{ color: "white", marginBottom: "20px" }} />
      ),
      style: {
        height: 31,
      },
    };
	const objetoTourMes = {
		title: t("calculadoras.pasos.titulo11"),
		description: t("calculadoras.pasos.subtitulo11"),
		target: () => refMes.current,
		prevButtonProps: propsPrev,
		nextButtonProps: propsNext,
	  };
    const objetoTourMotivo = {
      title: t("calculadoras.pasos.titulo7"),
      description: t("calculadoras.pasos.subtitulo7"),
      target: () => refMotivo.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    };
    const objetoTourCombustible = {
      title: t("calculadoras.pasos.titulo1"),
      description: t("calculadoras.pasos.subtitulo1"),
      target: () => refCombustible.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    };
    const objetoTourCantidad = {
      title: t("calculadoras.pasos.titulo2"),
      description: t("calculadoras.pasos.subtitulo2"),
      target: () => refCantidad.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    };
    const objetoTourVehiculo = {
      title: t("calculadoras.pasos.titulo9"),
      description: t("calculadoras.pasos.subtitulo9"),
      target: () => refVehiculo.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    };
    const objetoTourTamanio = {
      title: t("calculadoras.pasos.titulo21"),
      description: t("calculadoras.pasos.subtitulo21"),
      target: () => refTamanio.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    };
    const objetoTourPasajeros = {
      title: t("calculadoras.pasos.titulo22"),
      description: t("calculadoras.pasos.subtitulo22"),
      target: () => refPasajeros.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    };
   
    const objetoTourBtnGuardar = {
      title: t("calculadoras.pasos.titulo4"),
      description: t("calculadoras.pasos.subtitulo4"),
      target: () => refBtnGuardar.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    };
    const objetoTourTabla = {
      title: t("calculadoras.pasos.titulo6"),
      description: t("calculadoras.pasos.subtitulo6"),
      target: () => refTabla.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsFin,
    };
    let pasosPorClaseVehiculo;
    if (sesion.rol === 1) {
      pasosPorClaseVehiculo = {
        moto: [
			          objetoTourMes,
          objetoTourVehiculo,
          objetoTourTamanio,
          objetoTourCantidad,

          objetoTourBtnGuardar,
          objetoTourTabla,
        ],
        bus: [
			objetoTourMes,
          objetoTourVehiculo,
          objetoTourTamanio,
          objetoTourPasajeros,
          objetoTourCantidad,
          objetoTourMes,
          objetoTourBtnGuardar,
          objetoTourTabla,
        ],
        ferry: [
			objetoTourMes,
          objetoTourVehiculo,
          objetoTourTamanio,
          objetoTourPasajeros,
          objetoTourCantidad,
     
          objetoTourBtnGuardar,
          objetoTourTabla,
        ],
        auto: [
			objetoTourMes,
          objetoTourVehiculo,
          objetoTourTamanio,
          objetoTourCombustible,
          objetoTourCantidad,
  
          objetoTourBtnGuardar,
          objetoTourTabla,
        ],
        default: [
			objetoTourMes,
          objetoTourVehiculo,
          objetoTourTamanio,
          objetoTourCantidad,
       
          objetoTourBtnGuardar,
          objetoTourTabla,
        ],
      };
    } else {
      pasosPorClaseVehiculo = {
        moto: [
			objetoTourMes,
          objetoTourMotivo,
          objetoTourVehiculo,
          objetoTourTamanio,
          objetoTourCantidad,
      
          objetoTourBtnGuardar,
          objetoTourTabla,
        ],
        bus: [
			   objetoTourMes,
          objetoTourMotivo,
          objetoTourVehiculo,
          objetoTourTamanio,
          objetoTourPasajeros,
          objetoTourCantidad,
       
          objetoTourBtnGuardar,
          objetoTourTabla,
        ],
        ferry: [
			objetoTourMes,
          objetoTourMotivo,
          objetoTourVehiculo,
          objetoTourTamanio,
          objetoTourPasajeros,
          objetoTourCantidad,
       
          objetoTourBtnGuardar,
          objetoTourTabla,
        ],
        auto: [
			objetoTourMes,
          objetoTourMotivo,
          objetoTourVehiculo,
          objetoTourTamanio,
          objetoTourCombustible,
          objetoTourCantidad,
      
          objetoTourBtnGuardar,
          objetoTourTabla,
        ],
        default: [
			objetoTourMes,
          objetoTourMotivo,
          objetoTourVehiculo,
          objetoTourTamanio,
          objetoTourCantidad,
       
          objetoTourBtnGuardar,
          objetoTourTabla,
        ],
      };
    }

    const pasosAAplicar =
      pasosPorClaseVehiculo[tempDatosCalculoDos.clase_vehiculo] ||
      pasosPorClaseVehiculo.default;
    setPasos(pasosAAplicar);

    const camposPorClaseVehiculo = {
      bus: [
        { name: "fecha", validator: validarFecha },
        { name: "motivo", validator: validarSelector },
        { name: "cantidad_kilometros", validator: validarNumericoDecimal },
        { name: "cantidad_pasajeros", validator: validarSelector },
        { name: "vehiculo", validator: validarSelector },
        { name: "clase_vehiculo", validator: validarSelector },
      ],
      ferry: [
        { name: "fecha", validator: validarFecha },
        { name: "motivo", validator: validarSelector },
        { name: "cantidad_kilometros", validator: validarNumericoDecimal },
        { name: "cantidad_pasajeros", validator: validarSelector },
        { name: "vehiculo", validator: validarSelector },
        { name: "clase_vehiculo", validator: validarSelector },
      ],
      auto: [
        { name: "fecha", validator: validarFecha },
        { name: "tipo_combustible", validator: validarSelector },
        { name: "motivo", validator: validarSelector },
        { name: "cantidad_kilometros", validator: validarNumericoDecimal },
        { name: "vehiculo", validator: validarSelector },
        { name: "clase_vehiculo", validator: validarSelector },
      ],
      default: [
        { name: "fecha", validator: validarFecha },
        { name: "motivo", validator: validarSelector },
        { name: "cantidad_kilometros", validator: validarNumericoDecimal },
        { name: "vehiculo", validator: validarSelector },
        { name: "clase_vehiculo", validator: validarSelector },
      ],
    };

    const camposAAplicar =
      camposPorClaseVehiculo[tempDatosCalculoDos.clase_vehiculo] ||
      camposPorClaseVehiculo.default;

    setTooltipVisible({
      fecha: false,
      motivo: false,
      cantidad_kilometros: false,
      cantidad_pasajeros: false,
      tipo_combustible: false,
      vehiculo: false,
      clase_vehiculo: false,
    });
    setErrores(
      camposAAplicar.reduce((obj, campo) => ({ ...obj, [campo.name]: "" }), {})
    );
    setCampos(camposAAplicar);
  }, [tempDatosCalculoDos.clase_vehiculo, sesion.rol]);

  var [tooltipVisible, setTooltipVisible] = useState({});
  const [errores, setErrores] = useState({});
  const [campos, setCampos] = useState([]);
  // const [fuenteEmision, setFuenteEmision] = useState("");
  const [show, setShow] = useState("");
  const [fetchDatosDos, setFetchDatosDos] = useState([]);

  let URI_NUEVO_CALCULO_KILOMETROS;
  const URI_CONSUMOS_VIAJES_KILOMETROS =
    process.env.REACT_APP_DOMINIO +
    process.env.REACT_APP_PUERTO +
    "/datos_consumo?type=viajes&id=" +
    idEmpresa +
    "&ids=" +
    idSede;
  useEffect(() => {
    (async () => {
      setOpcionesVehiculos(await traerDatos(URI_VEHICULOS));
      setOpcionesMotivos(await traerDatos(URI_MOTIVOS_VIAJES));
      setFetchDatosDos(await traerDatos(URI_CONSUMOS_VIAJES_KILOMETROS));
      setOpcionesCombustibles(await traerDatos(URI_TIPO_COMBUSTIBLES));
      setOpcionesClasesVehiculos(await traerDatos(URI_CLASE_VEHICULOS));
      console.log(await traerDatos(URI_VEHICULOS));
    })();
  }, [show, URI_CONSUMOS_VIAJES_KILOMETROS]);
  const elScroll = { x: 0 };

  const ColumnsDos = [
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}>{t("tablas.fecha")}</span>
        </div>
      ),
      key: "fecha",
      align: "center",
      sorter: (a, b) => a.fecha.localeCompare(b.fecha),
      render: (a) => (
        <div style={{ fontSize: "12px", padding: "2px 4px" }}>
          {moment(`${a.fecha}`, "YYYY-MM-DD").format("DD/MM/YYYY")}
        </div>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}>
            {t("tablas.kilometrosRecorridos")}
          </span>
        </div>
      ),
      key: "km_recorridos",
      align: "right",
      sorter: (a, b) => a.km_recorridos - b.km_recorridos,
      render: (a) => (
        <div style={{ fontSize: "12px", padding: "2px 4px" }}>
          {fNum(a.km_recorridos)}
        </div>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}>{t("tablas.vehiculo")}</span>
        </div>
      ),
      align: "left",
      sorter: (a, b) => a.nombre_vehiculo.localeCompare(b.nombre_vehiculo),
      render: (a) => (
        <div style={{ fontSize: "12px", padding: "2px 4px" }}>
          {currentLanguage === "es" ? a.nombre_vehiculo : a.nombre_vehiculo_en}
        </div>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}>{t("tablas.motivo")}</span>
        </div>
      ),
      align: "left",
      sorter: (a, b) => a.motivo.localeCompare(b.motivo),
      render: (a) => (
        <div style={{ fontSize: "12px", padding: "2px 4px" }}>
          {currentLanguage === "es" ? a.motivo : a.motivo_en}
        </div>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span
            style={{ fontSize: "0.8em" }}
            dangerouslySetInnerHTML={{ __html: t("tablas.toneladas") }}
          />
        </div>
      ),
      key: "kgcodose_emitido",
      align: "right",
      sorter: (a, b) => a.kgcodose_emitido - b.kgcodose_emitido,
      render: (a) => (
        <div style={{ fontSize: "12px", padding: "2px 4px" }}>
          {fNum(a.kgcodose_emitido)}
        </div>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}>+ Info</span>
        </div>
      ),
      key: "id",
      align: "center",
      render: (dato) => (
        <div className="row">
          <div className="col align-items-center">
            {dato.compensado === 1 && (
              <>
                <button
                  className="boton-vehiculos-nuevo justify-content-center"
                  onClick={(e) => borrarDos(e, dato.id)}
                >
                  <DeleteOutlined />
                </button>

                <button
                  className="boton-vehiculos-nuevo justify-content-center"
                  onClick={(e) => traerCalculo(e, dato.id)}
                >
                  <EditOutlined />
                </button>
              </>
            )}
            {dato.compensado === 2 && (
              <span>
                <b> {t("compensacion.estados.pendiente")}</b>
              </span>
            )}
            {dato.compensado === 3 && (
              <span>
                <b>{t("compensacion.estados.compensado")}</b>
              </span>
            )}
          </div>
        </div>
      ),
    },
  ];

  const handleChangeDos = async (e) => {
    const { id, value } = e.target;
    setTempDatosCalculoDos({
      ...tempDatosCalculoDos,
      [id]: value,
    });

    let erroresTemp = { ...errores };
    const validarCampo = async (campo, valor) => {
      const resultadoValidacion = await campo(valor, t);
      return {
        mensaje: resultadoValidacion.mensaje,
        valido: resultadoValidacion.valido,
      };
    };
    const campoActual = campos.find((campo) => campo.name === id);

    if (campoActual) {
      const mira = await validarCampo(campoActual.validator, value);
      erroresTemp[id] = mira.mensaje;
      setTooltipVisible((prevState) => ({
        ...prevState,
        [id]: !mira.valido,
      }));
      setTimeout(() => {
        setTooltipVisible((prevState) => ({
          ...prevState,
          [id]: false,
        }));
      }, 3000);
    }

    setErrores(erroresTemp);
  };

  const guardarDos = async (e) => {
    e.preventDefault();

    let hayErrores = false;
    const erroresTemp = {};
    for (const campo of campos) {
      const valorCampo = String(tempDatosCalculoDos[campo.name]);
      const resultadoValidacion = await campo.validator(valorCampo, t);

      if (resultadoValidacion.valido === false) {
        hayErrores = true;
        erroresTemp[campo.name] = resultadoValidacion.mensaje;
        setTooltipVisible((prevState) => ({
          ...prevState,
          [campo.name]: !resultadoValidacion.valido,
        }));
        setTimeout(() => {
          setTooltipVisible((prevState) => ({
            ...prevState,
            [campo.name]: false,
          }));
        }, 3000);
      }
    }

    if (hayErrores) {
      setErrores(erroresTemp);
      return;
    } else {
      tempDatosCalculoDos.cantidad_kilometros = await sacaComas(
        tempDatosCalculoDos.cantidad_kilometros
      );
      if (idUnico) {
        URI_NUEVO_CALCULO_KILOMETROS =
          process.env.REACT_APP_DOMINIO +
          process.env.REACT_APP_PUERTO +
          `/calculo_viajes_kilometros?id=` +
          idEmpresa +
          "&unique_id=" +
          idUnico +
          "&ids=" +
          idSede;
      } else {
        URI_NUEVO_CALCULO_KILOMETROS =
          process.env.REACT_APP_DOMINIO +
          process.env.REACT_APP_PUERTO +
          "/calculo_viajes_kilometros?id=" +
          idEmpresa +
          "&unique_id=none" +
          "&ids=" +
          idSede;
      }
      const registro = await axios.post(
        URI_NUEVO_CALCULO_KILOMETROS,
        {
          reason: tempDatosCalculoDos.motivo,
          vehicle_class: tempDatosCalculoDos.clase_vehiculo,
          vehicle: tempDatosCalculoDos.vehiculo,
          fuel_type: tempDatosCalculoDos.tipo_combustible,
          passengers_quantity: tempDatosCalculoDos.cantidad_pasajeros,
          quantity_kilometers: tempDatosCalculoDos.cantidad_kilometros,
          date: tempDatosCalculoDos.fecha,
        },
        HeadersAuth
      );
      if (registro.data.errors) {
        const errores = registro.data.errors;
        // console.log(errores)
        const msgError = errores.map((error) => {
          return "<pre>" + (currentLanguage === "es"
            ? error.msg.ES
            : error.msg.EN) + "</pre>";
        });
        MySwal.fire({
          html: `${msgError}`,
          imageUrl: "img/lechuza_triste.png",
          imageHeight: 65,
          confirmButtonText: "Ok",
        });
      } else {
        if (registro.data.code === 409) {
          CheckExpiracion();
        } else {
          if (registro.data.code === 200) {
            MySwal.fire({
              html: (
                <PresetResultado resultado={fNum(registro.data.result)} t={t} />
              ),
              imageUrl: "img/mensajes/resultado.svg",
              imageHeight: 65,
              confirmButtonText: "Ok",
            });

            setTempDatosCalculoDos({
              fecha: "",
              tipo_combustible: "0",
              motivo: sesion.rol === 1 ? "11" : "",
              cantidad_kilometros: "",
              cantidad_pasajeros: "0",
              vehiculo: "",
            });
            if (show === "") {
              setShow("guardado");
            } else {
              setShow("");
            }
            if (idUnico) {
              setIdUnico("");
            }
          } else if (registro.data.code === 408) {
            MySwal.fire({
              html: t("mensajes.mensaje13"),
              imageUrl: "img/lechuza_triste.png",
              imageHeight: 65,
              confirmButtonText: "Ok",
            });
            setTempDatosCalculoDos({
              fecha: "",
              tipo_combustible: "0",
              motivo: sesion.rol === 1 ? "11" : "",
              cantidad_kilometros: "",
              cantidad_pasajeros: "0",
              vehiculo: "",
            });
          }
        }
      }
    }
  };
  const traerCalculo = async (e, value) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    setIdUnico(value);
  };
  const borrarDos = async (e, value) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    //const { value } = e.target;
    // console.log(value)
    const URI_BORRAR_CONSUMO =
      process.env.REACT_APP_DOMINIO +
      process.env.REACT_APP_PUERTO +
      "/borrar_consumo?type=trip_report&id=" +
      idEmpresa +
      "&unique_id=" +
      value +
      "&ids=" +
      idSede;
    const borrar = await axios.delete(URI_BORRAR_CONSUMO, HeadersAuth);
    if (borrar.data.code === 401 || borrar.data.code === 402) {
      MySwal.fire({
        text: t("mensajes.mensaje12"),
        imageUrl: "img/lechuza-triste.png",
        imageHeight: 55,
        confirmButtonText: "Ok",
      });
    } else {
      if (borrar.data.code === 409) {
        CheckExpiracion();
      } else {
        if (borrar.data.code === 200) {
          MySwal.fire({
            text: t("mensajes.mensaje10"),
            imageUrl: "img/borrar.png",
            imageHeight: 50,
            confirmButtonText: "Ok",
          });
          if (show === "") {
            setShow("borrado");
          } else {
            setShow("");
          }
        }
      }
    }
  };
  const handleQuestionIconClick = () => {
    if (!tempDatosCalculoDos.tipo_combustible) {
      // Mostrar Sweet Alert si no se seleccionó una opción
      Swal.fire({
        imageUrl: "img/lechuza-triste.png",
        imageHeight: 65,
        title: "Error",
        text: t("mensajes.mensaje11"),
      });
    } else {
      // Abrir el modal solo si se ha seleccionado una opción
      Swal.fire({
        imageUrl: "img/imagen1.png",
        imageHeight: 65,
        text: "https://www.gov.uk/government/publications/greenhouse-gas-reporting-conversion-factors-2021",
      });
      // setModalVisible(true);
    }
  };

  return (
    <>
      <br></br>
      <div className="perfil row g-3 justify-content-center ms-4 me-4">
        <div className="row row-form px-2">
          <div className="col-12 col-md-4">
            <div className="contenedor-form-viajes">
              <div className="contenedor-titulo w-100">
                <h2 className="titulo-calculadoras">
                  {titulo}
                  <Tooltip title={t("tooltip.ayuda")} placement="top">
                    <FaQuestionCircle
                      style={{
                        marginLeft: "5px",
                        cursor: "pointer",
                        color: "red",
                      }}
                      onClick={() => setAbrirAyuda(true)}
                    />
                  </Tooltip>
                  {idUnico ? (
                    <>
                      <Tooltip title={t("tooltip.nuevoCalculo")}>
                        <PlusOutlined
                          className="btnnuevocalculo p-1 mb-1"
                          onClick={(e) => setIdUnico("")}
                        />
                      </Tooltip>{" "}
                      <br></br>
                    </>
                  ) : (
                    <></>
                  )}
                </h2>

                <form onSubmit={guardarDos}>
                 

                  <div className="row pb-2 mb-3 justify-content-center custom-select-row">
                    <div
                      ref={refMes}
                      className="col-md-6 col-12 d-flex flex-column align-items-center gap-1"
                    >
                      <div className="d-flex align-items-center w-100 justify-content-start">
                        <label
                          className="select_lista text-left"
                          style={{ flex: "1 0 auto" }}
                        >
                          {t("tablas.fecha")}
                        </label>
                      </div>
                      <Tooltip
                        title={!!errores.fecha && <span>{errores.fecha}</span>}
                        open={tooltipVisible.fecha}
                        color="#1CA6AF"
                      >
                        <div className="w-100">
                          <input
                            className="form-control w-100"
                            type="date"
                            min="2010-01-01"
                            max={`${new Date().getFullYear()}-${(
                              "0" +
                              (new Date().getMonth() + 1)
                            ).slice(-2)}-${("0" + new Date().getDate()).slice(
                              -2
                            )}`}
                            id="fecha"
                            value={tempDatosCalculoDos.fecha || ""}
                            onChange={handleChangeDos}
                          />{" "}
                        </div>
                      </Tooltip>
                    </div>
                    {sesion.rol === 1 ? (
                      <></>
                    ) : (
                      <div
                        ref={refMotivo}
                        className="col-md-6 col-12 d-flex flex-column align-items-center gap-1"
                      >
                        <div className="d-flex align-items-center w-100 justify-content-start">
                          <label className="select_lista text-left">
                            {t("tablas.motivoViaje")}
                            <Tooltip
                              title={
                                !!errores.motivo && (
                                  <span>{errores.motivo}</span>
                                )
                              }
                              open={tooltipVisible.motivo}
                              color="#1CA6AF"
                            >
                              <select
                                className="form-select form-select-sm  w-100"
                                id="motivo"
                                onChange={handleChangeDos}
                                value={tempDatosCalculoDos.motivo || ""}
                              >
                                <option value="">
                                  {t("empresa.datosSede.elejirOpcion")}
                                </option>
                                {opcionesMotivos.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {currentLanguage === "es"
                                      ? option.motivo
                                      : option.motivo_en}
                                  </option>
                                ))}
                              </select>
                            </Tooltip>
                          </label>
                        </div>
                      </div>
                    )}
                    <div
                      ref={refVehiculo}
                      className="col-md-6 col-12 d-flex flex-column align-items-center gap-1"
                    >
                      <div className="d-flex align-items-center w-100 justify-content-start">
                        <label
                          className="select_lista text-left"
                          style={{ flex: "1 0 auto" }}
                        >
                          {t("tablas.vehiculo")}
                          <Tooltip
                            title={
                              !!errores.clase_vehiculo && (
                                <span>{errores.clase_vehiculo}</span>
                              )
                            }
                            open={tooltipVisible.clase_vehiculo}
                            color="#1CA6AF"
                          >
                            <select
                              className="form-select form-select-sm w-100"
                              id="clase_vehiculo"
                              onChange={handleChangeDos}
                              value={tempDatosCalculoDos.clase_vehiculo || ""}
                            >
                              <option value="">
                                {t("empresa.datosSede.elejirOpcion")}
                              </option>
                              {opcionesClasesVehiculos.map((option) => (
                                <option key={option.id} value={option.id}>
                                  {currentLanguage === "es"
                                    ? option.clase
                                    : option.clase_en}
                                </option>
                              ))}
                            </select>
                          </Tooltip>
                        </label>
                      </div>
                    </div>
                    <div
                      ref={refTamanio}
                      className="col-md-6 col-12 d-flex flex-column align-items-center gap-1"
                    >
                      <div className="d-flex align-items-center w-100 justify-content-start ">
                        <label className="select_lista text-left"   style={{ flex: "1 0 auto" }}>
                          {t("tablas.tamaño")}
                          <Tooltip
                            title={
                              !!errores.vehiculo && (
                                <span>{errores.vehiculo}</span>
                              )
                            }
                            open={tooltipVisible.vehiculo}
                            color="#1CA6AF"
                          >
                            <select
                              className="form-select form-select-sm w-100"
                              id="vehiculo"
                              onChange={handleChangeDos}
                              value={tempDatosCalculoDos.vehiculo || ""}
                            >
                              <option value="">
                                {t("empresa.datosSede.elejirOpcion")}
                              </option>
                              {opcionesVehiculos.map((option) =>
                                parseInt(tempDatosCalculoDos.clase_vehiculo) ===
                                option.clase ? (
                                  <option key={option.id} value={option.id}>
                                    {currentLanguage === "es"
                                      ? option.vehiculo
                                      : option.vehiculo_en}
                                  </option>
                                ) : (
                                  ""
                                )
                              )}
                            </select>
                          </Tooltip>
                        </label>
                      </div>
                    </div>

                    {parseInt(tempDatosCalculoDos.clase_vehiculo) === 1 ? (
                      <div
                        ref={refCombustible}
                        className="col-md-6 col-12 d-flex flex-column align-items-center gap-1"
                      >
                        <div className="d-flex align-items-center w-100 justify-content-start">
                          <label className="select_lista text-left"  style={{ flex: "1 0 auto" }}>
                            {t("tablas.combustible")}
                            <FaQuestionCircle
                              style={{
                                marginLeft: "5px",
                                cursor: "pointer",
                                color: "#1ca5af",
                              }}
                              onClick={() => handleQuestionIconClick()}
                            />
                          </label>
                        </div>
                        <Tooltip
                          title={
                            !!errores.tipo_combustible && (
                              <span>{errores.tipo_combustible}</span>
                            )
                          }
                          open={tooltipVisible.tipo_combustible}
                          color="#1CA6AF"
                        >
                          <select
                            className="form-select form-select-sm  w-100"
                            id="tipo_combustible"
                            onChange={handleChangeDos}
                            value={tempDatosCalculoDos.tipo_combustible || ""}
                          >
                            <option value="">
                              {t("empresa.datosSede.elejirOpcion")}
                            </option>
                            {opcionesCombustibles.map((option) => (
                              <option key={option.id} value={option.id}>
                                {currentLanguage === "es"
                                  ? option.combustible
                                  : option.combustible_en}
                              </option>
                            ))}
                          </select>
                        </Tooltip>
                      </div>
                    ) : (
                      <></>
                    )}
                    {parseInt(tempDatosCalculoDos.clase_vehiculo) === 3 ||
                    parseInt(tempDatosCalculoDos.clase_vehiculo) === 4 ? (
                      <div
                        ref={refPasajeros}
                        className="col-md-6 col-12 d-flex flex-column align-items-center gap-1"
                      >
                        <div className="d-flex align-items-center w-100 justify-content-start">
                          <label className="select_lista text-left"  style={{ flex: "1 0 auto" }}>
                            {t("tablas.pasajeros")}
                            <Tooltip
                              title={
                                !!errores.cantidad_pasajeros && (
                                  <span>{errores.cantidad_pasajeros}</span>
                                )
                              }
                              open={tooltipVisible.cantidad_pasajeros}
                              color="#1CA6AF"
                            >
                              <select
                                className="form-select form-select-sm  w-100"
                                id="cantidad_pasajeros"
                                onChange={handleChangeDos}
                                value={
                                  tempDatosCalculoDos.cantidad_pasajeros || ""
                                }
                              >
                                <option value="">
                                  {t("empresa.datosSede.elejirOpcion")}
                                </option>
                                <option key={1} value={1}>
                                  1
                                </option>
                                <option key={2} value={2}>
                                  2
                                </option>
                                <option key={3} value={3}>
                                  3
                                </option>
                                <option key={4} value={4}>
                                  4
                                </option>
                                <option key={5} value={5}>
                                  5
                                </option>
                                <option key={6} value={6}>
                                  6
                                </option>
                                <option key={7} value={7}>
                                  7
                                </option>
                                <option key={8} value={8}>
                                  8
                                </option>
                                <option key={9} value={9}>
                                  9
                                </option>
                                <option key={10} value={10}>
                                  10
                                </option>
                              </select>
                            </Tooltip>
                          </label>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    <div
                      ref={refCantidad}
                      className="col-md-6 col-12 d-flex flex-column align-items-center gap-1"
                    >
                      <div className="d-flex align-items-center w-100 justify-content-start">
                        <label className="select_lista text-left">
                          {t("tablas.kilometrosRecorridos")}
                        </label>
                      </div>
                      <Tooltip
                        title={
                          !!errores.cantidad_kilometros && (
                            <span>{errores.cantidad_kilometros}</span>
                          )
                        }
                        open={tooltipVisible.cantidad_kilometros}
                        color="#1CA6AF"
                      >
                        <div className="w-100">
                          <InputPrecio
                            className="form-control"
                            idCampo={"cantidad_kilometros"}
                            elValue={tempDatosCalculoDos.cantidad_kilometros}
                            elPlaceholder={"Kilometros recorridos"}
                            elSufijo={"km"}
                            onChange={handleChangeDos}
                          />
                        </div>
                      </Tooltip>
                    </div>

                    <div
                      ref={refBtnGuardar}
                      className="col-md-6 col-12 d-flex flex-column align-items-center gap-1"
                    >
                      <div className="d-flex align-items-center w-100 justify-content-end mt-4">
                        <BtnPrincipal name={t("botones.calcular")} />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-8">
            <div className="contenedor-tabla-viajes">
              <div ref={refTabla}>
                <TablaCompleta
                  fetchDatos={fetchDatosDos}
                  Columns={ColumnsDos}
                  elScroll={elScroll}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Tour
        open={abrirAyuda}
        onClose={() => setAbrirAyuda(false)}
        steps={pasos}
      />
    </>
  );
};
