import { BtnAtrasCalculadoras } from "../navegacion/btn_atras_calculadoras";
import {
  PlusOutlined,
  RightOutlined,
  LeftOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { TablaCompleta } from "../tablas/tabla_simple/tabla_simple";
import { ChartEmisionesMulti } from "../charts/ChartEmisionesMulti";
import { InputPrecio } from "../componentes/inputComponente";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import moment from "moment";
import withReactContent from "sweetalert2-react-content";
import { FaQuestionCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import {
  traerDatos,
  HeadersAuth,
  CheckExpiracion,
  traerDatosObjeto,
  fNum,
  cambioValor,
  sacaComas,
} from "../funciones_utiles";
import {
  validarNumericoDecimal,
  validarFechaPeriodo,
  validarSelector,
} from "../validaciones";
import PresetResultado from "./presetResultado";
import { BtnPrincipal } from "../navegacion/BtnPrincipal";
import { Tooltip, Tour } from "antd";

const MySwal = withReactContent(Swal);

const MemoizedChartEmisionesMulti = React.memo(ChartEmisionesMulti);
//DESCARGAR MOMENT JS
export const CompCalculadoraCombustion = ({ sesion, titulo }) => {
  const [t, i18n] = useTranslation("global");
  const currentLanguage = i18n.language;
  const URI_COMBUSTIBLES =
    process.env.REACT_APP_DOMINIO +
    process.env.REACT_APP_PUERTO +
    "/combustibles";
  const URI_UNIDAD_MEDIDA =
    process.env.REACT_APP_DOMINIO +
    process.env.REACT_APP_PUERTO +
    "/unidad_medida";
  // console.log(empresasUsuario)
  // console.log(empresasUsuario.some(checkEmpresa))
  const refMes = useRef(null);
  const refCombustible = useRef(null);
  const refUnidadMedida = useRef(null);
  const refCantidad = useRef(null);

  const refBtnGuardar = useRef(null);
  const refGrafico = useRef(null);
  const refTabla = useRef(null);

  const [abrirAyuda, setAbrirAyuda] = useState(false);

  const propsNext = {
    children: (
      <RightOutlined style={{ color: "white", marginBottom: "20px" }} />
    ),
    style: {
      height: 31,
    },
  };
  const propsFin = {
    children: (
      <CloseOutlined style={{ color: "white", marginBottom: "20px" }} />
    ),
    style: {
      height: 31,
    },
  };
  const propsPrev = {
    children: <LeftOutlined style={{ color: "white", marginBottom: "20px" }} />,
    style: {
      height: 31,
    },
  };
  const pasos = [
    {
      title: t("calculadoras.pasos.titulo3"),
      description: t("calculadoras.pasos.subtitulo3"),
      target: () => refMes.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    },
    {
      title: t("calculadoras.pasos.titulo1"),
      description: t("calculadoras.pasos.subtitulo1"),
      target: () => refCombustible.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    },
    {
      title: t("calculadoras.pasos.titulo26"),
      description: t("calculadoras.pasos.subtitulo26"),
      target: () => refUnidadMedida.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    },
    {
      title: t("calculadoras.pasos.titulo2"),
      description: t("calculadoras.pasos.subtitulo2"),
      target: () => refCantidad.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    },
    {
      title: t("calculadoras.pasos.titulo4"),
      description: t("calculadoras.pasos.subtitulo4"),
      target: () => refBtnGuardar.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    },
    {
      title: t("calculadoras.pasos.titulo5"),
      description: t("calculadoras.pasos.subtitulo5"),
      target: () => refGrafico.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    },
    {
      title: t("calculadoras.pasos.titulo6"),
      description: t("calculadoras.pasos.subtitulo6"),
      target: () => refTabla.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsFin,
    },
  ];

  const anioActual = new Date().getFullYear();
  const [yearRange, setYearRange] = useState(anioActual);
  const [sufijo, setSufijo] = useState("S/N");

  const [datosChart, setDatosChart] = useState([]);
  const [datosTable, setDatosTable] = useState([]);

  const [opcionesCombustibles, setOpcionesCombustibles] = useState([]);
  const [opcionesUnidadMedida, setOpcionesUnidadMedida] = useState([]);

  const [idUnico, setIdUnico] = useState("");
  const [tempDatosCalculo, setTempDatosCalculo] = useState({});
  const [idEmpresa, setIdEmpresa] = useState(sesion.empresaActiva);
  const [idSede, setIdSede] = useState(sesion.sedeActiva);
  const [fuenteEmision, setFuenteEmision] = useState("");

  useEffect(() => {
    setIdEmpresa(sesion.empresaActiva);
    setIdSede(sesion.sedeActiva);
    if (idUnico) {
      (async () => {
        const URI_CONSUMOS_COMBUSTION_UNICOS =
          process.env.REACT_APP_DOMINIO +
          process.env.REACT_APP_PUERTO +
          "/datos_consumo_unico?type=combustion_report&unique_id=" +
          idUnico +
          "&ids=" +
          idSede +
          "&id=" +
          idEmpresa;
        const datosConsumo = await traerDatosObjeto(
          URI_CONSUMOS_COMBUSTION_UNICOS
        );
        // Aplicar formato a los Kwhs consumidos
        datosConsumo.fuel_quantity = cambioValor(
          parseFloat(datosConsumo.fuel_quantity).toFixed(2)
        );

        setTempDatosCalculo(datosConsumo);
      })();
    } else {
      setTempDatosCalculo({
        fuel: "",
        unit_of_measurament: "",
        fuel_quantity: "",
        month_year: "",
      });
    }
  }, [idUnico, idSede, idEmpresa, sesion.empresaActiva, sesion.sedeActiva]);

  const [show, setShow] = useState("");
  const [fetchDatos, setFetchDatos] = useState([]);

  var [tooltipVisible, setTooltipVisible] = useState({
    fuel: false,
    unit_of_measurament: false,
    fuel_quantity: false,
    month_year: false,
  });
  const [errores, setErrores] = useState({
    fuel: "",
    unit_of_measurament: "",
    fuel_quantity: "",
    month_year: "",
  });
  const campos = [
    { name: "month_year", validator: validarFechaPeriodo },
    { name: "fuel", validator: validarSelector },
    { name: "unit_of_measurament", validator: validarSelector },
    { name: "fuel_quantity", validator: validarNumericoDecimal },
  ];
  useEffect(() => {
    const actualizarChart = async () => {
      const nuevosDatos = fetchDatos.filter(
        (d) =>
          moment(d.fecha, "YYYY-MM").year() >= yearRange &&
          moment(d.fecha, "YYYY-MM").year() <= yearRange
      );

      setDatosChart(nuevosDatos);
      setDatosTable(nuevosDatos);
    };
    actualizarChart();
  }, [yearRange, fetchDatos, show]);

  useEffect(() => {
    (async () => {
      setOpcionesUnidadMedida(
        await traerDatos(URI_UNIDAD_MEDIDA + "?id=" + tempDatosCalculo.fuel)
      );
    })();
    const fuenteElegida = opcionesCombustibles.find(
      (item) => parseInt(item.id) === parseInt(tempDatosCalculo.fuel)
    );
    setFuenteEmision(fuenteElegida ? fuenteElegida.fuente : "");
  }, [tempDatosCalculo.fuel]);
  useEffect(() => {
    const sufijoElegido = opcionesUnidadMedida.find(
      (item) =>
        parseInt(item.id) === parseInt(tempDatosCalculo.unit_of_measurament)
    );
    sufijoElegido ? setSufijo(sufijoElegido.unidad_medida) : setSufijo("S/N");
  }, [tempDatosCalculo.unit_of_measurament, opcionesUnidadMedida]);

  useEffect(() => {
    (async () => {
      setOpcionesCombustibles(await traerDatos(URI_COMBUSTIBLES));
      setFetchDatos(await traerDatos(URI_CONSUMOS_ENERGIA));
    })();
  }, [show, URI_COMBUSTIBLES]);
  let URI_NUEVO_CALCULO;
  const URI_CONSUMOS_ENERGIA =
    process.env.REACT_APP_DOMINIO +
    process.env.REACT_APP_PUERTO +
    "/datos_consumo?type=combustion&id=" +
    idEmpresa +
    "&ids=" +
    idSede;
  const elScroll = { x: 0 };
  const Columns = [
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}>{t("tablas.periodo")}</span>
        </div>
      ),
      key: "fecha",
      align: "center",
      width: "80px",
      sorter: (a, b) => a.fecha.localeCompare(b.fecha),
      render: (a) => moment(`${a.fecha}`, "YYYY-MM").format("MM/YYYY"),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}>{t("tablas.cantidad")}</span>
        </div>
      ),
      align: "right",
      width: "80px",
      sorter: (a, b) => a.combustible_consumido - b.combustible_consumido,
      render: (a) => (
        <>
          {fNum(a.combustible_consumido)} {a.sufijo}{" "}
        </>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}>{t("tablas.combustible")}</span>
        </div>
      ),
      align: "left",
      width: "50px",
      sorter: (a, b) =>
        -a.nombre_combustible.localeCompare(b.nombre_combustible),
      render: (a) =>
        currentLanguage === "es"
          ? a.nombre_combustible
          : a.nombre_combustible_en,
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span
            style={{ fontSize: "0.8em" }}
            dangerouslySetInnerHTML={{ __html: t("tablas.toneladasPorUnidad") }}
          />
        </div>
      ),
      key: "kgcodose_emitido",
      align: "right",
      width: "120px",
      sorter: (a, b) => a.kgcodose_emitido - b.kgcodose_emitido,
      render: (a) => fNum(a.kgcodose_emitido),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}>+ Info</span>
        </div>
      ),
      key: "id",
      align: "center",
      width: "100px",
      render: (dato) => (
        <div className="row row-icons">
          <div className="col align-items-center">
            {dato.compensado === 1 && (
              <>
                <button
                  className="boton-vehiculos-nuevo justify-content-center"
                  onClick={(e) => borrar(e, dato.id)}
                >
                  <DeleteOutlined />
                </button>

                <button
                  className="boton-vehiculos-nuevo justify-content-center"
                  onClick={(e) => traerCalculo(e, dato.id)}
                >
                  <EditOutlined />
                </button>
              </>
            )}
            {dato.compensado === 2 && (
              <span>
                <b> {t("compensacion.estados.pendiente")}</b>
              </span>
            )}
            {dato.compensado === 3 && (
              <span>
                <b>{t("compensacion.estados.compensado")}</b>
              </span>
            )}
          </div>
        </div>
      ),
    },
  ];
  // Asegúrate de definir esta función según tus necesidades específicas
  const handleChangeModal = (e, index) => {
    const { value } = e.target;
    // Realiza las operaciones necesarias con los cambios en el select del modal
    console.log(`Select en la fila ${index + 1} cambió a ${value}`);
  };

  const handleQuestionIconClick = () => {
    if (!tempDatosCalculo.fuel) {
      // Mostrar Sweet Alert si no se seleccionó una opción
      Swal.fire({
        imageUrl: "img/lechuza-triste.png",
        imageHeight: 65,
        title: "Error",
        text: t("mensajes.mensaje11"),
      });
    } else {
      // Abrir el modal solo si se seleccionó una opción
      Swal.fire({
        imageUrl: "img/imagen1.png",
        imageHeight: 65,
        text: fuenteEmision,
      });
    }
  };

  const handleChange = async (e) => {
    const { id, value } = e.target;
    // console.log("ES : " + value);

    setTempDatosCalculo({
      ...tempDatosCalculo,
      [id]: value,
    });

    let erroresTemp = { ...errores };
    const validarCampo = async (campo, valor) => {
      const resultadoValidacion = await campo(valor, t);
      return {
        mensaje: resultadoValidacion.mensaje,
        valido: resultadoValidacion.valido,
      };
    };
    const campoActual = campos.find((campo) => campo.name === id);

    if (campoActual) {
      const mira = await validarCampo(campoActual.validator, value);
      erroresTemp[id] = mira.mensaje;
      setTooltipVisible((prevState) => ({
        ...prevState,
        [id]: !mira.valido,
      }));
      setTimeout(() => {
        setTooltipVisible((prevState) => ({
          ...prevState,
          [id]: false,
        }));
      }, 3000);
    }

    setErrores(erroresTemp);
  };
  const handleChangeAnio = (e) => {
    const { value } = e.target;
    // console.log(value)
    setYearRange(value);
  };

  const guardar = async (e) => {
    e.preventDefault();
    let hayErrores = false;
    const erroresTemp = {};
    for (const campo of campos) {
      const valorCampo = String(tempDatosCalculo[campo.name]);
      const resultadoValidacion = await campo.validator(valorCampo, t);

      if (resultadoValidacion.valido === false) {
        hayErrores = true;
        erroresTemp[campo.name] = resultadoValidacion.mensaje;
        setTooltipVisible((prevState) => ({
          ...prevState,
          [campo.name]: !resultadoValidacion.valido,
        }));
        setTimeout(() => {
          setTooltipVisible((prevState) => ({
            ...prevState,
            [campo.name]: false,
          }));
        }, 3000);
      }
    }
    if (hayErrores) {
      setErrores(erroresTemp);
      return;
    } else {
      tempDatosCalculo.fuel_quantity = await sacaComas(
        tempDatosCalculo.fuel_quantity
      );

      if (idUnico) {
        URI_NUEVO_CALCULO =
          process.env.REACT_APP_DOMINIO +
          process.env.REACT_APP_PUERTO +
          "/calculo_combustion?id=" +
          idEmpresa +
          "&ids=" +
          idSede +
          "&unique_id=" +
          idUnico;
      } else {
        URI_NUEVO_CALCULO =
          process.env.REACT_APP_DOMINIO +
          process.env.REACT_APP_PUERTO +
          "/calculo_combustion?id=" +
          idEmpresa +
          "&ids=" +
          idSede +
          "&unique_id=none";
      }

      const registro = await axios.post(
        URI_NUEVO_CALCULO,
        tempDatosCalculo,
        HeadersAuth
      );

      if (registro.data.errors) {
        const errores = registro.data.errors;
        // console.log(errores)
        const msgError = errores.map((error) => {
          return (
            "<pre>" +
            (currentLanguage === "es" ? error.msg.ES : error.msg.EN) +
            "</pre>"
          );
        });
        MySwal.fire({
          html: `${msgError}`,
          imageUrl: "img/lechuza_triste.png",
          imageHeight: 65,
          confirmButtonText: "Ok",
        });
      } else {
        if (registro.data.code === 409) {
          CheckExpiracion();
        } else {
          if (registro.data.code === 200) {
            MySwal.fire({
              html: (
                <PresetResultado resultado={fNum(registro.data.result)} t={t} />
              ),
              imageUrl: "img/mensajes/resultado.svg",
              imageHeight: 65,
              confirmButtonText: "Ok",
            });
            setTempDatosCalculo({
              fuel: "",
              unit_of_measurament: "",
              fuel_quantity: "",
              month_year: "",
            });
            if (show === "") {
              setShow("guardado");
            } else {
              setShow("");
            }
            setIdUnico("");
          }
        }
      }
    }
  };
  const traerCalculo = async (e, value) => {
    e.preventDefault();
    window.scrollTo(0, 0);

    setIdUnico(value);
  };
  const borrar = async (e, value) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    // const { value } = e.target;
    // console.log(value)
    const URI_BORRAR_CONSUMO =
      process.env.REACT_APP_DOMINIO +
      process.env.REACT_APP_PUERTO +
      "/borrar_consumo?type=combustion_report&id=" +
      idEmpresa +
      "&ids=" +
      idSede +
      "&unique_id=" +
      value;
    const borrar = await axios.delete(URI_BORRAR_CONSUMO, HeadersAuth);
    if (borrar.data.code === 401 || borrar.data.code === 402) {
      MySwal.fire({
        text: t("mensajes.mensaje12"),
        imageUrl: "img/lechuza-triste.png",
        imageHeight: 55,
        confirmButtonText: "Ok",
      });
    } else {
      if (borrar.data.code === 409) {
        CheckExpiracion();
      } else {
        if (borrar.data.code === 200) {
          MySwal.fire({
            text: t("mensajes.mensaje10"),
            imageUrl: "img/borrar.png",
            imageHeight: 50,
            confirmButtonText: "Ok",
          });
          if (show === "") {
            setShow("borrado");
          } else {
            setShow("");
          }
          setIdUnico("");
        }
      }
    }
  };

  return (
    <>
      <br></br>
      <div className="perfil row g-3 justify-content-center ms-4 me-4">
        <div className="row row-form px-2">
          <div className="col-12 col-md-5">
            <div className="contenedor-form-combustion">
              <div className="contenedor-titulo w-100">
                <h2 className="titulo-calculadoras ">
                  {titulo}

                  <Tooltip title={t("tooltip.ayuda")} placement="top">
                    <FaQuestionCircle
                      style={{
                        marginLeft: "5px",
                        cursor: "pointer",
                        color: "red",
                      }}
                      onClick={() => setAbrirAyuda(true)}
                    />
                  </Tooltip>
                  {idUnico ? (
                  <>
                    <Tooltip title={t("tooltip.nuevoCalculo")}>
                      <PlusOutlined
                        className="btnnuevocalculo p-1 mb-1"
                        onClick={(e) => setIdUnico("")}
                      />
                    </Tooltip>{" "}
                  </>
                ) : (
                  <></>
                )}
                </h2>
              </div>
              <form onSubmit={guardar}>
               

                <div className="row pb-2 mb-3 justify-content-center custom-select-row">
                  <div
                    ref={refMes}
                    className="col-md-6 col-12 d-flex flex-column align-items-center"
                  >
                    <div className="d-flex align-items-center w-100 justify-content-start">
                      <label
                        className="select_lista text-left"
                        style={{ flex: "1 0 auto" }}
                      >
                        {t("tablas.mes")}
                      </label>
                    </div>
                    <Tooltip
                      title={
                        !!errores.month_year && (
                          <span>{errores.month_year}</span>
                        )
                      }
                      open={tooltipVisible.month_year}
                      color="#1CA6AF"
                    >
                      <div className="w-100">
                        <input
                          className="form-control w-100"
                          type="month"
                          min="2010-01"
                          max={`${new Date().getFullYear()}-${(
                            "0" +
                            (new Date().getMonth() + 1)
                          ).slice(-2)}`}
                          id="month_year"
                          value={tempDatosCalculo.month_year || ""}
                          onChange={handleChange}
                        />
                      </div>
                    </Tooltip>
                  </div>
                  <div
                    ref={refCombustible}
                    className="col-md-6 col-12 d-flex flex-column align-items-center"
                  >
                    <div className="d-flex align-items-center w-100 justify-content-start">
                      <label className="select_lista text-left">
                        {t("tablas.combustible")}
                        <Tooltip
                          title={t("tooltip.fuenteEmision")}
                          placement="top"
                        >
                          <FaQuestionCircle
                            style={{
                              marginLeft: "5px",
                              cursor: "pointer",
                              color: "#1ca5af",
                            }}
                            onClick={() =>
                              handleQuestionIconClick(fuenteEmision)
                            }
                          />
                        </Tooltip>
                      </label>
                    </div>
                    <Tooltip
                      title={!!errores.fuel && <span>{errores.fuel}</span>}
                      open={tooltipVisible.fuel}
                      color="#1CA6AF"
                    >
                      <select
                        className="form-select form-select-sm w-100"
                        id="fuel"
                        onChange={handleChange}
                        value={tempDatosCalculo.fuel || ""}
                      >
                        <option key="" value="">
                          {t("empresa.datosSede.elejirOpcion")}
                        </option>
                        {opcionesCombustibles
                          .filter((option) => option.apto_fija === 1)
                          .map((option) => (
                            <option key={option.id} value={option.id}>
                              {currentLanguage === "es"
                                ? option.combustible
                                : option.combustible_en}
                            </option>
                          ))}
                      </select>
                    </Tooltip>
                  </div>
                  <div
                    ref={refUnidadMedida}
                    className="col-md-6 col-12 d-flex flex-column align-items-center"
                  >
                    <div className="d-flex align-items-center w-100 justify-content-start">
                      <label
                        className="select_lista text-left"
                        style={{ flex: "1 0 auto" }}
                      >
                        {t("tablas.unidadMedida")}
                      </label>
                    </div>
                    <Tooltip
                      title={
                        !!errores.unit_of_measurament && (
                          <span>{errores.unit_of_measurament}</span>
                        )
                      }
                      open={tooltipVisible.unit_of_measurament}
                      color="#1CA6AF"
                    >
                      <select
                        className="form-select form-select-sm w-100"
                        id="unit_of_measurament"
                        onChange={handleChange}
                        value={tempDatosCalculo.unit_of_measurament || ""}
                      >
                        <option key="" value="">
                          {t("empresa.datosSede.elejirOpcion")}
                        </option>
                        {opcionesUnidadMedida.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.unidad_medida}
                          </option>
                        ))}
                      </select>
                    </Tooltip>
                  </div>

                  <div
                    ref={refCantidad}
                    className="col-md-6 col-12 d-flex flex-column align-items-center"
                  >
                    <div className="d-flex align-items-center w-100 justify-content-start">
                      <label
                        className="select_lista text-left"
                        style={{ flex: "1 0 auto" }}
                      >
                        {t("tablas.cantidad")}
                      </label>
                    </div>
                    <Tooltip
                      title={
                        !!errores.fuel_quantity && (
                          <span>{errores.fuel_quantity}</span>
                        )
                      }
                      open={tooltipVisible.fuel_quantity}
                      color="#1CA6AF"
                    >
                      <div className="w-100">
                        <InputPrecio
                          className="form-control"
                          idCampo={"fuel_quantity"}
                          elValue={tempDatosCalculo.fuel_quantity || ""}
                          elPlaceholder={"Combustible Consumido"}
                          elSufijo={sufijo}
                          onChange={handleChange}
                        />
                      </div>
                    </Tooltip>
                  </div>

                  <div
                    ref={refBtnGuardar}
                    className="col-md-6 col-12 mt-4 d-flex justify-content-end w-100"
                  >
                    <BtnPrincipal name={t("botones.calcular")} />
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="col-12 col-md-7">
            <div className="contenedor-tabla px-4">
              <div ref={refGrafico}>
                <select
                  className="form-select form-select-sm w-100"
                  id="anio_graph"
                  onChange={handleChangeAnio}
                  value={yearRange || ""}
                >
                  <option value="">{t("planes.año2")}</option>
                  {[
                    ...new Set(
                      fetchDatos.map((item) => item.fecha.substring(0, 4))
                    ),
                  ].map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <div className="mt-2">
                  <MemoizedChartEmisionesMulti
                    data={datosChart}
                    year={yearRange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="contenedor-tabla-combustion px-4">
            <div ref={refTabla}>
              <h2 className="texto-explicacion">{t("tablas.info")}</h2>
              <TablaCompleta
                fetchDatos={datosTable}
                Columns={Columns}
                elScroll={elScroll}
        
              />
            </div>
          </div>
        </div>
      </div>{" "}
      <Tour
        open={abrirAyuda}
        onClose={() => setAbrirAyuda(false)}
        steps={pasos}
      />
    </>
  );
};
