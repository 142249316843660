import { useState, useMemo, useEffect } from "react";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Tooltip } from "antd";
import {
  traerDatos,
  HeadersAuth,
  traerDatosObjeto,
  CheckExpiracion,
  renewSession,
} from "../funciones_utiles";
import {
  validarTelefono,
  validarTextoNumerico,
  validarSoloTexto,
  validarCPostal,
  validarSelector,
  validarNumericoID,
  validarEmail,
} from "../validaciones";

import axios from "axios";
import { BtnPrincipal } from "../navegacion/BtnPrincipal";
import { BtnAtras } from "../navegacion/btn_atras";
import { useTranslation } from "react-i18next";
const MySwal = withReactContent(Swal);
const URI_PAISES =
  process.env.REACT_APP_DOMINIO + process.env.REACT_APP_PUERTO + "/paises";
const URI_PROVINCIAS =
  process.env.REACT_APP_DOMINIO + process.env.REACT_APP_PUERTO + "/provincias";
const URI_DATOS_PERFIL =
  process.env.REACT_APP_DOMINIO +
  process.env.REACT_APP_PUERTO +
  "/datos_perfil";
const URI_GUARDAR_PERFIL =
  process.env.REACT_APP_DOMINIO +
  process.env.REACT_APP_PUERTO +
  "/update_register";

export const CompPerfilUsuario = () => {
  const [t, i18n] = useTranslation("global");
  const currentLanguage = i18n.language;
  const [opcionesPaises, setOpcionesPaises] = useState([]);
  const [opcionesProvincias, setOpcionesProvincias] = useState([]);
  // const [datosPerfil, setDatosPerfil] = useState({});
  const [tempDatosPerfil, setTempDatosPerfil] = useState({});
  const navigate = useNavigate();
  var [tooltipVisible, setTooltipVisible] = useState({
    nombre: false,
    numero_identificacion: false,
    pais: false,
    ciudad: false,
    telefono: false,
    apellido: false,
    email: false,
    provincia_estado: false,
    domicilio: false,
    codigo_postal: false,
  });
  const [errores, setErrores] = useState({
    nombre: "",
    numero_identificacion: "",
    pais: "",
    ciudad: "",
    telefono: "",
    apellido: "",
    email: "",
    provincia_estado: "",
    domicilio: "",
    codigo_postal: "",
  });
  const campos = [
    { name: "nombre", validator: validarSoloTexto },
    { name: "numero_identificacion", validator: validarNumericoID },
    { name: "pais", validator: validarSelector },
    { name: "ciudad", validator: validarTextoNumerico },
    { name: "telefono", validator: validarTelefono },
    { name: "apellido", validator: validarSoloTexto },
    { name: "email", validator: validarEmail },
    { name: "provincia_estado", validator: validarSelector },
    { name: "domicilio", validator: validarTextoNumerico },
    { name: "codigo_postal", validator: validarCPostal },
  ];
  useMemo(() => {
    (async () => {
      setOpcionesPaises(await traerDatos(URI_PAISES));
    })();
  }, []);
  useEffect(() => {
    (async () => {
      setTempDatosPerfil(await traerDatosObjeto(URI_DATOS_PERFIL));
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setOpcionesProvincias(
        await traerDatos(URI_PROVINCIAS + "?id=" + tempDatosPerfil.pais)
      );
    })();
  }, [tempDatosPerfil.pais]);

  const handleChange = async (e) => {
    const { id, value } = e.target;

    if (id === "pais") {
      setTempDatosPerfil({
        ...tempDatosPerfil,
        [id]: value,
        provincia_estado: "",
      });
    } else {
      setTempDatosPerfil({
        ...tempDatosPerfil,
        [id]: value,
      });
    }
    console.log(tempDatosPerfil);
    let erroresTemp = { ...errores };
    const validarCampo = async (campo, valor) => {
      const resultadoValidacion = await campo(valor, t);
      return {
        mensaje: resultadoValidacion.mensaje,
        valido: resultadoValidacion.valido,
      };
    };

    const campoActual = campos.find((campo) => campo.name === id);

    if (campoActual) {
      const mira = await validarCampo(campoActual.validator, value);
      erroresTemp[id] = mira.mensaje;
      setTooltipVisible((prevState) => ({
        ...prevState,
        [id]: !mira.valido,
      }));
      setTimeout(() => {
        setTooltipVisible((prevState) => ({
          ...prevState,
          [id]: false,
        }));
      }, 3000);
    }

    setErrores(erroresTemp);
  };
  const guardar = async (e) => {
    e.preventDefault();

    let hayErrores = false;
    const erroresTemp = {};
    for (const campo of campos) {
      const valorCampo = String(tempDatosPerfil[campo.name]);
      const resultadoValidacion = await campo.validator(valorCampo, t);

      if (resultadoValidacion.valido === false) {
        hayErrores = true;
        erroresTemp[campo.name] = resultadoValidacion.mensaje;
        setTooltipVisible((prevState) => ({
          ...prevState,
          [campo.name]: !resultadoValidacion.valido,
        }));
        setTimeout(() => {
          setTooltipVisible((prevState) => ({
            ...prevState,
            [campo.name]: false,
          }));
        }, 3000);
      }
    }
    if (hayErrores) {
      setErrores(erroresTemp);
      return;
    } else {
      const registro = await axios.post(
        URI_GUARDAR_PERFIL,
        tempDatosPerfil,
        HeadersAuth
      );
      if (registro.data.errors) {
        const errores = registro.data.errors;
        
        const msgError = errores.map((error) => {
          return "<pre>" + (currentLanguage === "es"
            ? error.msg.ES
            : error.msg.EN) + "</pre>";
        });
        MySwal.fire({
          html: `${msgError}`,
          icon: "warning",
          confirmButtonText: "Ok",
        });
      } else {
        if (registro.data.code === 409) {
          CheckExpiracion();
        } else {
          if (registro.data.code === 200) {
            MySwal.fire({
              text: t("mensajes.mensaje7"),
              imageUrl: "img/guardado-exitoso.svg",
              imageHeight: 65,
              confirmButtonText: "Ok",
            });
            await renewSession();
            navigate("/");
          }
        }
      }
    }
  };

  return (
    <>
      <BtnAtras titulo={t("perfil.titulo")} />
      {/* <h1 className='nombre-perfil'>Perfil{console.log("CUERPO "+HeadersAuth)}</h1> */}
      <div className="row row-form px-1 justify-content-center">
        <div className="col-10 col-md-8">
          <form onSubmit={guardar} style={{ marginBottom: "2em" }}>
            <div className="row pb-2 mb-3 px-3">
              <div className="col-md-6 col-12 d-flex flex-column align-items-center">
                <div className="d-flex align-items-center w-100 justify-content-start">
                  <label
                    htmlFor="nombre"
                    className="select_lista text-left"
                    style={{ flex: "1 0 auto" }}
                  >
                    {t("perfil.nombre")}
                  </label>{" "}
                </div>
                <Tooltip
                  title={!!errores.nombre && <span>{errores.nombre}</span>}
                  open={tooltipVisible.nombre}
                  color="#1CA6AF"
                >
                  <div className="w-100">
                    <input
                      className="form-control w-100"
                      type="text"
                      id="nombre"
                      value={tempDatosPerfil.nombre || ""}
                      onChange={handleChange}
                    />{" "}
                  </div>
                </Tooltip>
              </div>
              <div className="col-md-6 col-12 d-flex flex-column align-items-center">
                <div className="d-flex align-items-center w-100 justify-content-start">
                  <label
                    htmlFor="name"
                    className="select_lista text-left"
                    style={{ flex: "1 0 auto" }}
                  >
                    {t("perfil.apellido")}
                  </label>{" "}
                </div>
                <Tooltip
                  title={!!errores.apellido && <span>{errores.apellido}</span>}
                  open={tooltipVisible.apellido}
                  color="#1CA6AF"
                >
                  <div className="w-100">
                    <input
                      className="form-control w-100"
                      type="text"
                      id="apellido"
                      value={tempDatosPerfil.apellido || ""}
                      onChange={handleChange}
                    />
                  </div>
                </Tooltip>
              </div>
              <div className="col-md-6 col-12 d-flex flex-column align-items-center">
                <div className="d-flex align-items-center w-100 justify-content-start">
                  <label
                    htmlFor="name"
                    className="select_lista text-left"
                    style={{ flex: "1 0 auto" }}
                  >
                    {t("perfil.numeroIdentificacion")}
                  </label>
                  <Tooltip
                    title={
                      !!errores.numero_identificacion && (
                        <span>{errores.numero_identificacion}</span>
                      )
                    }
                    open={tooltipVisible.numero_identificacion}
                    color="#1CA6AF"
                  >
                    <div className="w-100">
                      <input
                        className="form-control w-100"
                        type="number"
                        id="numero_identificacion"
                        value={tempDatosPerfil.numero_identificacion || ""}
                        onChange={handleChange}
                      />{" "}
                    </div>
                  </Tooltip>
                </div>{" "}
              </div>
              <div className="col-md-6 col-12 d-flex flex-column align-items-center">
                <div className="d-flex align-items-center w-100 justify-content-start">
                  <label
                    htmlFor="name"
                    className="select_lista text-left"
                    style={{ flex: "1 0 auto" }}
                  >
                    Email
                  </label>
                  <Tooltip
                    title={!!errores.email && <span>{errores.email}</span>}
                    open={tooltipVisible.email}
                    color="#1CA6AF"
                  >
                    <div className="w-100">
                      <input
                        className="form-control w-100"
                        type="email"
                        id="email"
                        value={tempDatosPerfil.email || ""}
                        onChange={handleChange}
                      />{" "}
                    </div>
                  </Tooltip>
                </div>{" "}
              </div>
              <div className="col-md-6 col-12 d-flex flex-column align-items-center">
                <div className="d-flex align-items-center w-100 justify-content-start">
                  <label
                    htmlFor="name"
                    className="select_lista text-left"
                    style={{ flex: "1 0 auto" }}
                  >
                    {t("perfil.pais")}
                  </label>
                  <Tooltip
                    title={!!errores.pais && <span>{errores.pais}</span>}
                    open={tooltipVisible.pais}
                    color="#1CA6AF"
                  >
                    <select
                      className="form-select form-select-sm w-100"
                      id="pais"
                      onChange={handleChange}
                      value={tempDatosPerfil.pais || ""}
                    >
                      <option value="">
                        {t("empresa.datosSede.elejirOpcion")}
                      </option>
                      {opcionesPaises.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.paisnombre}
                        </option>
                      ))}
                    </select>
                  </Tooltip>
                </div>{" "}
              </div>
              <div className="col-md-6 col-12 d-flex flex-column align-items-center">
                <div className="d-flex align-items-center w-100 justify-content-start">
                  <label
                    htmlFor="name"
                    className="select_lista text-left"
                    style={{ flex: "1 0 auto" }}
                  >
                    {t("perfil.provincia")}
                  </label>
                  <Tooltip
                    title={
                      !!errores.provincia_estado && (
                        <span>{errores.provincia_estado}</span>
                      )
                    }
                    open={tooltipVisible.provincia_estado}
                    color="#1CA6AF"
                  >
                    <select
                      className="form-select form-select-sm w-100"
                      id="provincia_estado"
                      onChange={handleChange}
                      value={tempDatosPerfil.provincia_estado || ""}
                    >
                      <option value="">
                        {t("empresa.datosSede.elejirOpcion")}
                      </option>
                      {opcionesProvincias.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.estadonombre}
                        </option>
                      ))}
                    </select>
                  </Tooltip>
                </div>{" "}
              </div>
              <div className="col-md-6 col-12 d-flex flex-column align-items-center">
                <div className="d-flex align-items-center w-100 justify-content-start">
                  <label
                    htmlFor="name"
                    className="select_lista text-left"
                    style={{ flex: "1 0 auto" }}
                  >
                    {t("perfil.ciudad")}
                  </label>
                  <Tooltip
                    title={!!errores.ciudad && <span>{errores.ciudad}</span>}
                    open={tooltipVisible.ciudad}
                    color="#1CA6AF"
                  >
                    <div className="w-100">
                      <input
                        className="form-control w-100"
                        type="text"
                        id="ciudad"
                        value={tempDatosPerfil.ciudad || ""}
                        onChange={handleChange}
                      />
                    </div>
                  </Tooltip>
                </div>{" "}
              </div>
              <div className="col-md-6 col-12 d-flex flex-column align-items-center">
                <div className="d-flex align-items-center w-100 justify-content-start">
                  <label
                    htmlFor="name"
                    className="select_lista text-left"
                    style={{ flex: "1 0 auto" }}
                  >
                    {t("perfil.domicilio")}
                  </label>
                  <Tooltip
                    title={
                      !!errores.domicilio && <span>{errores.domicilio}</span>
                    }
                    open={tooltipVisible.domicilio}
                    color="#1CA6AF"
                  >
                    <div className="w-100">
                      <input
                        className="form-control w-100"
                        type="text"
                        id="domicilio"
                        value={tempDatosPerfil.domicilio || ""}
                        onChange={handleChange}
                      />
                    </div>
                  </Tooltip>
                </div>{" "}
              </div>
              <div className="col-md-6 col-12 d-flex flex-column align-items-center">
                <div className="d-flex align-items-center w-100 justify-content-start">
                  <label
                    htmlFor="name"
                    className="select_lista text-left"
                    style={{ flex: "1 0 auto" }}
                  >
                    {t("perfil.telefono")}
                  </label>{" "}
                </div>
                <Tooltip
                  title={!!errores.telefono && <span>{errores.telefono}</span>}
                  open={tooltipVisible.telefono}
                  color="#1CA6AF"
                >
                  <div className="w-100">
                    <input
                      className="form-control w-100"
                      type="number"
                      id="telefono"
                      value={tempDatosPerfil.telefono || ""}
                      onChange={handleChange}
                    />
                  </div>
                </Tooltip>
              </div>

              <div className="col-md-6 col-12 d-flex flex-column align-items-center">
                <div className="d-flex align-items-center w-100 justify-content-start">
                  <label htmlFor="name" className="select_lista text-left" style={{ flex: "1 0 auto" }}>
                    {t("perfil.codigo")}
                  </label></div>
                  <Tooltip
                    title={
                      !!errores.codigo_postal && (
                        <span>{errores.codigo_postal}</span>
                      )
                    }
                    open={tooltipVisible.codigo_postal}
                    color="#1CA6AF"
                  >
                 
                    <div className="w-100">
                      <input
                        className="form-control w-100"
                        type="text"
                        id="codigo_postal"
                        value={tempDatosPerfil.codigo_postal || ""}
                        onChange={handleChange}
                      />
                    </div>
                  </Tooltip>
                
              </div>
            </div>
            <div className="col-md-12 col-12 d-flex flex-column align-items-center gap-1">
              {" "}
              <div className="d-flex align-items-center justify-content-center mt-4">
                <BtnPrincipal name={t("botones.guardar")} />
              </div>{" "}
            </div>
          </form>{" "}
        </div>{" "}
      </div>
    </>
  );
};
