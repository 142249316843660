import { BtnAtras } from '../navegacion/btn_atras'
import { Tabs  } from 'antd';
import { CompDatosProyectosCompensadores } from './proyectosCompensadores';
import { CompDatosProyectosCompensaciones } from './listadoCompensaciones';
import { useState } from 'react';
import { useTranslation } from "react-i18next";



export const CompPanelCompensador = ({sesion}) => {
  const [t] = useTranslation("global");

     const onChangeTab = (key) => {
        // console.log(key);
     };
   
    const [losItem] = useState([
          {
               key: '1',
               label: t("compensacion.informacion.titulo2"),
               children: <CompDatosProyectosCompensadores sesion={sesion} id_empresa={sesion.empresaPrincipal} />,
          },
          {
               key: 'Compensaciones',
               label:  t("compensacion.general.compensaciones"),
               children: <CompDatosProyectosCompensaciones sesion={sesion} id_empresa={sesion.empresaPrincipal} />,
          },
          
     ]);
    
    return(
      <div className="fondoempresa" >    
          <div className="panel-empresa row g-3 d-flex justify-content-center  mb-5">
          <Tabs 
               defaultActiveKey="1" 
               tabPosition={'top'} 
               items={losItem} 
               onChange={onChangeTab} 
               className='tabs-panel-empresa justify-content-center  '
               tabBarExtraContent={<div />}
               tabBarGutter={0}
               tabBarStyle={{marginBottom: 0}}
               renderTabBar={(props, DefaultTabBar) => (
                 <DefaultTabBar {...props} className="custom-tab-label" />
               )}
          />
          </div>
      </div>
    )
}