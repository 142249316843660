
import React from 'react';

const PresetResultado = ({ t, resultado }) => {
  return (
    <>
      {t('mensajes.mensaje15')} {resultado} Ton.
      <pre>{t('mensajes.mensaje16')}</pre>
    </>
  );
};

export default PresetResultado;
