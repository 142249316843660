import React from 'react';
import { Button } from 'antd';
import { ArrowUpOutlined } from '@ant-design/icons';


export const BottomTop = () => {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className="bottom-component" onClick={handleClick}>
      <Button type="primary" icon={<ArrowUpOutlined />} className="round-button">
  
      </Button>
    </div>
  );
};
