import { Avatar, List, Input, Tooltip, Image } from "antd";
import axios from "axios";
import { useState, useEffect, useMemo, useRef } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useTranslation } from "react-i18next";
import {
  traerDatos,
  HeadersAuth,
  traerDatosObjeto,
  CheckExpiracion,
} from "../funciones_utiles";
import {
  validarTextoNumerico,
  validarSelector,
  validarNumericoDecimal,
  validarSoloTexto,
} from "../validaciones";
import {
  EditOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

const MySwal = withReactContent(Swal);

const URI_PAISES =
  process.env.REACT_APP_DOMINIO + process.env.REACT_APP_PUERTO + "/paises";
const URI_PROVINCIAS =
  process.env.REACT_APP_DOMINIO + process.env.REACT_APP_PUERTO + "/provincias";

export const CompDatosProyectosCompensadores = ({ sesion, id_empresa }) => {
  const [t, i18n] = useTranslation("global");
  const currentLanguage = i18n.language;
  const plan = sesion.datosPlanes.find(
    (item) => parseInt(item.id_empresa) === parseInt(id_empresa)
  );
  const [containerHeight, setContainerHeight] = useState("35em");
  const [opcionesPaises, setOpcionesPaises] = useState([]);
  const [opcionesProvincias, setOpcionesProvincias] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [tempDatosSede, setTempDatosSede] = useState({});
  const [idProyecto, setIdProyecto] = useState("");
  const [infoSedes, setInfoSedes] = useState([]);
  const URI_GUARDAR_SEDE =
    process.env.REACT_APP_DOMINIO +
    process.env.REACT_APP_PUERTO +
    "/nuevo_proyecto?id=" +
    id_empresa;
  const URI_ACTUALIZAR_SEDE =
    process.env.REACT_APP_DOMINIO +
    process.env.REACT_APP_PUERTO +
    "/update_proyecto?id=" +
    id_empresa +
    "&idp=" +
    idProyecto;
  const URI_PROYECTOS =
    process.env.REACT_APP_DOMINIO +
    process.env.REACT_APP_PUERTO +
    "/proyectos_por_compensador";
  const [inputValue, setInputValue] = useState("");
  const [filteredList, setFilteredList] = useState();
  // console.log(URI_VEHICULOS_EMPRESA_UNICO)
  const [show, setShow] = useState("");
  const inputFileRef = useRef(null);
  useMemo(() => {
    (async () => {
      setOpcionesPaises(await traerDatos(URI_PAISES));
    })();
  }, []);
  useEffect(() => {
    (async () => {
      setOpcionesProvincias(
        await traerDatos(URI_PROVINCIAS + "?id=" + tempDatosSede.pais)
      );
    })();
  }, [tempDatosSede.pais]);
  useEffect(() => {
    (async () => {
      setInfoSedes(await traerDatos(URI_PROYECTOS));
    })();
    // console.log("que tiene show:" , show)
    if (show === "editar" || show === "nuevo") {
      if (show === "nuevo") {
        setTempDatosSede({});
        setSelectedImages([]);
      } else if (show === "editar" && idProyecto !== "") {
        (async () => {
          const URI_PROYECTO_UNICO =
            process.env.REACT_APP_DOMINIO +
            process.env.REACT_APP_PUERTO +
            "/datos_proyecto?id=" +
            id_empresa +
            "&idp=" +
            idProyecto;
          setTempDatosSede(await traerDatosObjeto(URI_PROYECTO_UNICO));
          // console.log(URI_SEDE_UNICO)
          const URI_FOTOS_PROYECTO =
            process.env.REACT_APP_DOMINIO +
            process.env.REACT_APP_PUERTO +
            "/fotos_proyecto?id=" +
            id_empresa +
            "&idp=" +
            idProyecto;
          setSelectedImages(
            (await traerDatos(URI_FOTOS_PROYECTO)).map(
              (objeto) => objeto.imagen
            )
          );
          // console.log(await traerDatos(URI_FOTOS_PROYECTO));

          //ACA TENGO QU TRAER LAS IMAGENES PARA RELLENAR CUANDO ES EL UOPDATE
        })();
      }
    } else {
    }
  }, [show, id_empresa, idProyecto]);

  var [tooltipVisible, setTooltipVisible] = useState({
    nombre: false,
    descripcion: false,
    pais: false,
    provincia_estado: false,
    tipo: false,
    potencial: false,
    cupo: false,
    precio: false,
    certificado: false,
    tipo_certificado: false,
  });
  const [errores, setErrores] = useState({
    nombre: "",
    descripcion: "",
    pais: "",
    provincia_estado: "",
    tipo: "",
    potencial: "",
    cupo: "",
    precio: "",
    certificado: "",
    tipo_certificado: "",
  });
  const campos = [
    { name: "nombre", validator: validarTextoNumerico },
    { name: "descripcion", validator: validarTextoNumerico },
    { name: "provincia_estado", validator: validarSelector },
    { name: "pais", validator: validarSelector },
    { name: "tipo", validator: validarTextoNumerico },
    { name: "potencial", validator: validarTextoNumerico },
    { name: "cupo", validator: validarNumericoDecimal },
    { name: "precio", validator: validarNumericoDecimal },
    { name: "tipo_certificado", validator: validarTextoNumerico },
    { name: "certificado", validator: validarSelector },
  ];
  //Buscador
  useEffect(() => {
    // setFilteredList(infoVehiculos)
    if (inputValue) {
      setFilteredList(
        filteredList.filter(
          (item) =>
            item.nombre
              .toString()
              .toLowerCase()
              .indexOf(inputValue.toString().toLowerCase()) > -1
        )
      );
    } else {
      setFilteredList(infoSedes);
    }
  }, [inputValue, infoSedes]);

  function onSearch(e) {
    setInputValue(e.target.value);
  }
  const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
  const maxFileSize = 20 * 1024 * 1024; // 20 MB

  const handleLogoChange = (e) => {
    const files = e.target.files;
    const updatedSelectedImages = [...selectedImages];

    if (files.length + selectedImages.length > 5) {
      MySwal.fire({
        text: t("mensajes.mensaje20"),
        imageUrl: "img/lechuza-triste.png",
        imageHeight: 55,
        confirmButtonText: "Ok",
      });
      return;
    }

    // Array para almacenar las Promesas de carga de las imágenes
    const imagePromises = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (!file.type || !allowedTypes.includes(file.type)) {
        MySwal.fire({
          text: t("mensajes.mensaje21"),
          imageUrl: "img/icono-exitoso.png",
          imageHeight: 65,
          confirmButtonText: "Ok",
        });

        continue; // Move to the next file
      }

      if (file.size > maxFileSize) {
        MySwal.fire({
          text: t("mensajes.mensaje22"),
          imageUrl: "img/lechuza-triste.png",
          imageHeight: 55,
          confirmButtonText: "Ok",
        });

        continue; // Move to the next file
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);

      // Crear una Promesa para la carga de la imagen
      const imagePromise = new Promise((resolve) => {
        reader.onload = () => {
          resolve(reader.result);
        };
      });

      imagePromises.push(imagePromise);
    }

    // Esperar a que todas las Promesas se resuelvan (todas las imágenes se carguen)
    Promise.all(imagePromises).then((loadedImages) => {
      // Agregar las imágenes cargadas al estado
      updatedSelectedImages.push(...loadedImages);

      // Actualizar el estado para mostrar las imágenes en el visor
      setSelectedImages(updatedSelectedImages);
      setContainerHeight("40em");
    });
    if (files && files.length > 0) {
      const newImages = Array.from(files).map((file) =>
        URL.createObjectURL(file)
      );
      setSelectedImages([...selectedImages, ...newImages]);

      // Selecciona el contenedor y añade la clase expand
      const contenedor = document.querySelector(
        ".contenedor-form-compensadores"
      );
      contenedor.classList.add("expand");

      // Remueve la clase expand después de 1 segundo
      setTimeout(() => {
        contenedor.classList.remove("expand");
      }, 1000);
    }
  };
  const handleImageRemove = (index, e) => {
    e.preventDefault();
    const updatedSelectedImages = [...selectedImages];
    updatedSelectedImages.splice(index, 1);
    setSelectedImages(updatedSelectedImages);
  };
  const cambiarShow = async (e) => {
    e.stopPropagation();
    window.scrollTo(0, 0);
    const { id, value } = e.currentTarget; // Cambia aquí
    setShow("");
    setShow(value);

    let idSplit = id.split("-");

    if (value === "editar") {
      setIdProyecto(idSplit[0]);
    }
  };
  const handleChange = async (e) => {
    const { id, value } = e.target;
    // setTempDatosSede({
    //     ...tempDatosSede,
    //     [id]: value
    // })
    if (id === "pais") {
      setTempDatosSede({
        ...tempDatosSede,
        provincia_estado: "",
        [id]: value,
      });
    } else {
      setTempDatosSede({
        ...tempDatosSede,
        [id]: value,
      });
    }
    let erroresTemp = { ...errores };
    const validarCampo = async (campo, valor) => {
      const resultadoValidacion = await campo(valor, t);
      return {
        mensaje: resultadoValidacion.mensaje,
        valido: resultadoValidacion.valido,
      };
    };

    const campoActual = campos.find((campo) => campo.name === id);

    if (campoActual) {
      const mira = await validarCampo(campoActual.validator, value);
      erroresTemp[id] = mira.mensaje;
      setTooltipVisible((prevState) => ({
        ...prevState,
        [id]: !mira.valido,
      }));
      setTimeout(() => {
        setTooltipVisible((prevState) => ({
          ...prevState,
          [id]: false,
        }));
      }, 3000);
    }

    setErrores(erroresTemp);
  };
  const guardar = async (e) => {
    e.preventDefault();
    let hayErrores = false;
    const erroresTemp = {};
    for (const campo of campos) {
      const valorCampo = String(tempDatosSede[campo.name]);
      const resultadoValidacion = await campo.validator(valorCampo, t);

      if (resultadoValidacion.valido === false) {
        hayErrores = true;
        erroresTemp[campo.name] = resultadoValidacion.mensaje;
        setTooltipVisible((prevState) => ({
          ...prevState,
          [campo.name]: !resultadoValidacion.valido,
        }));
        setTimeout(() => {
          setTooltipVisible((prevState) => ({
            ...prevState,
            [campo.name]: false,
          }));
        }, 3000);
      }
    }
    tempDatosSede.fotos = selectedImages;
    if (hayErrores) {
      console.log(hayErrores);
      setErrores(erroresTemp);
      return;
    } else {
      let registro;

      if (show === "nuevo") {
        registro = await axios.post(
          URI_GUARDAR_SEDE,
          tempDatosSede,
          HeadersAuth
        );
      } else if (show === "editar" && idProyecto !== "") {
        registro = await axios.post(
          URI_ACTUALIZAR_SEDE,
          tempDatosSede,
          HeadersAuth
        );
      }
      if (registro.data.errors) {
        const errores = registro.data.errors;
        
        const msgError = errores.map((error) => {
          return "<pre>" + (currentLanguage === "es"
            ? error.msg.ES
            : error.msg.EN) + "</pre>";
        });
        MySwal.fire({
          html: `${msgError}`,
          icon: "warning",
          confirmButtonText: "Ok",
        });
      } else {
        if (registro.data.code === 409) {
          CheckExpiracion();
        } else {
          if (registro.data.code === 200) {
            MySwal.fire({
              text: t("mensajes.mensaje23"),
              imageUrl: "img/nueva-empresa.png",
              imageHeight: 75,
              confirmButtonText: "Ok",
            });
            setShow("");
            setContainerHeight("35em");
          }
        }
      }
    }
  };
  useEffect(() => {
    if (show === "editar" && idProyecto !== "") {
      setContainerHeight('35');
    }
  }, [show, idProyecto]);
  
  useEffect(() => {
    const ajustarAltura = () => {
      if (window.innerWidth <= 768) { // Pantallas pequeñas
        setContainerHeight('auto');
      } else {
        setContainerHeight(selectedImages.length > 0 ? 'auto' : '35em');
      }
    };
  
    window.addEventListener('resize', ajustarAltura);
    ajustarAltura(); // Ajustar la altura al cargar la página
  
    return () => window.removeEventListener('resize', ajustarAltura);
  }, [selectedImages]);
  const borrar = async (e, id) => {
    e.preventDefault();

    let validarBorrar = MySwal.fire({
      text: t("mensajes.mensaje24"),
      text: t("mensajes.mensaje25"),
      imageUrl: "img/borrar.png",
      imageHeight: 65,
      showCancelButton: true,
      confirmButtonColor: "#1CA6AF",
      cancelButtonColor: "#d33",
      confirmButtonText: t("botones.borrar"),
      cancelButtonText: t("botones.cancelar"),
    });
    if ((await validarBorrar).isConfirmed) {
      window.scrollTo(0, 0);
      // const { id } = e.target;
      // console.log("el id ", id);
      const URI_BORRAR_SEDE =
        process.env.REACT_APP_DOMINIO +
        process.env.REACT_APP_PUERTO +
        "/borrar_proyecto?id=" +
        id_empresa +
        "&idp=" +
        id;
      const borrar = await axios.delete(URI_BORRAR_SEDE, HeadersAuth);
      if (borrar.data.code === 401 || borrar.data.code === 402) {
        MySwal.fire({
          text: t("mensajes.mensaje12"),
          imageUrl: "img/lechuza-triste.png",
          imageHeight: 55,
          confirmButtonText: "Ok",
        });
      } else {
        if (borrar.data.code === 409) {
          CheckExpiracion();
        } else {
          if (borrar.data.code === 200) {
            MySwal.fire({
              text: t("mensajes.mensaje78"),
              imageUrl: "img/borrar.png",
              imageHeight: 50,
              confirmButtonText: "Ok",
            });
            if (show === "") {
              setShow("borrado");
            } else {
              setShow("");
            }
          }
        }
      }
    }
  };

  const activar = async (e, activo) => {
    e.preventDefault();

    let validarActivo = MySwal.fire({
      title: t("mensajes.mensaje26"),
      text: t("mensajes.mensaje27"),
      imageUrl: "img/nueva-empresa.png",
      imageHeight: 65,
      showCancelButton: true,
      confirmButtonColor: "#1CA6AF",
      cancelButtonColor: "#d33",
      confirmButtonText: t("botones.actualizar"),
      cancelButtonText: t("botones.cancelar"),
    });
    if ((await validarActivo).isConfirmed) {
      window.scrollTo(0, 0);
      const { id } = e.target;
      // console.log("el id ", id);
      const URI_ACTUALIZAR_SEDE =
        process.env.REACT_APP_DOMINIO +
        process.env.REACT_APP_PUERTO +
        "/activar_proyecto?id=" +
        id_empresa +
        "&idp=" +
        id +
        "&activo=" +
        activo;
      const borrar = await axios.post(URI_ACTUALIZAR_SEDE, HeadersAuth);
      if (borrar.data.code === 401 || borrar.data.code === 402) {
        MySwal.fire({
          text: t("mensajes.mensaje12"),
          imageUrl: "img/lechuza-triste.png",
          imageHeight: 55,
          confirmButtonText: "Ok",
        });
      } else {
        if (borrar.data.code === 409) {
          CheckExpiracion();
        } else {
          if (borrar.data.code === 200) {
            MySwal.fire({
              text: t("mensajes.mensaje28"),
              imageUrl: "img/nueva-empresa.png",
              imageHeight: 50,
              confirmButtonText: "Ok",
            });
            if (show === "") {
              setShow("actualizado");
            } else {
              setShow("");
            }
          }
        }
      }
    }
  };
  return (
    <>
      <div className="perfil row g-3 justify-content-center ms-2 me-2">
        {/* <span>{console.log(infoVehiculos)}</span> */}
        {show === "editar" ? (
          <div className=""></div>
        ) : show === "nuevo" ? (
          <div className="">
            <h3 className="subtitulo-empresas mt-3">
              {t("botones.nuevoProyecto")}
            </h3>
          </div>
        ) : (
          ""
        )}

        {show === "editar" || show === "nuevo" ? (
          <div className="perfil row g-3 justify-content-center ms-2 me-2">
            <div className="row row-form px-1 justify-content-center">
              <div className="col-10 col-md-8">
                <div
                  className="contenedor-form-compensadores"
                  style={{ height: containerHeight }}
                >
                  <div className="contenedor-titulo w-100">
                    <form onSubmit={guardar}>
                      <div className="row pb-2 mb-3 px-3">
                        <div className="col-md-12 col-12 d-flex flex-column align-items-center">
                          <label htmlFor="fileInput" className="boton-imagen">
                            <img
                              src="./img/foto.png"
                              alt="Imagen de botón"
                              style={{
                                width: "5em",
                                padding: "0.5em",
                                cursor: "pointer",
                              }}
                            />
                            {t("compensacion.tabla.fotoProyecto")}
                          </label>

                          <input
                            type="file"
                            id="fileInput"
                            ref={inputFileRef}
                            style={{ display: "none" }}
                            name="photo"
                            accept=".jpg,.jpeg,.png,.gif"
                            multiple
                            onChange={handleLogoChange}
                          />

                          {/* Renderizar imágenes seleccionadas debajo del label */}
                          <div className="d-flex align-items-center w-100 justify-content-start mt-3">
                            <div className="row justify-content-center text-center pt-2 g-3">
                              {selectedImages.map((imagen, index) => (
                                <div
                                  className="col-4 col-md-2"
                                  key={index}
                                  style={{
                                    alignItems: "center",
                                  }}
                                >
                                  <Image
                                    className="image-responsive"
                                    style={{ width: "50%", height: "auto" }}
                                    src={imagen}
                                  />
                                  <button
                                    className="btn"
                                    onClick={(e) => handleImageRemove(index, e)}
                                  >
                                    {t("botones.borrar")}
                                  </button>
                                </div>
                              ))}
                            </div>
                          </div>

                          <Image.PreviewGroup
                            preview={{
                              onChange: (current, prev) =>
                                console.log(
                                  `current index: ${current}, prev index: ${prev}`
                                ),
                            }}
                          ></Image.PreviewGroup>
                        </div>
                        <div className="col-md-4 col-12 d-flex flex-column align-items-center">
                          <div className="d-flex align-items-center w-100 justify-content-start">
                            <label
                              className="select_lista text-left"
                              style={{ flex: "1 0 auto" }}
                            >
                              {t("compensacion.tabla.nombreProyecto")}
                            </label>
                          </div>
                          <Tooltip
                            title={
                              !!errores.nombre && <span>{errores.nombre}</span>
                            }
                            open={tooltipVisible.nombre}
                            color="#1CA6AF"
                          >
                            <div className="w-100">
                              <input
                                className="form-control  w-100"
                                type="text"
                                placeholder={t("registro.nombre")}
                                id="nombre"
                                value={tempDatosSede.nombre || ""}
                                onChange={handleChange}
                              />
                            </div>
                          </Tooltip>
                        </div>
                        <div className="col-md-4 col-12 d-flex flex-column align-items-center">
                          <div className="d-flex align-items-center w-100 justify-content-start">
                            <label
                              className="select_lista text-left"
                              style={{ flex: "1 0 auto" }}
                            >
                              {t("empresa.datosSede.pais")}
                            </label>
                          </div>
                          <Tooltip
                            title={
                              !!errores.pais && <span>{errores.pais}</span>
                            }
                            open={tooltipVisible.pais}
                            color="#1CA6AF"
                          >
                            <select
                              className="form-select form-select-sm w-100"
                              id="pais"
                              onChange={handleChange}
                              value={tempDatosSede.pais || ""}
                            >
                              <option value="">
                                {t("empresa.datosSede.elejirOpcion")}
                              </option>
                              {opcionesPaises.map((option) => (
                                <option key={option.id} value={option.id}>
                                  {option.paisnombre}
                                </option>
                              ))}
                            </select>
                          </Tooltip>
                        </div>
                        <div className="col-md-4 col-12 d-flex flex-column align-items-center">
                          <div className="d-flex align-items-center w-100 justify-content-start">
                            <label
                              className="select_lista text-left"
                              style={{ flex: "1 0 auto" }}
                            >
                              {t("empresa.datosSede.provincia")}
                            </label>
                          </div>
                          <Tooltip
                            title={
                              !!errores.provincia_estado && (
                                <span>{errores.provincia_estado}</span>
                              )
                            }
                            open={tooltipVisible.provincia_estado}
                            color="#1CA6AF"
                          >
                            <select
                              className="form-select form-select-sm w-100"
                              id="provincia_estado"
                              onChange={handleChange}
                              value={tempDatosSede.provincia_estado || ""}
                            >
                              <option value="">
                                {t("empresa.datosSede.elejirOpcion")}
                              </option>
                              {opcionesProvincias.map((option) => (
                                <option key={option.id} value={option.id}>
                                  {option.estadonombre}
                                </option>
                              ))}
                            </select>
                          </Tooltip>
                        </div>
                        <div className="col-md-4 col-12 d-flex flex-column align-items-center">
                          <div className="d-flex align-items-center w-100 justify-content-start">
                            <label
                              className="select_lista text-left"
                              style={{ flex: "1 0 auto" }}
                            >
                              {t("compensacion.tabla.potencial")}
                            </label>
                          </div>
                          <Tooltip
                            title={
                              !!errores.potencial && (
                                <span>{errores.potencial}</span>
                              )
                            }
                            open={tooltipVisible.potencial}
                            color="#1CA6AF"
                          >
                            <div className="w-100">
                              <input
                                className="form-control  w-100"
                                type="text"
                                placeholder={t("compensacion.tabla.potencial")}
                                id="potencial"
                                value={tempDatosSede.potencial || ""}
                                onChange={handleChange}
                              />
                            </div>
                          </Tooltip>
                        </div>
                        <div className="col-md-4 col-12 d-flex flex-column align-items-center">
                          <div className="d-flex align-items-center w-100 justify-content-start">
                            <label
                              className="select_lista text-left"
                              style={{ flex: "1 0 auto" }}
                            >
                              {t("compensacion.tabla.tipo")}
                            </label>
                          </div>
                          <Tooltip
                            title={
                              !!errores.tipo && <span>{errores.tipo}</span>
                            }
                            open={tooltipVisible.tipo}
                            color="#1CA6AF"
                          >
                            <div className="w-100">
                              <input
                                className="form-control w-100"
                                type="text"
                                placeholder={t("compensacion.tabla.tipo")}
                                id="tipo"
                                value={tempDatosSede.tipo || ""}
                                onChange={handleChange}
                              />
                            </div>
                          </Tooltip>
                        </div>
                        <div className="col-md-4 col-12 d-flex flex-column align-items-center">
                          <div className="d-flex align-items-center w-100 justify-content-start">
                            <label
                              className="select_lista text-left"
                              style={{ flex: "1 0 auto" }}
                            >
                              {t("compensacion.tabla.precioPorTn")}
                            </label>{" "}
                          </div>
                          <Tooltip
                            title={
                              !!errores.precio && <span>{errores.precio}</span>
                            }
                            open={tooltipVisible.precio}
                            color="#1CA6AF"
                          >
                            <div className="w-100">
                              <Input.Group compact>
                                <Input
                                  type="number"
                                  placeholder={t(
                                    "compensacion.tabla.precioPorTn"
                                  )}
                                  id="precio"
                                  value={tempDatosSede.precio || ""}
                                  onChange={handleChange}
                                  style={{ width: "85%" }}
                                />
                                <Input
                                  value="USD"
                                  disabled
                                  style={{ width: "15%", textAlign: "center" }} // Ajusta el width para el sufijo
                                />
                              </Input.Group>
                            </div>
                          </Tooltip>
                        </div>
                        <div className="col-md-4 col-12 d-flex flex-column align-items-center">
                          <div className="d-flex align-items-center w-100 justify-content-start">
                            <label
                              className="select_lista text-left"
                              style={{ flex: "1 0 auto" }}
                            >
                              {t("compensacion.tabla.cupo")}
                            </label>
                          </div>
                          <Tooltip
                            title={
                              !!errores.cupo && <span>{errores.cupo}</span>
                            }
                            open={tooltipVisible.cupo}
                            color="#1CA6AF"
                          >
                            <div className="w-100">
                              <Input.Group compact>
                                <Input
                                  type="number"
                                  placeholder="tCO₂eq"
                                  id="cupo"
                                  value={tempDatosSede.cupo || ""}
                                  onChange={handleChange}
                                  style={{ width: "85%" }}
                                />
                                <Input
                                  value="t."
                                  disabled
                                  style={{ width: "15%", textAlign: "center" }} // Ajusta el width para el sufijo
                                />
                              </Input.Group>
                            </div>
                          </Tooltip>
                        </div>
                        <div className="col-md-4 col-12 d-flex flex-column align-items-center">
                          <div className="d-flex align-items-center w-100 justify-content-start">
                            <label
                              className="select_lista text-left"
                              style={{ flex: "1 0 auto" }}
                            >
                              {t("compensacion.tabla.poseeCertificado")}
                            </label>
                          </div>
                          <Tooltip
                            title={
                              !!errores.certificado && (
                                <span>{errores.certificado}</span>
                              )
                            }
                            open={tooltipVisible.certificado}
                            color="#1CA6AF"
                          >
                            <select
                              className="form-select form-select-sm w-100"
                              id="certificado"
                              onChange={handleChange}
                              value={tempDatosSede.certificado || 0}
                            >
                              <option key={0} value={0}>
                                {" "}
                                {t("botones.no")}{" "}
                              </option>
                              <option key={1} value={1}>
                                {" "}
                                {t("botones.si")}{" "}
                              </option>
                            </select>
                          </Tooltip>
                        </div>
                        <div className="col-md-4 col-12 d-flex flex-column align-items-center">
                          {tempDatosSede.certificado == 1 ? (
                            <>
                              <div className="d-flex align-items-center w-100 justify-content-start">
                                <label
                                  className="select_lista text-left"
                                  style={{ flex: "1 0 auto" }}
                                >
                                  {t("compensacion.tabla.tipoCertificado")}
                                </label>
                                <Tooltip
                                  title={
                                    !!errores.tipo_certificado && (
                                      <span>{errores.tipo_certificado}</span>
                                    )
                                  }
                                  open={tooltipVisible.tipo_certificado}
                                  color="#1CA6AF"
                                >
                                  <input
                                    className="form-control  w-100"
                                    type="text"
                                    placeholder={t(
                                      "compensacion.tabla.tipoCertificado"
                                    )}
                                    id="tipo_certificado"
                                    value={tempDatosSede.tipo_certificado || ""}
                                    onChange={handleChange}
                                  />
                                </Tooltip>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="col-md-12 col-12 d-flex flex-column align-items-center">
                          <div className="d-flex align-items-center w-100 justify-content-start">
                            <label
                              className="select_lista text-left"
                              style={{ flex: "1 0 auto" }}
                            >
                              {t("compensacion.tabla.descripcion")}
                            </label>
                          </div>
                          <Tooltip
                            title={
                              !!errores.descripcion && (
                                <span>{errores.descripcion}</span>
                              )
                            }
                            open={tooltipVisible.descripcion}
                            color="#1CA6AF"
                          >
                            <textarea
                              className="form-control  w-100"
                              type="text"
                              placeholder={t("compensacion.tabla.descripcion2")}
                              id="descripcion"
                              value={tempDatosSede.descripcion || ""}
                              onChange={handleChange}
                            ></textarea>
                          </Tooltip>
                        </div>
                        <div className="d-flex justify-content-center mt-3">
                          <button
                            className="boton-agregar-empresa"
                            type="submit"
                          >
                            <span className="label-boton">
                              {t("botones.guardarProyecto")}
                            </span>
                          </button>
                        </div>{" "}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="">
          <div
            className="d-flex justify-content-between"
            style={{ marginTop: "0.8em" }}
          >
            <Input
              className="dominio"
              placeholder={t("registro.nombre")}
              onChange={(e) => onSearch(e)}
              style={{
                width: 200,
              }}
            />
            <button
              className="boton-agregar-empresa justify-content-end"
              style={{ marginTop: "0" }}
              value="nuevo"
              onClick={cambiarShow}
            >
              {t("botones.nuevoProyecto")}
            </button>
          </div>
        </div>

        <List
          itemLayout="horizontal"
          dataSource={filteredList}
          className="lista-sedes"
          pagination={{
            size: "small",
            pageSize: 5,
          }}
          size="small"
          renderItem={(item) => (
            <List.Item
              actions={[
                <>
                  <button
                    style={{ marginRight: "8px" }}
                    className="boton-vehiculos"
                    onClick={cambiarShow}
                    id={item.id + "-sedes"}
                    value={"editar"}
                  >
                    <EditOutlined />
                  </button>

                  {parseInt(item.activo) === 1 ? (
                    <button
                      style={{ marginRight: "8px" }}
                      className="boton-vehiculos justify-content-end"
                      onClick={(e) => {
                        activar(e, 0);
                      }}
                      id={item.id}
                    >
                      <EyeInvisibleOutlined />
                    </button>
                  ) : (
                    <button
                      style={{ marginRight: "8px" }}
                      className="boton-vehiculos justify-content-end"
                      onClick={(e) => {
                        activar(e, 1);
                      }}
                      id={item.id}
                    >
                      <EyeOutlined />
                    </button>
                  )}

                  <button
                    style={{ marginRight: "8px" }}
                    className="boton-vehiculos justify-content-end"
                    onClick={(e) => borrar(e, item.id)}
                  >
                    <DeleteOutlined />
                  </button>
                </>,
              ]}
            >
              <List.Item.Meta
                avatar={<Avatar src=".././img/sedes.png" />}
                title={item.nombre_proyecto}
                description={
                  parseInt(item.activo) === 1 ? (
                    <b style={{ color: "green" }}>
                      {t("compensacion.estados.activo")}
                    </b>
                  ) : (
                    <b style={{ color: "green" }}>
                      {t("compensacion.estados.desactivado")}
                    </b>
                  )
                }
              />
            </List.Item>
          )}
        />
      </div>
    </>
  );
};
