import { BtnAtrasCalculadoras } from "../navegacion/btn_atras_calculadoras";
import { Tooltip, Tour } from "antd";
import { TablaCompleta } from "../tablas/tabla_simple/tabla_simple";
import { ChartEmisiones } from "../charts/ChartEmisiones";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import {
  PlusOutlined,
  RightOutlined,
  LeftOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import Swal from "sweetalert2";
import moment from "moment";
import withReactContent from "sweetalert2-react-content";
import { InputPrecio } from "../componentes/inputComponente";
import { FaQuestionCircle } from "react-icons/fa";
import {
  traerDatos,
  HeadersAuth,
  CheckExpiracion,
  traerDatosObjeto,
  fNum,
  sacaComas,
  cambioValor,
} from "../funciones_utiles";
import { validarNumericoDecimal, validarFechaPeriodo } from "../validaciones";
import PresetResultado from "./presetResultado";
import { BtnPrincipal } from "../navegacion/BtnPrincipal";
import { useTranslation } from "react-i18next";
const MySwal = withReactContent(Swal);

//DESCARGAR MOMENT JS

export const CompCalculadoraConsumo = ({ sesion, titulo }) => {
  const { t, i18n } = useTranslation("global");
  const currentLanguage = i18n.language;

  // console.log(empresasUsuario)
  // console.log(empresasUsuario.some(checkEmpresa))
  const anioActual = new Date().getFullYear();
  const [yearRange, setYearRange] = useState(anioActual);
  const [idUnico, setIdUnico] = useState("");
  const [tempDatosCalculo, setTempDatosCalculo] = useState({});
  const [datosChart, setDatosChart] = useState([]);
  const [datosTable, setDatosTable] = useState([]);
  const [idEmpresa, setIdEmpresa] = useState(sesion.empresaActiva);
  const [idSede, setIdSede] = useState(sesion.sedeActiva);
  const URI_COMBUSTIBLES =
    process.env.REACT_APP_DOMINIO +
    process.env.REACT_APP_PUERTO +
    "/combustibles";
  const [fuenteEmision, setFuenteEmision] = useState("");

  const [opcionesCombustibles, setOpcionesCombustibles] = useState([]);

  const refCantidad = useRef(null);
  const refMes = useRef(null);
  const refBtnGuardar = useRef(null);
  const refGrafico = useRef(null);
  const refTabla = useRef(null);

  const [abrirAyuda, setAbrirAyuda] = useState(false);
  const propsNext = {
    children: (
      <RightOutlined style={{ color: "white", marginBottom: "20px" }} />
    ),
    style: {
      height: 31,
    },
  };
  const propsFin = {
    children: (
      <CloseOutlined style={{ color: "white", marginBottom: "20px" }} />
    ),
    style: {
      height: 31,
    },
  };
  const propsPrev = {
    children: <LeftOutlined style={{ color: "white", marginBottom: "20px" }} />,
    style: {
      height: 31,
    },
  };

  const pasos = [
    {
      title: t("calculadoras.pasos.titulo3"),
      description: t("calculadoras.pasos.subtitulo3"),
      target: () => refMes.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    },
    {
      title: t("calculadoras.pasos.titulo2"),
      description: t("calculadoras.pasos.subtitulo17"),
      target: () => refCantidad.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    },

    {
      title: t("calculadoras.pasos.titulo4"),
      description: t("calculadoras.pasos.subtitulo4"),
      target: () => refBtnGuardar.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    },
    {
      title: t("calculadoras.pasos.titulo5"),
      description: t("calculadoras.pasos.subtitulo5"),
      target: () => refGrafico.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    },
    {
      title: t("calculadoras.pasos.titulo6"),
      description: t("calculadoras.pasos.subtitulo6"),
      target: () => refTabla.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsFin,
    },
  ];
  useEffect(() => {
    setIdEmpresa(sesion.empresaActiva);
    setIdSede(sesion.sedeActiva);
    if (idUnico) {
      (async () => {
        const URI_CONSUMOS_GAS_UNICOS =
          process.env.REACT_APP_DOMINIO +
          process.env.REACT_APP_PUERTO +
          "/datos_consumo_unico?type=electricity_report&unique_id=" +
          idUnico +
          "&ids=" +
          idSede +
          "&id=" +
          idEmpresa;
        const datosConsumo = await traerDatosObjeto(URI_CONSUMOS_GAS_UNICOS);
        // Aplicar formato a los Kwhs consumidos
        datosConsumo.fuel_quantity = cambioValor(
          parseFloat(datosConsumo.fuel_quantity).toFixed(2)
        );
        setTempDatosCalculo(datosConsumo);
      })();
    } else {
      setTempDatosCalculo({
        fuel_quantity: "",
        month_year: "",
      });
    }
  }, [idUnico, sesion.empresaActiva, sesion.sedeActiva, idEmpresa, idSede]);

  var [tooltipVisible, setTooltipVisible] = useState({
    fuel_quantity: false,
    month_year: false,
  });
  const [errores, setErrores] = useState({
    fuel_quantity: "",
    month_year: "",
  });
  const campos = [
    { name: "month_year", validator: validarFechaPeriodo },
    { name: "fuel_quantity", validator: validarNumericoDecimal },
  ];
  const [show, setShow] = useState("");
  const [fetchDatos, setFetchDatos] = useState([]);

  const actualizarChart = async () => {
    const nuevosDatos = (await fetchDatos).filter(
      (d) =>
        moment(d.date, "YYYY-MM").year() >= yearRange &&
        moment(d.date, "YYYY-MM").year() <= yearRange
    );
    setDatosChart(nuevosDatos);
    setDatosTable(nuevosDatos);
  };

  useEffect(() => {
    actualizarChart();
  }, [yearRange, fetchDatos, show]);

  useEffect(() => {
    (async () => {
      setFetchDatos(await traerDatos(URI_CONSUMOS_ENERGIA));
    })();
  }, [show]);

  useEffect(() => {
    (async () => {
      setOpcionesCombustibles(await traerDatos(URI_COMBUSTIBLES));
    })();
  }, [URI_COMBUSTIBLES]);

  let URI_NUEVO_CALCULO;
  const URI_CONSUMOS_ENERGIA =
    process.env.REACT_APP_DOMINIO +
    process.env.REACT_APP_PUERTO +
    "/datos_consumo?type=electricity_report&id=" +
    idEmpresa +
    "&ids=" +
    idSede;
  const elScroll = { x: 0 };
  const Columns = [
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}> {t("tablas.periodo")}</span>
        </div>
      ),
      key: "mes_anio",
      align: "center",
      sorter: (a, b) => a.date.localeCompare(b.date),
      render: (a) => (
        <span style={{ fontSize: "0.8em" }}>
          {moment(`${a.date}`, "YYYY-MM").format("MM/YYYY")}
        </span>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}> {t("tablas.kwh")}</span>
        </div>
      ),
      key: "kwh_consumed",
      align: "right",
      sorter: (a, b) => a.kwh_consumed - b.kwh_consumed,
      render: (a) => (
        <span style={{ fontSize: "0.8em" }}>{fNum(a.kwh_consumed)}</span>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span
            style={{ fontSize: "0.8em" }}
            dangerouslySetInnerHTML={{ __html: t("tablas.toneladas") }}
          />
        </div>
      ),
      dataIndex: "emitted_co2_kg",
      align: "right",
      sorter: (a, b) => a.emitted_co2_kg - b.emitted_co2_kg,
      render: (a) => <span style={{ fontSize: "0.8em" }}>{fNum(a)}</span>,
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}>+ Info</span>
        </div>
      ),
      key: "id",
      align: "center",
      render: (dato) => (
        <div className="row">
          <div className="col align-items-center">
            {dato.offset === 1 && (
              <>
                <button
                  className="boton-vehiculos-nuevo justify-content-center"
                  onClick={(e) => borrar(e, dato.id)}
                >
                  <DeleteOutlined />
                </button>

                <button
                  className="boton-vehiculos-nuevo justify-content-center"
                  onClick={(e) => traerCalculo(e, dato.id)}
                >
                  <EditOutlined />
                </button>
              </>
            )}
            {dato.offset === 2 && (
              <span>
                <b> {t("compensacion.estados.pendiente")}</b>
              </span>
            )}
            {dato.offset === 3 && (
              <span>
                <b>{t("compensacion.estados.compensado")}</b>
              </span>
            )}
          </div>
        </div>
      ),
    },
  ];
  const handleQuestionIconClick = async () => {
    try {
      const idCombustible = 22;
      const combustibleSeleccionado = opcionesCombustibles.find(
        (item) => parseInt(item.id) === idCombustible
      );
      // console.log(combustibleSeleccionado)
      if (combustibleSeleccionado) {
        const fuenteEmisionObtenida = combustibleSeleccionado.fuente;
        setFuenteEmision(fuenteEmisionObtenida);
        Swal.fire({
          imageUrl: "img/imagen1.png",
          imageHeight: 65,
          text: fuenteEmisionObtenida,
        });
      } else {
        console.error("Combustible no encontrado");
      }
    } catch (error) {
      console.error("Error al obtener la fuente de emisión:", error);
    }
  };

  const handleChange = async (e) => {
    const { id, value } = e.target;
    // console.log(value)
    setTempDatosCalculo({
      ...tempDatosCalculo,
      [id]: value,
    });

    let erroresTemp = { ...errores };
    const validarCampo = async (campo, valor) => {
      const resultadoValidacion = await campo(valor, t);
      return {
        mensaje: resultadoValidacion.mensaje,
        valido: resultadoValidacion.valido,
      };
    };
    const campoActual = campos.find((campo) => campo.name === id);

    if (campoActual) {
      const mira = await validarCampo(campoActual.validator, value);
      erroresTemp[id] = mira.mensaje;
      setTooltipVisible((prevState) => ({
        ...prevState,
        [id]: !mira.valido,
      }));
      setTimeout(() => {
        setTooltipVisible((prevState) => ({
          ...prevState,
          [id]: false,
        }));
      }, 3000);
    }

    setErrores(erroresTemp);
  };
  const handleChangeAnio = (e) => {
    const { value } = e.target;
    // console.log(value)
    setYearRange(value);
  };
  const guardar = async (e) => {
    e.preventDefault();

    let hayErrores = false;
    const erroresTemp = {};
    for (const campo of campos) {
      const valorCampo = String(tempDatosCalculo[campo.name]);
      const resultadoValidacion = await campo.validator(valorCampo, t);

      if (resultadoValidacion.valido === false) {
        hayErrores = true;
        erroresTemp[campo.name] = resultadoValidacion.mensaje;
        setTooltipVisible((prevState) => ({
          ...prevState,
          [campo.name]: !resultadoValidacion.valido,
        }));
        setTimeout(() => {
          setTooltipVisible((prevState) => ({
            ...prevState,
            [campo.name]: false,
          }));
        }, 3000);
      }
    }
    if (hayErrores) {
      setErrores(erroresTemp);
      return;
    } else {
      tempDatosCalculo.fuel_quantity = await sacaComas(
        tempDatosCalculo.fuel_quantity
      );
      if (idUnico) {
        URI_NUEVO_CALCULO =
          process.env.REACT_APP_DOMINIO +
          process.env.REACT_APP_PUERTO +
          "/calculo_kwhs?id=" +
          idEmpresa +
          "&ids=" +
          idSede +
          "&unique_id=" +
          idUnico;
      } else {
        URI_NUEVO_CALCULO =
          process.env.REACT_APP_DOMINIO +
          process.env.REACT_APP_PUERTO +
          "/calculo_kwhs?id=" +
          idEmpresa +
          "&ids=" +
          idSede +
          "&unique_id=none";
      }
      const registro = await axios.post(
        URI_NUEVO_CALCULO,
        tempDatosCalculo,
        HeadersAuth
      );
      if (registro.data.errors) {
        const errores = registro.data.errors;
        // console.log(errores)
        const msgError = errores.map((error) => {
          return (
            "<pre>" +
            (currentLanguage === "es" ? error.msg.ES : error.msg.EN) +
            "</pre>"
          );
        });
        MySwal.fire({
          html: `${msgError}`,
          imageUrl: "img/lechuza_triste.png",
          imageHeight: 65,
          confirmButtonText: "Ok",
        });
      } else {
        if (registro.data.code === 409) {
          CheckExpiracion();
        } else {
          if (registro.data.code === 200) {
            MySwal.fire({
              html: (
                <PresetResultado resultado={fNum(registro.data.result)} t={t} />
              ),
              imageUrl: "img/mensajes/resultado.svg",
              imageHeight: 65,
              confirmButtonText: "Ok",
            });
            setTempDatosCalculo({
              fuel_quantity: "",
              month_year: "",
            });
            if (show === "") {
              setShow("guardado");
            } else {
              setShow("");
            }
            setIdUnico("");
          }
        }
      }
    }
  };
  const traerCalculo = async (e, value) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    setIdUnico(value);
  };
  const borrar = async (e, value) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    //const { value } = e.target;
    console.log(e.target);
    const URI_BORRAR_CONSUMO =
      process.env.REACT_APP_DOMINIO +
      process.env.REACT_APP_PUERTO +
      "/borrar_consumo?type=electricity_report&id=" +
      idEmpresa +
      "&ids=" +
      idSede +
      "&unique_id=" +
      value;
    const borrar = await axios.delete(URI_BORRAR_CONSUMO, HeadersAuth);
    if (borrar.data.code === 401 || borrar.data.code === 402) {
      MySwal.fire({
        text: t("mensajes.mensaje12"),
        imageUrl: "img/lechuza-triste.png",
        imageHeight: 55,
        confirmButtonText: "Ok",
      });
    } else {
      if (borrar.data.code === 409) {
        CheckExpiracion();
      } else {
        if (borrar.data.code === 200) {
          MySwal.fire({
            text: t("mensajes.mensaje10"),
            imageUrl: "img/borrar.png",
            imageHeight: 50,
            confirmButtonText: "Ok",
          });
          if (show === "") {
            setShow("borrado");
          } else {
            setShow("");
          }
        }
      }
    }
  };

  return (
    <>
      <br></br>
      <div className="perfil row g-3 justify-content-center ms-4 me-4">
        <div className="row row-form px-2">
          <div className="col-12 col-md-5">
            <div className="contenedor-form-electricidad">
              <div className="contenedor-titulo w-100">
                <h2 className="titulo-calculadoras">
                  {titulo}
                  <Tooltip title={t("tooltip.ayuda")} placement="top">
                    <FaQuestionCircle
                      style={{
                        marginLeft: "5px",
                        cursor: "pointer",
                        color: "red",
                      }}
                      onClick={() => setAbrirAyuda(true)}
                    />
                  </Tooltip>
                  {idUnico ? (
                    <>
                      <Tooltip title={t("tooltip.nuevoCalculo")}>
                        <PlusOutlined
                          className="btnnuevocalculo p-1 mb-1"
                          onClick={(e) => setIdUnico("")}
                        />
                      </Tooltip>{" "}
                      <br></br>
                    </>
                  ) : (
                    <></>
                  )}
                </h2>
                <form onSubmit={guardar}>
                  <div className="row pb-2 mb-3 justify-content-center custom-select-row">
                    <div
                      ref={refMes}
                      className="col-md-6 col-12 d-flex flex-column align-items-center"
                    >
                      <div className="d-flex align-items-center w-100 justify-content-start">
                        <label className="select_lista text-left">
                          {t("tablas.mes")}
                        </label>{" "}
                      </div>
                      <Tooltip
                        title={
                          !!errores.month_year && (
                            <span>{errores.month_year}</span>
                          )
                        }
                        open={tooltipVisible.month_year}
                        color="#1CA6AF"
                      >
                        <div className="w-100">
                          <input
                            className="form-control  w-100"
                            type="month"
                            min="2010-01"
                            max={`${new Date().getFullYear()}-${(
                              "0" +
                              (new Date().getMonth() + 1)
                            ).slice(-2)}`}
                            id="month_year"
                            value={tempDatosCalculo.month_year || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </Tooltip>
                    </div>{" "}
                    <div
                      ref={refCantidad}
                      className="col-md-6 col-12 d-flex flex-column align-items-center"
                    >
                      <div className="d-flex align-items-center w-100 justify-content-start">
                        <label className="select_lista text-left">
                          {t("tablas.kwh")}
                          <Tooltip
                            title={t("tooltip.fuenteEmision")}
                            placement="top"
                          >
                            <FaQuestionCircle
                              style={{
                                marginLeft: "5px",
                                cursor: "pointer",
                                color: "#1ca5af",
                              }} //
                              onClick={() =>
                                handleQuestionIconClick("combustible")
                              }
                            />
                          </Tooltip>
                        </label>
                      </div>
                      <Tooltip
                        title={
                          !!errores.fuel_quantity && (
                            <span>{errores.fuel_quantity}</span>
                          )
                        }
                        open={tooltipVisible.fuel_quantity}
                        color="#1CA6AF"
                      >
                        <div className="w-100">
                          <InputPrecio
                            className="form-control w-100"
                            idCampo={"fuel_quantity"}
                            elValue={tempDatosCalculo.fuel_quantity}
                            elPlaceholder={"kWh Consumidos"}
                            elSufijo={"kWh"}
                            onChange={handleChange}
                          />
                        </div>
                      </Tooltip>
                    </div>
                    <div
                      ref={refBtnGuardar}
                      className="col-md-6 col-12 mt-4 d-flex justify-content-end w-100"
                    >
                      <BtnPrincipal name={t("botones.calcular")} />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-7">
            <div
              ref={refGrafico}
              className="col-12 col-md-12 px-4 col-input-calculadoras contenedor-form-electricidad"
            >
               <h2 className="texto-explicacion">
              {t("tablas.info3")}
            </h2>
              <select
                className="form-select form-select-sm w-100"
                id="anio_graph"
                onChange={handleChangeAnio}
                value={yearRange || ""}
              >
                <option value="">{t("planes.año2")}</option>
                {[
                  ...new Set(
                    fetchDatos.map((item) => item.date.substring(0, 4))
                  ),
                ].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <div>
                <ChartEmisiones data={datosChart} year={yearRange} />
              </div>
            </div>
           
          </div>
        </div>
        <div className="contenedor-tabla-electricidad px-5">
          <div
            ref={refTabla}
            className="col-12 col-md-12 tabla .offset-md-3 col-input-calculadoras"
          >
            <h2 className="texto-explicacion"> </h2>
            <TablaCompleta
              fetchDatos={datosTable}
              Columns={Columns}
              elScroll={elScroll}
              sizeVariant="small"
            />
          </div>{" "}
        </div>{" "}
      </div>
      <Tour
        open={abrirAyuda}
        onClose={() => setAbrirAyuda(false)}
        steps={pasos}
      />
    </>
  );
};
