import { BtnAtrasCalculadoras } from "../navegacion/btn_atras_calculadoras";
import { TablaCompleta } from "../tablas/tabla_simple/tabla_simple";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import moment from "moment";
import withReactContent from "sweetalert2-react-content";
import { InputPrecio } from "../componentes/inputComponente";
import { FaQuestionCircle } from "react-icons/fa";
import {
  PlusOutlined,
  RightOutlined,
  LeftOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  traerDatos,
  HeadersAuth,
  CheckExpiracion,
  traerDatosObjeto,
  fNum,
  sacaComas,
  cambioValor,
} from "../funciones_utiles";
import {
  validarNumericoDecimal,
  validarFecha,
  validarSelector,
} from "../validaciones";
import { BtnPrincipal } from "../navegacion/BtnPrincipal";
import PresetResultado from "./presetResultado";
import { Tooltip, Tour } from "antd";
import { useTranslation } from "react-i18next";
const MySwal = withReactContent(Swal);

const URI_COMBUSTIBLES =
  process.env.REACT_APP_DOMINIO +
  process.env.REACT_APP_PUERTO +
  "/combustibles";
const URI_VEHICULOS_LOGISTICA =
  process.env.REACT_APP_DOMINIO +
  process.env.REACT_APP_PUERTO +
  "/vehiculos_logistica";
const URI_AGUAS_LOGISTICA =
  process.env.REACT_APP_DOMINIO +
  process.env.REACT_APP_PUERTO +
  "/aguas_logistica";
const URI_UNIDAD_MEDIDA =
  process.env.REACT_APP_DOMINIO +
  process.env.REACT_APP_PUERTO +
  "/unidad_medida";

export const CompCalculadoraLogisticCombustibles = ({
  sesion,
  type,
  type_tabla,
  alcance,
  titulo,
}) => {
  const [t, i18n] = useTranslation("global");
  const currentLanguage = i18n.language;
  const [opcionesCombustibles, setOpcionesCombustibles] = useState([]);
  const [opcionesVehiculos, setOpcionesVehiculos] = useState([]);
  const [opcionesTipoAguas, setOpcionesTipoAguas] = useState([]);
  const [opcionesUnidadMedida, setOpcionesUnidadMedida] = useState([]);
  const [idUnico, setIdUnico] = useState("");
  const [tempDatosCalculo, setTempDatosCalculo] = useState({});
  const [idEmpresa, setIdEmpresa] = useState(sesion.empresaActiva);
  const [idSede, setIdSede] = useState(sesion.sedeActiva);
  const [sufijo, setSufijo] = useState("S/N");
  //Modal para los factores de emisión
  let URI_NUEVO_CALCULO_COMBUSTIBLE;

  //Lista para los factores de emisión
  const [fuenteEmision, setFuenteEmision] = useState("");

  const refAguas = useRef(null);
  const refVehiculo = useRef(null);
  const refCombustible = useRef(null);
  const refUnidadMedida = useRef(null);

  const refCantidad = useRef(null);
  const refCantidadUnidades = useRef(null);
  const refMes = useRef(null);
  const refBtnGuardar = useRef(null);
  const refTabla = useRef(null);

  const [abrirAyuda, setAbrirAyuda] = useState(false);

  const propsNext = {
    children: (
      <RightOutlined style={{ color: "white", marginBottom: "20px" }} />
    ),
    style: {
      height: 31,
    },
  };
  const propsFin = {
    children: (
      <CloseOutlined style={{ color: "white", marginBottom: "20px" }} />
    ),
    style: {
      height: 31,
    },
  };
  const propsPrev = {
    children: <LeftOutlined style={{ color: "white", marginBottom: "20px" }} />,
    style: {
      height: 31,
    },
  };
  const pasos = [
    {
      title: t("calculadoras.pasos.titulo11"),
      description: t("calculadoras.pasos.subtitulo11"),
      target: () => refMes.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    },
    {
      title: t("calculadoras.pasos.titulo8"),
      description: t("calculadoras.pasos.subtitulo8"),
      target: () => refAguas.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    },
    {
      title: t("calculadoras.pasos.titulo9"),
      description: t("calculadoras.pasos.subtitulo9"),
      target: () => refVehiculo.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    },
    {
      title: t("calculadoras.pasos.titulo1"),
      description: t("calculadoras.pasos.subtitulo1"),
      target: () => refCombustible.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    },
    {
      title: t("calculadoras.pasos.titulo26"),
      description: t("calculadoras.pasos.subtitulo26"),
      target: () => refUnidadMedida.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    },
    {
      title: t("calculadoras.pasos.titulo2"),
      description: t("calculadoras.pasos.subtitulo2"),
      target: () => refCantidad.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    },
    {
      title: t("calculadoras.pasos.titulo2"),
      description: t("calculadoras.pasos.subtitulo10"),
      target: () => refCantidadUnidades.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    },

    {
      title: t("calculadoras.pasos.titulo4"),
      description: t("calculadoras.pasos.subtitulo4"),
      target: () => refBtnGuardar.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsNext,
    },
    {
      title: t("calculadoras.pasos.titulo6"),
      description: t("calculadoras.pasos.subtitulo6"),
      target: () => refTabla.current,
      prevButtonProps: propsPrev,
      nextButtonProps: propsFin,
    },
  ];

  //meter esto en un useEffect, si hay id de calculo busco la data y armo, sino en blanco
  useEffect(() => {
    setIdEmpresa(sesion.empresaActiva);
    setIdSede(sesion.sedeActiva);
    if (idUnico) {
      (async () => {
        const URI_CONSUMOS_VIAJES_COMBUSTIBLES_UNICOS =
          process.env.REACT_APP_DOMINIO +
          process.env.REACT_APP_PUERTO +
          "/datos_consumo_unico?type=" +
          type +
          "&unique_id=" +
          idUnico +
          "&ids=" +
          idSede +
          "&id=" +
          idEmpresa;

        const datosConsumo = await traerDatosObjeto(
          URI_CONSUMOS_VIAJES_COMBUSTIBLES_UNICOS
        );
        // Aplicar formato a los Kwhs consumidos

        // Aplicar formato a los Kwhs consumidos
        datosConsumo.fuel_consumed = cambioValor(
          parseFloat(datosConsumo.fuel_consumed).toFixed(2)
        );
        datosConsumo.quantity_of_units = cambioValor(
          parseFloat(datosConsumo.quantity_of_units).toFixed(2)
        );
        setTempDatosCalculo({
          fecha: datosConsumo.date,
          combustible: datosConsumo.fuel,
          unidad_medida: datosConsumo.unit_of_measurament,
          combustible_consumido: datosConsumo.fuel_consumed,
          vehiculo: datosConsumo.vehicle,
          aguas: datosConsumo.waters,
          cantidad_unidades: datosConsumo.quantity_of_units,
        });
      })();
    } else {
      setTempDatosCalculo({
        fecha: "",
        combustible: "",
        unidad_medida: "",
        combustible_consumido: "",
        vehiculo: "",
        aguas: "",
        cantidad_unidades: "",
      });
    }
  }, [idUnico, sesion.empresaActiva, sesion.sedeActiva, idEmpresa, idSede]);

  // useEffect(() => {
  // 	const sufijoElegido = opcionesCombustibles.find(
  // 		(item) => parseInt(item.id) === parseInt(tempDatosCalculo.combustible)
  // 	);
  // 	sufijoElegido ? setSufijo(sufijoElegido.unidad_medida) : setSufijo("S/N");
  // 	setFuenteEmision(sufijoElegido ? sufijoElegido.fuentes : "");
  // }, [tempDatosCalculo.combustible, opcionesCombustibles]);

  useEffect(() => {
    (async () => {
      setOpcionesUnidadMedida(
        await traerDatos(
          URI_UNIDAD_MEDIDA + "?id=" + tempDatosCalculo.combustible
        )
      );
    })();
    const fuenteElegida = opcionesCombustibles.find(
      (item) => parseInt(item.id) === parseInt(tempDatosCalculo.combustible)
    );
    setFuenteEmision(fuenteElegida ? fuenteElegida.fuente : "");
  }, [tempDatosCalculo.combustible]);
  useEffect(() => {
    const sufijoElegido = opcionesUnidadMedida.find(
      (item) => parseInt(item.id) === parseInt(tempDatosCalculo.unidad_medida)
    );
    sufijoElegido ? setSufijo(sufijoElegido.unidad_medida) : setSufijo("S/N");
  }, [tempDatosCalculo.unidad_medida, opcionesUnidadMedida]);

  var [tooltipVisible, setTooltipVisible] = useState({
    fecha: false,
    combustible: false,
    combustible_consumido: false,
    unidad_medida: false,
    vehiculo: false,
    aguas: false,
    cantidad_unidades: false,
  });
  const [errores, setErrores] = useState({
    fecha: "",
    combustible: "",
    combustible_consumido: "",
    unidad_medida: "",
    vehiculo: "",
    aguas: "",
    cantidad_unidades: "",
  });
  const campos = [
    { name: "fecha", validator: validarFecha },
    { name: "combustible", validator: validarSelector },
    { name: "combustible_consumido", validator: validarNumericoDecimal },
    { name: "unidad_medida", validator: validarSelector },
    { name: "vehiculo", validator: validarSelector },
    { name: "aguas", validator: validarSelector },
    { name: "cantidad_unidades", validator: validarNumericoDecimal },
  ];

  const [show, setShow] = useState("");
  const [fetchDatos, setFetchDatos] = useState([]);

  const URI_CONSUMOS_VIAJES_COMBUSTIBLES =
    process.env.REACT_APP_DOMINIO +
    process.env.REACT_APP_PUERTO +
    "/datos_consumo?type=" +
    type_tabla +
    "&id=" +
    idEmpresa +
    "&ids=" +
    idSede;
  useEffect(() => {
    (async () => {
      setOpcionesCombustibles(await traerDatos(URI_COMBUSTIBLES));
      setOpcionesVehiculos(await traerDatos(URI_VEHICULOS_LOGISTICA));
      setOpcionesTipoAguas(await traerDatos(URI_AGUAS_LOGISTICA));

      setFetchDatos(await traerDatos(URI_CONSUMOS_VIAJES_COMBUSTIBLES));
      console.log(await traerDatos(URI_CONSUMOS_VIAJES_COMBUSTIBLES));
    })();
  }, [show, URI_CONSUMOS_VIAJES_COMBUSTIBLES]);

  const elScroll = { x: 0 };
  const Columns = [
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}>{t("tablas.periodo")}</span>
        </div>
      ),
      key: "fecha",
      align: "center",
      sorter: (a, b) => a.fecha.localeCompare(b.fecha),
      render: (a) => (
        <span style={{ fontSize: "0.9em" }}>
          {moment(`${a.fecha}`, "YYYY-MM").format("MM/YYYY")}
        </span>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}>{t("tablas.vehiculo")}</span>
        </div>
      ),
      align: "left",
      sorter: (a, b) => a.nombre_vehiculo.localeCompare(b.nombre_vehiculo),
      render: (a) => (
        <span style={{ fontSize: "0.8em" }}>
          {currentLanguage === "es" ? a.vehiculo : a.vehiculo_en}
        </span>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}>{t("tablas.tipoViaje")}</span>
        </div>
      ),
      align: "left",
      sorter: (a, b) => a.tipo_aguas.localeCompare(b.tipo_aguas),
      render: (a) => (
        <span style={{ fontSize: "0.8em" }}>
          {currentLanguage === "es" ? a.aguas : a.aguas_en}
        </span>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center", fontSize: "0.8em" }}>
          <span style={{ fontSize: "0.8em" }}> {t("tablas.cantidad")} </span>
        </div>
      ),
      key: "combustible_consumido",
      align: "right",
      width:"60px",
      sorter: (a, b) => a.combustible_consumido - b.combustible_consumido,
      render: (a) => (
        <span style={{ fontSize: "0.8em" }}>
          {fNum(a.combustible_consumido)} {a.sufijo}{" "}
        </span>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}>{t("tablas.combustible")}</span>
        </div>
      ),
      align: "left",
      width:"100px",
      sorter: (a, b) =>
        a.nombre_combustible.localeCompare(b.nombre_combustible),
      render: (a) => (
        <span style={{ fontSize: "0.8em" }}>
          {currentLanguage === "es"
            ? a.nombre_combustible
            : a.nombre_combustible_en}
        </span>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}>
            {t("tablas.cantidadUnidades")}
          </span>
        </div>
      ),
      key: "cantidad_unidad",
      align: "right",
      sorter: (a, b) => a.cantidad_unidad - b.cantidad_unidad,
      render: (a) => (
        <span style={{ fontSize: "0.8em" }}>{fNum(a.cantidad_unidad)}</span>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span
            style={{ fontSize: "0.8em" }}
            dangerouslySetInnerHTML={{ __html: t("tablas.toneladasPorUnidad") }}
          />
        </div>
      ),
      key: "kgcodose_emitido_unidad",
      align: "right",
      sorter: (a, b) => a.kgcodose_emitido_unidad - b.kgcodose_emitido_unidad,
      render: (a) => (
        <span style={{ fontSize: "0.8em" }}>
          {fNum(a.kgcodose_emitido_unidad)}
        </span>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span
            style={{ fontSize: "0.8em" }}
            dangerouslySetInnerHTML={{ __html: t("tablas.toneladas") }}
          />
        </div>
      ),
      key: "kgcodose_emitido",
      align: "right",
      sorter: (a, b) => a.kgcodose_emitido - b.kgcodose_emitido,
      render: (a) => (
        <span style={{ fontSize: "0.8em" }}>{a.kgcodose_emitido}</span>
      ),
    },
    {
      title: () => (
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "0.8em" }}>+ Info</span>
        </div>
      ),
      key: "id",
      align: "center",
      render: (dato) => (
        <div className="row">
          <div className="col align-items-center">
            {dato.compensado === 1 && (
              <>
                <button
                  className="boton-vehiculos-nuevo justify-content-center"
                  onClick={(e) => borrar(e, dato.id)}
                >
                  <DeleteOutlined />
                </button>

                <button
                  className="boton-vehiculos-nuevo justify-content-center"
                  onClick={(e) => traerCalculo(e, dato.id)}
                >
                  <EditOutlined />
                </button>
              </>
            )}
            {dato.compensado === 2 && (
              <span>
                <b>{t("compensacion.estados.pendiente")}</b>
              </span>
            )}
            {dato.compensado === 3 && (
              <span>
                <b>{t("compensacion.estados.compensado")}</b>
              </span>
            )}
          </div>
        </div>
      ),
    },
  ];

  const handleQuestionIconClick = () => {
    if (!tempDatosCalculo.combustible) {
      // Mostrar Sweet Alert si no se seleccionó una opción
      Swal.fire({
        imageUrl: "img/lechuza-triste.png",
        imageHeight: 65,
        title: "Error",
        text: t("mensajes.mensaje11"),
      });
    } else {
      // Abrir el modal solo si se ha seleccionado una opción
      Swal.fire({
        imageUrl: "img/imagen1.png",
        imageHeight: 65,
        text: fuenteEmision,
      });
      // setModalVisible(true);
    }
  };

  const handleChange = async (e) => {
    const { id, value } = e.target;
    setTempDatosCalculo({
      ...tempDatosCalculo,
      [id]: value,
    });
    // console.log("combustible seleccionado:", tempDatosCalculo.combustible);
    let erroresTemp = { ...errores };
    const validarCampo = async (campo, valor) => {
      const resultadoValidacion = await campo(valor, t);
      return {
        mensaje: resultadoValidacion.mensaje,
        valido: resultadoValidacion.valido,
      };
    };
    const campoActual = campos.find((campo) => campo.name === id);

    if (campoActual) {
      const mira = await validarCampo(campoActual.validator, value);
      erroresTemp[id] = mira.mensaje;
      setTooltipVisible((prevState) => ({
        ...prevState,
        [id]: !mira.valido,
      }));
      setTimeout(() => {
        setTooltipVisible((prevState) => ({
          ...prevState,
          [id]: false,
        }));
      }, 3000);
    }

    setErrores(erroresTemp);
  };

  const guardar = async (e) => {
    e.preventDefault();

    let hayErrores = false;
    const erroresTemp = {};
    for (const campo of campos) {
      const valorCampo = String(tempDatosCalculo[campo.name]);
      const resultadoValidacion = await campo.validator(valorCampo, t);

      if (resultadoValidacion.valido === false) {
        hayErrores = true;
        erroresTemp[campo.name] = resultadoValidacion.mensaje;
        setTooltipVisible((prevState) => ({
          ...prevState,
          [campo.name]: !resultadoValidacion.valido,
        }));
        setTimeout(() => {
          setTooltipVisible((prevState) => ({
            ...prevState,
            [campo.name]: false,
          }));
        }, 3000);
      }
    }
    if (hayErrores) {
      setErrores(erroresTemp);
      return;
    } else {
      tempDatosCalculo.cantidad_unidades = await sacaComas(
        tempDatosCalculo.cantidad_unidades
      );
      tempDatosCalculo.combustible_consumido = await sacaComas(
        tempDatosCalculo.combustible_consumido
      );

      if (idUnico) {
        URI_NUEVO_CALCULO_COMBUSTIBLE =
          process.env.REACT_APP_DOMINIO +
          process.env.REACT_APP_PUERTO +
          "/calculo_logistica_combustible?id=" +
          idEmpresa +
          "&scope=" +
          alcance +
          "&ids=" +
          idSede +
          "&unique_id=" +
          idUnico;
      } else {
        URI_NUEVO_CALCULO_COMBUSTIBLE =
          process.env.REACT_APP_DOMINIO +
          process.env.REACT_APP_PUERTO +
          "/calculo_logistica_combustible?id=" +
          idEmpresa +
          "&scope=" +
          alcance +
          "&ids=" +
          idSede +
          "&unique_id=none";
      }
      console.log(tempDatosCalculo.unidad_medida);
      const registro = await axios.post(
        URI_NUEVO_CALCULO_COMBUSTIBLE,
        {
          fuel: tempDatosCalculo.combustible,
          vehicle: tempDatosCalculo.vehiculo,
          waters: tempDatosCalculo.aguas,
          fuel_consumed: tempDatosCalculo.combustible_consumido,
          quantity_of_units: tempDatosCalculo.cantidad_unidades,
          unit_of_measurament: tempDatosCalculo.unidad_medida,
          date: tempDatosCalculo.fecha,
        },
        HeadersAuth
      );
      if (registro.data.errors) {
        const errores = registro.data.errors;
        // console.log(errores)
        const msgError = errores.map((error) => {
          return "<pre>" + (currentLanguage === "es"
            ? error.msg.ES
            : error.msg.EN) + "</pre>";
        });
        MySwal.fire({
          html: `${msgError}`,
          imageUrl: "img/lechuza_triste.png",
          imageHeight: 65,
          confirmButtonText: "Ok",
        });
      } else {
        if (registro.data.code === 409) {
          CheckExpiracion();
        } else {
          if (registro.data.code === 200) {
            MySwal.fire({
              html: (
                <PresetResultado resultado={fNum(registro.data.result)} t={t} />
              ),
              imageUrl: "img/mensajes/resultado.svg",
              imageHeight: 65,
              confirmButtonText: "Ok",
            });

            setTempDatosCalculo({
              fecha: "",
              combustible: "",
              combustible_consumido: "",
              unidad_medida: "",
              vehiculo: "",
              aguas: "",
              cantidad_unidades: "",
            });
            if (show === "") {
              setShow("guardado");
            } else {
              setShow("");
            }
            if (idUnico) {
              setIdUnico("");
            }
          }
        }
      }
    }
  };
  const traerCalculo = async (e, value) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    setIdUnico(value);
  };
  const borrar = async (e, value) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    //const { value } = e.target;
    // console.log(value)
    const URI_BORRAR_CONSUMO =
      process.env.REACT_APP_DOMINIO +
      process.env.REACT_APP_PUERTO +
      "/borrar_consumo?type=" +
      type +
      "&id=" +
      idEmpresa +
      "&unique_id=" +
      value +
      "&ids=" +
      idSede;
    const borrar = await axios.delete(URI_BORRAR_CONSUMO, HeadersAuth);
    if (borrar.data.code === 401 || borrar.data.code === 402) {
      MySwal.fire({
        text: t("mensajes.mensaje12"),
        imageUrl: "img/lechuza-triste.png",
        imageHeight: 55,
        confirmButtonText: "Ok",
      });
    } else {
      if (borrar.data.code === 409) {
        CheckExpiracion();
      } else {
        if (borrar.data.code === 200) {
          MySwal.fire({
            text: t("mensajes.mensaje10"),
            imageUrl: "img/borrar.png",
            imageHeight: 50,
            confirmButtonText: "Ok",
          });
          if (show === "") {
            setShow("borrado");
          } else {
            setShow("");
          }
        }
      }
    }
  };

  return (
    <>
      <br></br>
      <div className="perfil row g-3 justify-content-center ms-2 me-2">
        <div className="row row-form px-1">
          <div className="col-12 col-md-4">
            <div className="contenedor-form-km-tn">
              <div className="contenedor-titulo w-100">
                <h2 className="titulo-calculadoras">
                  {titulo}
                  <Tooltip title={t("tooltip.ayuda")} placement="top">
                    <FaQuestionCircle
                      style={{
                        marginLeft: "5px",
                        cursor: "pointer",
                        color: "red",
                      }}
                      onClick={() => setAbrirAyuda(true)}
                    />
                  </Tooltip>
                  {idUnico ? (
                    <>
                      <Tooltip title={t("tooltip.nuevoCalculo")}>
                        <PlusOutlined
                          className="btnnuevocalculo p-1 mb-1"
                          onClick={(e) => setIdUnico("")}
                        />
                      </Tooltip>{" "}
                      <br></br>
                    </>
                  ) : (
                    <></>
                  )}
                </h2>
                <form onSubmit={guardar}>
                  

                  <div className="row pb-2 mb-3 justify-content-center custom-select-row">
                    <div
                      ref={refMes}
                      className="col-md-6 col-12 d-flex flex-column align-items-center"
                    >
                      <div className="d-flex align-items-center w-100 justify-content-start">
                        <label
                          className="select_lista text-left"
                          style={{ flex: "1 0 auto" }}
                        >
                          {t("tablas.fecha")}
                        </label>
                      </div>
                      <Tooltip
                        title={!!errores.fecha && <span>{errores.fecha}</span>}
                        open={tooltipVisible.fecha}
                        color="#1CA6AF"
                      >
                        <div className="w-100">
                          <input
                            className="form-control  w-100"
                            type="date"
                            min="2010-01-01"
                            max={`${new Date().getFullYear()}-${(
                              "0" +
                              (new Date().getMonth() + 1)
                            ).slice(-2)}-${("0" + new Date().getDate()).slice(
                              -2
                            )}`}
                            id="fecha"
                            value={tempDatosCalculo.fecha || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </Tooltip>
                    </div>
                    <div
                      ref={refAguas}
                      className="col-md-6 col-12 d-flex flex-column align-items-center"
                    >
                      <div className="d-flex align-items-center w-100 justify-content-start">
                        <label
                          className="select_lista text-left"
                          style={{ flex: "1 0 auto" }}
                        >
                          {t("tablas.tipoViaje")}
                        </label>
                      </div>
                      <Tooltip
                        title={!!errores.aguas && <span>{errores.aguas}</span>}
                        open={tooltipVisible.aguas}
                        color="#1CA6AF"
                      >
                        <select
                          className="form-select form-select-sm w-100"
                          id="aguas"
                          onChange={handleChange}
                          value={tempDatosCalculo.aguas || ""}
                        >
                          <option value="">
                            {t("empresa.datosSede.elejirOpcion")}
                          </option>
                          {opcionesTipoAguas.map((option) => (
                            <option key={option.id} value={option.id}>
                              {currentLanguage === "es"
                                ? option.aguas
                                : option.aguas_en}
                            </option>
                          ))}
                        </select>
                      </Tooltip>
                    </div>
                    <div
                      ref={refVehiculo}
                      className="col-md-6 col-12 d-flex flex-column align-items-center"
                    >
                    
                      <div className="d-flex align-items-center w-100 justify-content-start">
                        <label
                          className="select_lista text-left"
                          style={{ flex: "1 0 auto" }}
                        >
                          {t("tablas.vehiculo")}
                        </label>
                      </div>
                      <Tooltip
                        title={
                          !!errores.vehiculo && <span>{errores.vehiculo}</span>
                        }
                        open={tooltipVisible.vehiculo}
                        color="#1CA6AF"
                      >
                        <select
                          className="form-select form-select-sm w-100"
                          id="vehiculo"
                          onChange={handleChange}
                          value={tempDatosCalculo.vehiculo || ""}
                        >
                          <option value="">
                            {t("empresa.datosSede.elejirOpcion")}
                          </option>
                          {opcionesVehiculos.map((option) => (
                            <option key={option.id} value={option.id}>
                              {currentLanguage === "es"
                                ? option.vehiculo
                                : option.vehiculo_en}
                            </option>
                          ))}
                        </select>
                      </Tooltip>
                    </div>
                    <div
                      ref={refCombustible}
                      className="col-md-6 col-12 d-flex flex-column align-items-center"
                    >
                      <div className="d-flex align-items-center w-100 justify-content-start">
                        <label
                          className="select_lista text-left"
                          style={{ flex: "1 0 auto" }}
                        >
                          {t("tablas.combustible")}

                          <Tooltip
                            title={t("tooltip.fuenteEmision")}
                            placement="top"
                          >
                            <FaQuestionCircle
                              style={{
                                marginLeft: "5px",
                                cursor: "pointer",
                                color: "#1ca5af",
                              }}
                              onClick={() =>
                                handleQuestionIconClick(fuenteEmision)
                              }
                            />
                          </Tooltip>
                        </label>{" "}
                      </div>
                      <Tooltip
                        title={
                          !!errores.combustible && (
                            <span>{errores.combustible}</span>
                          )
                        }
                        open={tooltipVisible.combustible}
                        color="#1CA6AF"
                      >
                        <select
                          className="form-select form-select-sm w-100"
                          id="combustible"
                          onChange={handleChange}
                          value={tempDatosCalculo.combustible || ""}
                        >
                          <option value="">
                            {t("empresa.datosSede.elejirOpcion")}
                          </option>
                          {opcionesCombustibles
                            .filter((option) => option.apto_movil === 1)
                            .map((option) => (
                              <option key={option.id} value={option.id}>
                                {currentLanguage === "es"
                                  ? option.combustible
                                  : option.combustible_en}
                              </option>
                            ))}
                        </select>
                      </Tooltip>
                    </div>
                    <div
                      ref={refUnidadMedida}
                      className="col-md-6 col-12 d-flex flex-column align-items-center"
                    >
                      <div className="d-flex align-items-center w-100 justify-content-start">
                        <label className="select_lista text-left">
                          {t("tablas.unidadMedida")}
                        </label>
                      </div>
                      <Tooltip
                        title={
                          !!errores.unidad_medida && (
                            <span>{errores.unidad_medida}</span>
                          )
                        }
                        open={tooltipVisible.unidad_medida}
                        color="#1CA6AF"
                      >
                        <select
                          className="form-select form-select-sm w-100"
                          id="unidad_medida"
                          onChange={handleChange}
                          value={tempDatosCalculo.unidad_medida || ""}
                        >
                          <option key="" value="">
                            {t("empresa.datosSede.elejirOpcion")}
                          </option>
                          {opcionesUnidadMedida.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.unidad_medida}
                            </option>
                          ))}
                        </select>
                      </Tooltip>
                    </div>
                    <div
                      ref={refCantidad}
                      className="col-md-6 col-12 d-flex flex-column align-items-center"
                    >
                      <div className="d-flex align-items-center w-100 justify-content-start">
                        <label
                          className="select_lista text-left"
                          style={{ flex: "1 0 auto" }}
                        >
                          {t("tablas.cantidadCombustible")}
                        </label>{" "}
                      </div>
                      <Tooltip
                        title={
                          !!errores.combustible_consumido && (
                            <span>{errores.combustible_consumido}</span>
                          )
                        }
                        open={tooltipVisible.combustible_consumido}
                        color="#1CA6AF"
                      >
                        <div className="w-100">
                          <InputPrecio
                            className="form-control"
                            idCampo={"combustible_consumido"}
                            elValue={tempDatosCalculo.combustible_consumido}
                            elPlaceholder={"Combustible Consumido"}
                            elSufijo={sufijo}
                            onChange={handleChange}
                          />
                        </div>
                      </Tooltip>
                    </div>
                    <div
                      ref={refCantidadUnidades}
                      className="col-md-6 col-12 d-flex flex-column align-items-center gap-1"
                    >
                      <div className="d-flex align-items-center w-100 justify-content-start">
                        <label
                          className="select_lista "
                
                        >
                          {t("tablas.cantidadUnidades")}
                        </label>{" "}
                      </div>
                      <Tooltip
                        title={
                          !!errores.cantidad_unidades && (
                            <span>{errores.cantidad_unidades}</span>
                          )
                        }
                        open={tooltipVisible.cantidad_unidades}
                        color="#1CA6AF"
                      >
                        <div className="w-100">
                          <InputPrecio
                            className="form-control"
                            idCampo={"cantidad_unidades"}
                            elValue={tempDatosCalculo.cantidad_unidades}
                            elPlaceholder={"Cantidad unidades"}
                            elSufijo={"U"}
                            onChange={handleChange}
                          />
                        </div>
                      </Tooltip>
                    </div>
                    <div
                      ref={refBtnGuardar}
                      className="col-md-6 col-12 d-flex flex-column align-items-center gap-1"
                    >
                      <div className="d-flex align-items-center w-100 justify-content-end mt-4">
                        <BtnPrincipal name={t("botones.calcular")} />
                      </div>
                    </div>{" "}
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-8">
            <div className="contenedor-tabla-combustible">
              <div ref={refTabla}>
                <TablaCompleta
                  fetchDatos={fetchDatos}
                  Columns={Columns}
                  elScroll={elScroll}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Tour
        open={abrirAyuda}
        onClose={() => setAbrirAyuda(false)}
        steps={pasos}
      />
    </>
  );
};
