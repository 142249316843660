import { Row, Col, Tooltip } from "antd";
import React from "react";
import { FaInstagram, FaLinkedinIn, FaCopyright } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  InstagramOutlined,
  WhatsAppOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";

// Archivo: Footer.jsx
export const Footer = () => {
  const [t] = useTranslation("global");
  return (
    <footer className="site-footer">
      <div className="footer-container">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <h6>{t("footer.titulo1")} </h6>
            <p className="text-justify">
              {t("footer.subtitulo1")}
              <br />
              {t("footer.subtitulo2")}
            </p>
            <img
              src="img/logo-blanco.svg"
              style={{ width: "7em" }}
              className="img-footer"
              alt="Logo"
            />
          </div>

          <div className="col-xs-6 col-md-3 d-none d-md-block"></div>

          <div className="col-xs-6 col-md-3 text-end">
            <h6>{t("footer.titulo2")}</h6>
            <ul className="footer-links">
              <li>
                <a href="#">(2396) 585964 / (011) 54581358</a>
              </li>
              <li>
                <a
                  href="mailto:owltrackcompany@gmail.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  info@owl-track.com
                </a>
              </li>
              <li>
                <a
                  href="https://www.google.com/maps/place/Buenos+Aires,+Argentina"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Argentina - Buenos Aires
                </a>
              </li>
            </ul>
          </div>
        </div>
        <hr />
      </div>
      <div className="footer-container">
        <div className="row">
          <div className="col-md-8 col-sm-6 col-xs-12">
            <p className="copyright-text">
              Owl-Track v. {process.env.REACT_APP_VERSION} - Copyright &copy;{" "}
              {t("footer.subtitulo3")}
              <a
                href="https://fasecuatro.com.ar/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "white" }}
              >
                Fase Cuatro SAS
              </a>
            </p>
            <p className="copyright-text">
              <a
                href="/terminos_y_condiciones"
                target="_blank"
                rel="noopener noreferrer"
                className="copyright-text"
                style={{fontSize:"0.8em"}}
              >
                Términos y condiciones
              </a>
            </p>
          </div>

          <div className="col-md-4 col-sm-6 col-xs-12">
            <ul className="social-icons">
              <li>
                <a
                  className="instagram"
                  href="https://www.instagram.com/owltracksumatuhuella/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <InstagramOutlined />
                </a>
              </li>
              <li>
                <a
                  className="whatsapp"
                  href="https://wa.me/2396585964"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <WhatsAppOutlined />
                </a>
              </li>
              <li>
                <a
                  className="linkedin"
                  href="https://www.linkedin.com/company/owl-track-sum%C3%A1-tu-huella/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedinOutlined />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};
