// Importa las dependencias necesarias
import React from "react";
import { useNavigate } from "react-router-dom";
import { Button} from "antd";
import { FaArrowLeft } from "react-icons/fa";

// Define el componente BackButton
const BackButton = () => {
  const navigate = useNavigate();

  // Función para manejar el clic en el botón de retroceso
  const handleBackClick = () => {
    // Navega hacia atrás
    navigate(-1);
  };

  return (
    <div className="contenedor-boton-atras container-fluid">
    <Button className="boton-atras" onClick={handleBackClick}>
        <FaArrowLeft className="icono-btn-atras" />
    </Button>
    </div>
  );
};

export default BackButton;
